import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import TenantDetailsForm from "../../form/TenantDetailsForm";
import SelectField from "../../field/SelectField";

import { useForm } from "../../../hooks/useForm";

import useLocationService from "../../../services/locationService";
import useUserService from "../../../services/userService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useSnackbarAlert } from "../../../context/snackbarAlert";

import ChangeLocalizationDialog from "../ChangeLocalizationDialog/ChangeLocalizationDialog";
import AddressDialog from "../AddressDialog/AddressDialog";
import useDialog from "../../../hooks/useDialog";

import {
  getErrorMsg,
  isEmptyArray,
  isEmptyValue,
} from "../../../helpers/methods";

const getAddressRepresentation = (addressData) => {
  let fullAddress = `${addressData.address_zip} ${addressData.address_city}, ${addressData.address_street} ${addressData.address_building}`;
  if (addressData.address_apartment) {
    fullAddress += `/${addressData.address_apartment}`;
  }
  return fullAddress;
};

const TENANT_REQUIRED_FIELDS = [
  "tenant_full_name",
  "tenant_short_name",
  "tenant_nip",
  "address_data",
  "locations",
];

const TenantCreateUpdateDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const { formValue, setFormValue, onChange } = useForm({"tenant_active" : true});
  const {
    prepareLocationDataToSend,
    prepareLocationDataFromBackendForLocationRecordsComponent,
  } = useLocationService();

  const handleChangeAddress = (addressData) => {
    setFormValue((prev) => ({
      ...prev,
      address_data: addressData,
      address: getAddressRepresentation(addressData),
    }));
  };

  const [isLocationsChanged, setIsLocationChanged] = useState(false);
  const handleChangeLocation = (locationData) => {
    setIsLocationChanged(true);
    setFormValue((prev) => ({
      ...prev,
      locations: locationData,
    }));
  };

  const [openAddressDialog, onOpenAddressDialog, onCloseAddressDialog] =
    useDialog();

  const [
    openChangeLocalizationDialog,
    onOpenChangeLocalizationDialog,
    onCloseChangeLocalizationDialog,
  ] = useDialog();

  const {
    getTenantDetails,
    getTenantFilteringData,
    updateTenant,
    createTenant,
  } = useUserService();

  const tenantDetailsData = useAsync(() => {
    let searchParams = {};
    if (props.showFullAddress) {
      searchParams["full_address"] = true;
    }
    if (props.showLocalization) {
      searchParams["include_locations"] = true;
    }
    return getTenantDetails(props.tenantId, searchParams);
  }, [props.tenantId]);
  const tenantFilteringData = useAsync(getTenantFilteringData);

  useEffect(() => {
    if (tenantDetailsData.loading) {
      return;
    }
    setFormValue((prev) => {
      let tempTenantDetailsData = { ...tenantDetailsData.value , ...prev};
      if (props.showFullAddress) {
        tempTenantDetailsData["address"] =
          tenantDetailsData.value.address?.full_address;
        tempTenantDetailsData["address_data"] =
          tenantDetailsData.value?.address;
      }
      if (props.showLocalization) {
        tempTenantDetailsData["locations"] =
          prepareLocationDataFromBackendForLocationRecordsComponent(
            tenantDetailsData.value?.locations
          );
      }
      return tempTenantDetailsData;
    });
  }, [tenantDetailsData.loading]);

  const prepareDataToSend = () => {
    const dataToSend = {};
    for (const [key, value] of Object.entries(formValue)) {
      if (key === "locations") {
        if (isLocationsChanged) {
          dataToSend[key] = prepareLocationDataToSend(value);
        }
        continue;
      }

      if (props.fieldsToUpdate.includes(key) && !isEmptyValue(value)) {
        dataToSend[key] = value;
      }
    }
    return dataToSend;
  };

  const createTenantFn = useAsyncFn(createTenant);
  const updateTenantFn = useAsyncFn(updateTenant);

  const submitFn = props.tenantId ? updateTenantFn : createTenantFn;

  const handleSubmit = (dataToSend) => {
    const submitParams = props.tenantId
      ? [props.tenantId, dataToSend]
      : [dataToSend];

    const successMsg = props.tenantId
      ? t("snackbar_alert.tenant_data_updated")
      : t("snackbar_alert.tenant_added");

    const errorMsg = props.tenantId
      ? t("snackbar_alert.occurred_error_during_updating_tenant_data")
      : t("snackbar_alert.occurred_error_during_adding_tenant_data");

    submitFn
      .execute(...submitParams)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(successMsg);
        if (props.onSubmit) {
          props.onSubmit(res);
        }
        props.onClose();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error, errorMsg));
      });
  };

  const validateChangeLocalization = (locations) => {
    return (
      locations &&
      locations.every((location) => {
        return location.filter((level) => level.id).length === 4;
      })
    );
  };

  const isFormValid =
    formValue &&
    TENANT_REQUIRED_FIELDS.every((field) => {
      if (field === "locations" && props.fieldsToUpdate.includes("locations")) {
        return (
          !isEmptyArray(prepareLocationDataToSend(formValue[field])) &&
          validateChangeLocalization(formValue[field])
        );
      }

      return (
        !props.fieldsToUpdate.includes(field) || !isEmptyValue(formValue[field])
      );
    });

  const isLoading =
    tenantFilteringData.loading ||
    tenantFilteringData.value === undefined ||
    formValue === undefined;

  const getDialogTitle = () =>
    props.tenantId
      ? t("dialog.tenant_details.edit_tenant")
      : t("dialog.tenant_details.add_tenant");

  const getDialogContent = () => {
    if (isLoading) return <LoaderWrapper showLoader={true} />;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowGap={1}
      >
        <TenantDetailsForm
          formValue={formValue}
          readOnly={props.fieldsInTenantDetailsFormReadOnly}
          onChange={onChange}
          onClickAddress={onOpenAddressDialog}
          onlyFormContent={true}
          showContacts={props.showContacts}
          showKrs={props.showKrs}
          showIban={props.showIban}
        />
        <Grid item xs={12}>
          <SelectField
            options={tenantFilteringData.value.tenant_priority}
            name={"tenant_priority"}
            isObjectOption={false}
            value={formValue?.tenant_priority}
            onChange={onChange}
            label={t("dialog.tenant_details.tenant_priority")}
          />
        </Grid>
        {props.showLocalization && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={onOpenChangeLocalizationDialog}
            >
              {t("dialog.tenant_details.assign_locations")}
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={submitFn.loading}
            onClick={() => handleSubmit(prepareDataToSend())}
            disabled={!isFormValid}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
        {openAddressDialog && (
          <AddressDialog
            open={openAddressDialog}
            onClose={onCloseAddressDialog}
            onSubmitReplacement={handleChangeAddress}
            addressData={formValue.address_data}
          />
        )}
        {openChangeLocalizationDialog && (
          <ChangeLocalizationDialog
            open={openChangeLocalizationDialog}
            onClose={onCloseChangeLocalizationDialog}
            preparedLocations={formValue.locations}
            onSubmitWithRawData={handleChangeLocation}
            allowToAddMultipleLocations={true}
            maxAllowedLevelNumber={4}
            validateFn={validateChangeLocalization}
          />
        )}
      </Grid>
    );
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={getDialogTitle()}
      maxWidth="sm"
      showDialogActions
    >
      {getDialogContent()}
    </BasicDialog>
  );
};

TenantCreateUpdateDialog.propTypes = {
  tenantId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  fieldsToUpdate: PropTypes.array,
  fieldsInTenantDetailsFormReadOnly: PropTypes.bool,
  showContacts: PropTypes.bool,
  showKrs: PropTypes.bool,
  showIban: PropTypes.bool,
  showFullAddress: PropTypes.bool,
  showLocalization: PropTypes.bool,
};

TenantCreateUpdateDialog.defaultProps = {
  open: false,
  fieldsToUpdate: [],
  fieldsInTenantDetailsFormReadOnly: true,
  showContacts: false,
  showKrs: false,
  showIban: false,
  showFullAddress: false,
  showLocalization: false,
};

export default TenantCreateUpdateDialog;
