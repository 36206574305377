import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import PrefixSuffixFieldTableWrapper from "../../../wrapper/PrefixSuffixFieldTableWrapper"
import WarningAlertIcon from "../../../other/WarningAlertIcon"
import { formatRackShellData } from "../../../../page/StockWarehousePage/PageTablesConfig";


export const POST_STATUS_TABLE_NAME = "post_status_table";
export const POST_STATUS_TABLE_CONFIG = [
  {
    name: "post_status_name",
    getValueCallback: (rowData) => rowData.post_status_name,
    label: i18n.t("table.correspondence_admin.post_status_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_status_order",
    getValueCallback: (rowData) => rowData.post_status_order,
    label: i18n.t("table.correspondence_admin.post_status_order"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_status_in",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.post_status_in),
    label: i18n.t("table.correspondence_admin.post_status_in"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
  {
    name: "post_status_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.post_status_active),
    label: i18n.t("table.correspondence_admin.post_status_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
