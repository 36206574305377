import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import { useForm } from "../../../hooks/useForm";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { getErrorMsg, isEmptyValue } from "../../../helpers/methods";
import usePurchaseService from "../../../services/purchaseService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useItemCalculationService from "../../../services/itemCalculationService";
import PoFvForm from "../../form/PoFvForm";
import PoSelectTable from "../../table/PoSelectTable";
import SelectPoDialog from "../SelectPoDialog";
import useDialog from "../../../hooks/useDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import useFileOnMemoryData from "../../../hooks/useFileOnMemoryData";
import FileUploadList from "../../other/FileUploadList";
import useFileService from "../../../services/fileService";
import BaseBox from "../../base/BaseBox/baseBox";
import { INTERNAL_TYPE } from "../../../helpers/constants";
import LoaderWrapper from "../../wrapper/LoaderWrapper";


const PurchaseFvDialog = (props) => {

  const snackbarAlert = useSnackbarAlert();

  const { t } = useTranslation();

  const requiredFields = ["fv_nr", "fv_date", "fv_issuer", "fv_payer", "fv_net"]
  const [isFvDataSaving, setIsFvDataSaving] = useState(false)
  const [poListByIssuerAnPayer, setPoListByIssuerAnPayer] = useState([])
  const [poListDataLocal, setPoListDataLocal] = useState([])

  const {
    formValue,
    setFormValue,
    onChangeAutocompleteFieldWithObjectOptions,
    onChange,
    onChangeDate
  } = useForm({});

  const [
    enclosures,
    onAddEnclosure,
    onUpdateEnclosure,
    onDeleteEnclosure,
    prepareFilesToSend
  ] = useFileOnMemoryData("docs");

  const { downloadFileByBlobUrl } = useFileService();

  const {
    createFv,
    getPoSelectData,
    getFVFilteringData
  } = usePurchaseService()

  const fVFilteringData = useAsync(getFVFilteringData)

  const [openSelectPoDialog,
    onOpenSelectPoDialog,
    onCloseSelectPoDialog] =
    useDialog();

  const {
    calculateVatGrossInFV
  } = useItemCalculationService()

  const createFvFn = useAsyncFn(createFv)

  const onCreateFv = useCallback((data) => {

    setIsFvDataSaving(true)
    createFvFn
      .execute(data)
      .then((res) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.fv_added")
        );
        props.onRefetchData()
        props.onClose()
        setIsFvDataSaving(false)
      })
      .catch((error) => {
        setIsFvDataSaving(false)
        snackbarAlert.openErrorSnackbarAlert( 
          getErrorMsg(error.data),
          t("snackbar_alert.occurred_error_during_fv_adding")
        );
      });
  },
    [formValue, poListDataLocal, isFvDataSaving]
  );

  const prepareQueryParamsDict = (data) => {
    return {
      'po_payer': data.fv_payer,
      'po_issuer': data.fv_issuer,
      'po_sended': true
    }
  }

  const onPreprepareDataToSave = (data) => {
    let tempData = {...data}
    delete tempData.fv_vat
    delete tempData.fv_gross 
    tempData.files = prepareFilesToSend(enclosures)
    tempData.po_list = poListDataLocal.map(po => po.id)
    return tempData
  }

  const getPoListByIssuerAnPayer = useAsync(() => {
    if (formValue.fv_issuer && formValue.fv_payer) {
      return getPoSelectData(prepareQueryParamsDict(formValue))
    }
    return Promise.resolve([])
  }, [formValue.fv_issuer, formValue.fv_payer])

  useEffect(() => {
    if (getPoListByIssuerAnPayer.loading) {
      return;
    }
    if (getPoListByIssuerAnPayer.value) {
      setPoListByIssuerAnPayer(getPoListByIssuerAnPayer.value)
    }
  }, [getPoListByIssuerAnPayer.loading]);


  const onChangeLocal = useCallback((...inputProps) => {
    let tempForm = { ...formValue }
    const { name, value } = onChange(...inputProps)
    tempForm[name] = value
    if (name === "fv_net") {
      setFormValue({ ...tempForm, ...calculateVatGrossInFV(tempForm) })
    }
  }, [formValue])

  const onHandleAddPo = useCallback(() => {
    if (formValue.fv_issuer && formValue.fv_payer) {
      onOpenSelectPoDialog()
    }
    else {
      snackbarAlert.openWarningSnackbarAlert(
        t("snackbar_alert.set_payer_and_issuer_to_select_po")
      );
    }
  }, [formValue])

  const onAddPoToList = useCallback((chosenPo) => {
    setPoListDataLocal((prev) => [...prev, chosenPo])
    onCloseSelectPoDialog()
  }, [formValue])


  const isValid = formValue ? requiredFields.every(
    (fieldName) => !isEmptyValue(formValue[fieldName])
  ) : false

  const submitData = useCallback(() => {
    onCreateFv(onPreprepareDataToSave(formValue))
  }, [formValue, poListDataLocal, enclosures])

  const handleRemovePoFromList = useCallback((id, rowIndex) => {
    let tempDataLocal = poListDataLocal
    tempDataLocal.splice(rowIndex, 1)
    setPoListDataLocal([...tempDataLocal])
  }, [poListDataLocal])

  const poListContextMenuActions = useMemo(
    () => [
      {
        label: t("dialog.po_fv_dialog.remove_po_from_list"),
        callback: handleRemovePoFromList,
        icon: <DeleteIcon fontSize="small" />,
      },
    ],
    [handleRemovePoFromList]
  );

  const onDownloadEnclosure = (enclosureId, enclosureName, index) => {
    const enclosure = enclosures[index];
    downloadFileByBlobUrl(enclosure.blob_url, enclosureName);
  };

  if (fVFilteringData.loading) return <LoaderWrapper showLoader={true} />; 
  return (

    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={t("dialog.po_fv_dialog.new_invoice")}
      maxWidth="lg"
      showDialogActions
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          <PoFvForm
            formValue={formValue}
            onChange={onChangeLocal}
            onChangeDate={onChangeDate}
            onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
            readOnly={props.readOnly}
            selectData={fVFilteringData}
          />
        </Grid>
        <Grid item xs={12}>
          <PoSelectTable
            poList={poListDataLocal}
            onHandleAddPo={onHandleAddPo}
            onChangeAutocomplete={onChangeAutocompleteFieldWithObjectOptions}
            readOnly={props.readOnly}
            poListContextMenuActions={poListContextMenuActions}
          />
        </Grid>

        <Grid item xs={12}>
          <BaseBox>
            <FileUploadList
              addEnclosureButtonProps={{ size: "mini" }}
              enclosures={enclosures}
              onDownloadEnclosure={onDownloadEnclosure}
              onAddFile={onAddEnclosure}
              onDeleteEnclosure={onDeleteEnclosure}
              onUpdateEnclosure={onUpdateEnclosure}
              fileType="docs"
              multiple= {false}
              readOnly = {enclosures.length > 0}
              filesOnMemory = {true}
              availableDocsRestrictedTypes = {[]}
            />
          </BaseBox>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            loading={isFvDataSaving}
            disabled={!isValid}
            onClick={submitData}
          >
            {t("add")}
          </LoadingButton>
        </Grid>
      </Grid>
      {openSelectPoDialog &&
        <SelectPoDialog
          open={openSelectPoDialog}
          onClose={onCloseSelectPoDialog}
          loading={getPoListByIssuerAnPayer.loading}
          selectList={poListByIssuerAnPayer}
          onSubmit={onAddPoToList}
        />
      }
    </BasicDialog>
  );
};

PurchaseFvDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetchData : PropTypes.func
};

PurchaseFvDialog.defaultProps = {
  open: false,
};

export default PurchaseFvDialog;
