import { BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const DEPARTMENT_TABLE_NAME = "department_table";
export const DEPARTMENT_TABLE_CONFIG = [
  {
    name: "department_name",
    getValueCallback: (rowData) => rowData.department_name,
    label: i18n.t("table.correspondence_admin.department_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "department_mail",
    getValueCallback: (rowData) => rowData.department_mail,
    label: i18n.t("table.correspondence_admin.department_e-mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
  {
    name: "department_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.department_active),
    label: i18n.t("table.correspondence_admin.department_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
