import { TABLE_TEXT_FIELD_MAX_LENGTH } from "../helpers/constants";
import { sortArrayOfObjectsByOrderArray } from "../helpers/methods";
import CheckboxFieldTable from "../components/field/CheckboxFieldTable";

const TableService = {
  getHeaderLables: (
    fieldsConfig,
    hiddenColumns = [],
    columnOrders = undefined
  ) => {
    let sortedFieldsConfing = columnOrders
      ? sortArrayOfObjectsByOrderArray(fieldsConfig, columnOrders)
      : fieldsConfig;

    return sortedFieldsConfing
      .filter(
        (headerConfig) =>
          !hiddenColumns.includes(headerConfig.name) && !hiddenColumns.onlyData
      )
      .map((headerConfig) => headerConfig.label);
  },

  getPreparedDataForBaseTable: (
    fieldsConfig,
    data,
    hiddenColumns = [],
    columnOrders,
    ...extraValueCallbackProps
  ) => {
    let sortedFieldsConfing = columnOrders
      ? sortArrayOfObjectsByOrderArray(fieldsConfig, columnOrders)
      : fieldsConfig;

    let preparedData = [];
    for (const rowData of data) {
      let rowTableData = [];
      for (const { getValueCallback, ...headerConfig } of sortedFieldsConfing) {
        if (!hiddenColumns.includes(headerConfig.name)) {
          rowTableData.push(
            getValueCallback(rowData, ...extraValueCallbackProps)
          );
        }
      }
      preparedData.push({
        rowId: rowData.id,
        data: rowTableData,
      });
    }
    return preparedData;
  },

  getHeadersConfigForCollapsibleTable: (
    fieldsConfig,
    filteringData,
    hiddenColumns = [],
    columnOrders
  ) => {
    let sortedFieldsConfing = columnOrders
      ? sortArrayOfObjectsByOrderArray(fieldsConfig, columnOrders)
      : fieldsConfig;

    let preparedHeadersConfig = [];
    for (const { getValueCallback, ...headerConfig } of sortedFieldsConfing) {
      if (
        !hiddenColumns.includes(headerConfig.name) &&
        !headerConfig.onlyData
      ) {
        preparedHeadersConfig.push({
          ...headerConfig,
          fieldConfig:
            filteringData && headerConfig.getFilterFieldConfigCallback
              ? headerConfig.getFilterFieldConfigCallback(filteringData)
              : undefined,
        });
      }
    }
    return preparedHeadersConfig;
  },

  getFieldsConfigForEditableTable: (
    fieldsConfig,
    data,
    filteringData,
    ...extraValueCallbackProps
  ) => {
    let preparedData = [];
    let index = 0;
    for (const rowData of data) {
      if (rowData) {
        let rowTableData = [];
        for (const { getValueCallback, ...headerConfig } of fieldsConfig) {
          rowTableData.push({
            ...headerConfig,
            fieldConfig:
              filteringData && headerConfig.getFilterFieldConfigCallback
                ? headerConfig.getFilterFieldConfigCallback(filteringData)
                : undefined,
            value: getValueCallback(rowData, index, ...extraValueCallbackProps),
            valueAsCheckbox: headerConfig.valueAsCheckbox === true,
            style: headerConfig.style ? headerConfig.style : {},
            maxTextLength: headerConfig.maxTextLength,
            index: index,
          });
        }

        preparedData.push({
          id: rowData.id,
          editable: rowData.editable,
          data: rowTableData,
          fieldConfig: rowData.fieldConfig,
          style: rowData.style,
          selected: rowData.selected,
          isDeleted: rowData.isDeleted,
        });
        index = index + 1;
      }
    }
    return preparedData;
  },

  getPreparedDataForCollapsibleTable: (
    fieldsConfig,
    data,
    hiddenColumns,
    columnOrders,
    ...extraValueCallbackProps
  ) => {
    let sortedFieldsConfing = columnOrders
      ? sortArrayOfObjectsByOrderArray(fieldsConfig, columnOrders)
      : fieldsConfig;

    let preparedData = [];
    for (const rowData of data) {
      let rowTableData = [];
      for (const { getValueCallback, ...headerConfig } of sortedFieldsConfing) {
        if (!hiddenColumns.includes(headerConfig.name)) {
          rowTableData.push({
            name: headerConfig.name,
            cellStyle:
              headerConfig.cellStyle instanceof Function
                ? headerConfig.cellStyle(rowData)
                : headerConfig.cellStyle,
            onlyData: headerConfig.onlyData,
            valueAsCheckbox: headerConfig.valueAsCheckbox === true,
            maxTextLength: headerConfig.maxTextLength,
            value: getValueCallback(rowData, ...extraValueCallbackProps),
          });
        }
      }
      preparedData.push({
        id: rowData.id,
        updated: rowData.updated,
        updatedInnerElementData: rowData.updatedInnerElementData,
        updatedSelectedInnerElement: rowData.updatedSelectedInnerElement,
        is_favorite: rowData.is_favorite,
        is_claim: rowData.is_claim,
        is_without_response: rowData.is_without_response,
        is_closed: rowData.is_closed,
        is_offer_cr_requested: rowData.is_offer_cr_requested
          ? rowData.is_offer_cr_requested
          : false,
        is_exceed: rowData.exceed ? rowData.exceed : false,
        selected: rowData.selected,
        hasChanges: rowData.has_changes,
        data: rowTableData,
      });
    }

    return preparedData;
  },

  getTableConfigsForTableColumnVisibilityDialog: (
    tableConfigs,
    columnOrders = {}
  ) => {
    let resData = {};
    tableConfigs.forEach((tableConfig) => {
      resData[tableConfig.name] = sortArrayOfObjectsByOrderArray(
        tableConfig.config,
        columnOrders[tableConfig.name]
      )
        .filter((config) => !config.onlyData)
        .map((config) => ({
          label: config.label,
          name: config.name,
          allowChangeVisibility: config.allowChangeVisibility !== false, //default true
          defaultHidden: config.defaultHidden === true, //default false
        }));
    });
    return resData;
  },

  getTableExportHeaders: (fieldsConfig, hiddenColumns, columnOrders) => {
    var headers = [];
    if (fieldsConfig !== undefined && hiddenColumns !== undefined) {
      for (const header of TableService.getHeadersConfigForCollapsibleTable(
        fieldsConfig,
        undefined,
        hiddenColumns,
        columnOrders
      )) {
        if (!header.excludedFromExport) {
          headers.push({
            field_name: header.other_export_field_name
              ? header.other_export_field_name
              : header.name,
            label: header.label,
          });
        }
      }
    }
    return headers;
  },

  getCellContent: (value, customMaxTextLength) => {
    const maxTextLength = customMaxTextLength || TABLE_TEXT_FIELD_MAX_LENGTH;
    return typeof value === "string" && value.length > maxTextLength
      ? `${value.slice(0, maxTextLength)}...`
      : value;
  },
};

export default TableService;
