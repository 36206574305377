import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import MultipleLocalizationTable from "../../table/MultipleLocalizationTable";
import BasicDialog from "../../base/BasicDialog";
import i18n from "../../../i18n";

const MultipleLocalizationDialog = (props) => {
  const { t } = useTranslation();

  const localizationData = props.isLevelObjectData
    ? props.localizationData.map((localization) => {
        let resLocalizationData = {};
        for (const [key, value] of Object.entries(localization)) {
          resLocalizationData[key] = value?.object_name;
        }
        return resLocalizationData;
      })
    : props.localizationData;

  const getTitle = () => {
    if (props.title) return props.title;
    if (props.itemType === "order") {
      return t("dialog.multiple_localization_dialog.locations_for_order");
    }
    if (props.itemType === "guarantee") {
      return t("dialog.multiple_localization_dialog.locations_for_guarantee");
    }
    if (props.itemType === "docs") {
      return t("dialog.multiple_localization_dialog.locations_for_docs");
    }
    return t("dialog.multiple_localization_dialog.locations_for_ticket");
  };

  const getHeadersConfig = () => {
    let multipleLocalizationTableFieldsConfig = [];
    for (let level = 1; level < props.maxAllowedLevelNumber + 1; level++) {
      multipleLocalizationTableFieldsConfig.push({
        name: `level_${level}`,
        getValueCallback: (rowData) => rowData[`level_${level}`],
        label: i18n.t(`table.multiple_localization.level_${level}`),
      });
    }
    return multipleLocalizationTableFieldsConfig;
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={getTitle()}
      maxWidth="sm"
      showDialogActions
    >
      <MultipleLocalizationTable
        localizationData={localizationData}
        headersConfig={getHeadersConfig()}
      />
    </BasicDialog>
  );
};

MultipleLocalizationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  localizationData: PropTypes.array,
  isLevelObjectData: PropTypes.bool,
  title: PropTypes.string,
  itemType: PropTypes.oneOf(["ticket", "order", "guarantee", "docs"]),
  maxAllowedLevelNumber: PropTypes.number,
};

MultipleLocalizationDialog.defaultProps = {
  open: false,
  itemType: "ticket",
  isLevelObjectData: false,
  maxAllowedLevelNumber: 5,
};

export default MultipleLocalizationDialog;
