import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

import ItemDetailsForm from "../../UniversalDetailsDrawerForms/ItemDetailsForm";
import PersonsForm from "../../UniversalDetailsDrawerForms/PersonsForm";
import LocalizationForm from "../../UniversalDetailsDrawerForms/LocalizationForm";
import CommunicationLogForm from "../../UniversalDetailsDrawerForms/CommunicationLogForm";
import DateForm from "../../UniversalDetailsDrawerForms/DateForm";
import ActionIconsForm from "../../UniversalDetailsDrawerForms/ActionIconsForm";

import { useForm } from "../../../../../hooks/useForm";
import { useSnackbarAlert } from "../../../../../context/snackbarAlert";
import { useTranslation } from "react-i18next";
import { getErrorMsg } from "../../../../../helpers/methods";

const TicketDrawerContent = (props) => {
  const {
    formValue,
    setFormValue,
    onChangeWithCallback,
    onChangeAutocompleteFieldWithObjectOptionsAndCallback,
    onChangeDateWithCallback,
  } = useForm(props.itemData);

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const snackbarAlert = useSnackbarAlert();

  const handleChangeFieldValue = (name, value) => {
    setIsLoading(true);
    const oldValue = formValue[name];
    props
      .onUpdateDataPromise(formValue.id, { [name]: value })
      .then(() => {
        props.onRefetchData();
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(getErrorMsg(error.data));
        setFormValue((prev) => ({ ...prev, [name]: oldValue }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeLocations = (locationData, onCloseDialog) => {
    const oldLocationData = formValue.locations;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { objects: locationData })
      .then((data) => {
        setFormValue((prev) => ({ ...prev, locations: data.locations }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        setFormValue((prev) => ({ ...prev, locations: oldLocationData }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangeStatus = (statusData, onCloseDialog) => {
    const oldStatus = formValue.ticket_status;
    const oldSubStatus = formValue.ticket_substatus;
    setIsLoading(true);
    props
      .onUpdateDataPromise(formValue.id, { status_data: statusData })
      .then((data) => {
        setFormValue((prev) => ({
          ...prev,
          ticket_status: data.ticket_status,
          ticket_substatus: data.ticket_substatus,
        }));
        onCloseDialog();
        props.onRefetchData();
      })
      .catch((error) => {
        setFormValue((prev) => ({
          ...prev,
          ticket_status: oldStatus,
          ticket_substatus: oldSubStatus,
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onOpenDialogWithSearchParams = () => {
    props.onOpenDialogParamsAction(false);
  };

  const onCloseDialogWithSearchParams = () => {
    props.onOpenDialogParamsAction(true);
  };

  const isTicketClosed = props.itemData.is_closed;
  const isTicketFinallyClosed = props.itemData.is_finally_closed;
  const isTicketClaim = props.itemData.is_claim;

  const getCategoryOptions = () => {
    return isTicketClaim
      ? props.filteringData.ticket_category
      : props.filteringData.ticket_category.filter(
          (tc) => tc.tc_is_claim === false
        );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
      rowGap={1}
      marginTop={1}
      marginBottom={3}
    >
      <Grid item xs={12}>
        <PersonsForm
          ownerName={formValue.ticket_owner?.full_name}
          ownerId={formValue.ticket_owner?.id}
          omName={formValue.ticket_om?.full_name}
          omId={formValue.ticket_om?.id}
          tenantName={formValue.tenant?.tenant_short_name}
          tenantId={formValue.tenant?.id}
          reposoniblePersonId={formValue.ticket_k}
          onChangeResponsiblePerson={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isResponsiblePersonAllowedToChange={
            !props.readOnly && !isTicketClosed
          }
          reposoniblePersonOptions={props.filteringData.ticket_k}
          itemType={props.itemType}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <LocalizationForm
          itemForChangeLocalizationId={formValue.id}
          isAllowedToChangeLocalization={
            !props.readOnly && !isTicketClosed && !isTicketClaim
          }
          localizationData={formValue.locations}
          isMultipleLocalization={formValue.is_multiple_locations}
          onChangeLocalization={handleChangeLocations}
          maxAllowedLevelNumber={props.maxAllowedLevelNumber}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DateForm
          createdDate={formValue.created}
          isAllowedToChangeFinishPlannedDate={
            !props.readOnly && !isTicketClosed
          }
          onChangeFinishPlannedDate={onChangeDateWithCallback(
            handleChangeFieldValue
          )}
          finishPlannedDate={formValue.ticket_deadlineK}
          finishPlannedDateFieldName={"ticket_deadlineK"}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ItemDetailsForm
          isPaid={formValue.ticket_paid}
          isAllowedToChangeIsPaid={!props.readOnly && !isTicketClosed}
          onChangeIsPaid={onChangeWithCallback(handleChangeFieldValue)}
          statusName={formValue.ticket_status}
          substatusName={formValue.ticket_substatus}
          categoryId={formValue.ticket_category}
          isCategoryAllowedToChange={
            !props.readOnly && !isTicketClosed && !isTicketClaim
          }
          categoryOptions={getCategoryOptions()}
          onChangeCategory={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          priorityId={formValue.ticket_priority}
          priorityOptions={props.filteringData.ticket_priority}
          onChangePriority={onChangeAutocompleteFieldWithObjectOptionsAndCallback(
            handleChangeFieldValue
          )}
          isPriorityAllowedToChange={!props.readOnly && !isTicketClosed}
          ticketDescCreator={formValue.ticket_desc_creator}
          itemName={formValue.ticket_name}
          onChangeTextField={onChangeWithCallback(handleChangeFieldValue)}
          isAllowedToChangeItemName={
            !props.readOnly && !isTicketClosed && !isTicketClaim
          }
          isAllowedToChangeTicketDescCreator={
            !props.readOnly &&
            !isTicketClosed &&
            !isTicketClaim &&
            formValue.is_ticket_owner
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CommunicationLogForm
        lastMessages={formValue.ticket_last_messages}
        itemId={formValue.id}
        itemType={props.itemType}
        showExtendedAddEnclosureDialog={true}
        onOpenDialogParamsAction={props.onOpenDialogParamsAction}
        onRefetchData={props.onRefetchData}
        isFinallyClosed={isTicketFinallyClosed}
      />
      {props.extraContent &&
        props.extraContent.map((Item, idx) => (
          <Grid item xs={12} key={idx}>
            {Item}
          </Grid>
        ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ActionIconsForm
          itemId={formValue.id}
          itemType={"ticket"}
          itemNumber={formValue.ticket_nr}
          isItemClosed={isTicketClosed}
          isItemFinallyClosed={isTicketFinallyClosed}
          isItemCancelled={props.itemData.is_cancel}
          isTicketClaim={isTicketClaim}
          onChangeStatus={handleChangeStatus}
          isLoading={isLoading}
          myOfferCount={formValue.my_offer_count}
          offerCount={formValue.offer_count}
          enclosuresCount={formValue.enclosures_count}
          ticketRelationsCount={formValue.ticket_relation_count}
          availableActionButtons={props.availableActionButtons}
          onRefetchData={props.onRefetchData}
          onRefetchTableData={props.onRefetchTableData}
          onOpenDialogParamsAction={onOpenDialogWithSearchParams}
          onCloseDialogParamsAction={onCloseDialogWithSearchParams}
          isAllowedToCopyTicket={formValue.is_allowed_to_copy_ticket}
          readOnly={props.readOnly}
        />
      </Grid>
    </Grid>
  );
};

TicketDrawerContent.propTypes = {
  itemData: PropTypes.object,
  itemType: PropTypes.oneOf(["ticket", "ticket_order"]),
  onRefetchData: PropTypes.func,
  readOnly: PropTypes.bool,
  availableActionButtons: PropTypes.array,
  onRefetchTableData: PropTypes.func,
  extraContent: PropTypes.array,
};

TicketDrawerContent.defaultProps = {
  itemType: "ticket",
  isLoading: false,
  readOnly: false,
};

export default TicketDrawerContent;
