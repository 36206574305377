import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import EnclosuresWindow from "../../window/EnclosuresWindow";
import {
  MAX_ALLOWED_FILE_SIZE_IN_MB,
  MAX_FILENAME_LENGTH_CHARS,
} from "../../../helpers/constants";

export default function AddFileForm(props) {
  const { t } = useTranslation();



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
  
      {  ( props.showButtonWithFileRestriction) && (
        <>
          <Grid item xs={12}>
            <Button
              size="medium"
              variant="contained"
              component="label"
              sx={{ width: "-moz-available" }}
              fullWidth
            >
              {t("form.add_docs_form.insert_from_disc")}
              <input
                type="file"
                hidden
                onChange={props.onChooseFile}
                multiple={props.multiple}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {" "}
              {t("form.add_docs_form.max_file_size") +
                ` ${MAX_ALLOWED_FILE_SIZE_IN_MB}MB`}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {" "}
              {t(
                `form.add_docs_form.max_file_name_${MAX_FILENAME_LENGTH_CHARS}_chars`
              )}{" "}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12}>
        <EnclosuresWindow
          enclosureWindowStyle={{}}
          enclosures={props.docs}
          showCreator={false}
          onDownload={props.onDownload}
          onPreview={props.onPreview}
          onDeleteEnclosure={props.onDeleteEnclosure}
          showAddToFavorite={false}
          showPublishButton={false}
          showDetailsButton={false}
          showDownloadButton={false}
          canHideEnclosures={!props.readOnly}
          fileType={props.fileType}
        />
      </Grid>
    </Grid>
  );
}

AddFileForm.propTypes = {
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  submitButtonLabel: PropTypes.string,
  onCopyFromOther: PropTypes.func,
  multiple: PropTypes.bool,
  showButtonWithFileRestriction: PropTypes.bool,
  readOnly : PropTypes.bool
};

AddFileForm.defaultProps = {
  formValue: {},
  multiple: true,
  showButtonWithFileRestriction: true,
  readOnly : false
};
