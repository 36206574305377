import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import { POST_STATUS_TABLE_CONFIG } from "./TableConfig";
import useCorrespondenceAdminService from "../../../../services/correspondenceAdminService";
import CreatePostStatusDialog from "../../../dialog/CreatePostStatusDialog/CreatePostStatusDialog";

const POST_STATUS_FILTER_PREFIX = "post_status";

const CorrespondenceAdminPostStatusTab = (props) => {
  const { t } = useTranslation();
  const [postStatusesLocal, setPostStatusesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postStatusTableSearchParams, setPostStatusTableSearchParams] = useState({});

  const { setNewItemSearchParamsIfAreChanged, clearSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo();
  const { getPostStatusList } = useCorrespondenceAdminService(); 

  const onCleanFiltersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(POST_STATUS_FILTER_PREFIX));
  };

  const [
    openPostStatusDialog,
    postStatusChangeId,
    onOpenPostStatusDialog,
    onClosePostStatusDialog,
    exportChangeStatusStatus
  ] = useDialogWithId();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(POST_STATUS_FILTER_PREFIX);

  const postStatusData = useAsync(
    () => getPostStatusList(postStatusTableSearchParams),
    [postStatusTableSearchParams]
  );

  useEffect(() => {
    if (postStatusData.loading) {
      return;
    }
    setPostStatusesLocal(postStatusData.value.results);
    setCountRecords(postStatusData.value.count);
  }, [postStatusData.loading]);

  const isLoading = postStatusData.loading || props.filteringData.loading;

  const handleOpenPostStatusDialog = useCallback(
    (e, postStatusId) => {
      e.stopPropagation();
      onOpenPostStatusDialog(postStatusId);
    },
    []
  );

  const refreshTable = () => {
    setPostStatusesLocal([]);
    postStatusData.refetch();
  };

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        POST_STATUS_TABLE_CONFIG,
        postStatusesLocal ? postStatusesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPostStatusDialog,
      ),
    [postStatusesLocal, POST_STATUS_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_STATUS_FILTER_PREFIX,
      postStatusTableSearchParams,
      setPostStatusTableSearchParams
    );
  }, [searchParams]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenPostStatusDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_post_status")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={POST_STATUS_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={POST_STATUS_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            POST_STATUS_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFiltersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={POST_STATUS_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={POST_STATUS_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openPostStatusDialog &&
        <CreatePostStatusDialog
          postStatusId={postStatusChangeId}
          open={openPostStatusDialog}
          onClose={onClosePostStatusDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminPostStatusTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminPostStatusTab.defaultProps = {};

export default CorrespondenceAdminPostStatusTab;
