import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import AutocompleteField from "../../field/AutocompleteField";
import SelectField from "../../field/SelectField";
import { useAsyncFn } from "../../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import useCorrespondenceService from "../../../services/correspondenceService";
import { isEmptyValue } from "../../../helpers/methods";
import { useForm } from "../../../hooks/useForm";

const PostRecipientsDialog = (props) => {
  const { t } = useTranslation();

  const { formValue, setFormValue, onChangeAutocompleteFieldWithObjectOptions } = useForm({});
  const { getUserDepartmentSelectList, getDepartmentSelectList } = useCorrespondenceService();

  const getUserDepartmentSelectListFn = useAsyncFn(getUserDepartmentSelectList);
  const getDepartmentSelectListFn = useAsyncFn(getDepartmentSelectList);

  const [userDepartmentSelectList, setUserDepartmentSelectList] = useState(props.autocompleteData.user_department);
  const [departmentSelectList, setDepartmentSelectList] = useState(props.autocompleteData.department);
  const [recipientExists, setRecipientExists] = useState(false); 

  const handleAutocompleteChange = (e, value_object, value_key, state_value_name) => {
    const resetFormValue = (updates) => {
      setFormValue((formValue) => ({ ...formValue, ...updates }));
    };

    const updateUserDepartmentSelectList = () => {
      if (value_object.id) {
        getUserDepartmentSelectListFn.execute({ department_id: value_object.id })
          .then((res) => setUserDepartmentSelectList(res));
      } else {
        setUserDepartmentSelectList(props.autocompleteData.user_department);
      }
    };

    const updateDepartmentSelectList = () => {
      if (value_object.id) {
        getDepartmentSelectListFn.execute({ user_id: value_object.id })
          .then((res) => setDepartmentSelectList(res));
      } else {
        setDepartmentSelectList(props.autocompleteData.department);
      }
    };

    resetFormValue({ [state_value_name]: undefined });
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name);

    switch (state_value_name) {
      case 'postrec_department':
        updateUserDepartmentSelectList();
        break;
      case 'postrec_person':
        updateDepartmentSelectList();
        break;
      default:
        break;
    }
  };
  
  const handleSelectChange = (name, event) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      [name]: event.target.value
    }));
  };

   const handleSubmit = () => {
    
    if (isEmptyValue(formValue.postrec_person)) {
      const selectedDepartmentUsers = userDepartmentSelectList.map(user => ({
        postrec_department: formValue.postrec_department,
        postrec_person: user.id,
        postrec_person_type: formValue.postrec_person_type
      }));

      selectedDepartmentUsers.forEach((recipient) => {
        const existingRecipient = props.existingRecipients.find(r => 
          r.postrec_department === recipient.postrec_department &&
          r.postrec_person === recipient.postrec_person
        );

        if (!existingRecipient) {
          props.onAddRecipient(recipient);
        }
      });
      props.onClose();
    } else {
      const existingRecipient = props.existingRecipients.find(recipient => 
        recipient.postrec_department === formValue.postrec_department &&
        recipient.postrec_person === formValue.postrec_person
      );

      if (existingRecipient) {
        setRecipientExists(true); 
      } else {
        setRecipientExists(false); 
        props.onAddRecipient(formValue);
        props.onClose();
      }
    }
  };

  const requiredFields = ["postrec_department", "postrec_person_type"];

  const isFormValid = () => {
    return requiredFields.every((fieldName) => !isEmptyValue(formValue[fieldName]));
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      backdropColor={props.backdropColor}
      title={props.postIn ? t("dialog.post_dialog.add_post_user") : t("dialog.post_dialog.add_post_out_user")}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AutocompleteField
            name="postrec_department"
            label={props.postIn ? t("table.correspondence.recipient_department") : t("table.correspondence.sender_department")}
            value={formValue["postrec_department"]}
            options={departmentSelectList}
            isObjectOption={true}
            optionLabelKey={"department_name"}
            addNewValue={false}
            onChange={(e, value) => handleAutocompleteChange(e, value, "id", "postrec_department")}
            disabled={props.readOnly}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteField
            name="postrec_person"
            label={props.postIn ? t("table.correspondence.recipient_person") : t("table.correspondence.sender_person")}
            value={formValue["postrec_person"]}
            options={userDepartmentSelectList}
            isObjectOption={true}
            optionLabelKey={"full_name"}
            addNewValue={false}
            onChange={(e, value) => handleAutocompleteChange(e, value, "id", "postrec_person")}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            name="postrec_person_type"
            label={props.postIn ? t("table.correspondence.recipient_type") : t("table.correspondence.sender_type")}
            value={formValue["postrec_person_type"]}
            options={props.autocompleteData.person_type}
            isObjectOption={false}
            addNewValue={false}
            onChange={(event) => handleSelectChange('postrec_person_type', event)}
            disabled={props.readOnly}
            required
          />
        </Grid>
        {recipientExists && (
          <Grid item xs={12}>
            <p style={{ color: "red" }}>{props.postIn ? t("form.error.recipient_already_added"): t("form.error.sender_already_added") }</p>
          </Grid>
        )}
        <Grid item xs={4.5}></Grid>
        {!props.readOnly && (
          <Grid item xs={3}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={props.isLoading}
              disabled={!isFormValid()}
              onClick={handleSubmit}
            >
              {t("add")}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </BasicDialog>
  );
};

PostRecipientsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddRecipient: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  postIn: PropTypes.bool.isRequired,
  autocompleteData: PropTypes.object.isRequired,
  backdropColor: PropTypes.string,
  existingRecipients: PropTypes.array.isRequired, 
};

PostRecipientsDialog.defaultProps = {
  isLoading: false,
  readOnly: false,
  backdropColor: undefined,
};

export default PostRecipientsDialog;
