import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FullWidthTabs from "../FullWidthTabs/FullWidthTabs";
import { useAsync } from "../../../hooks/useAsync";
import CorrespondenceAdminUserDepartmentTab from "./CorrespondenceAdminUserDepartmentTab";
import useCorrespondenceService from "../../../services/correspondenceService";
import CorrespondenceAdminPostStatusTab from "./CorrespondenceAdminPostStatusTab";
import CorrespondenceAdminPostDeliveryTab from "./CorrespondenceAdminPostDeliveryTab/CorrespondenceAdminPostDeliveryTab";
import CorrespondenceAdminPostCategoryTab from "./CorrespondenceAdminPostCategoryTab";
import CorrespondenceAdminPostSideTypeTab from "./CorrespondenceAdminPostSideTypeTab";
import CorrespondenceAdminPostArchivesTab from "./CorrespondenceAdminPostArchivesTab";
import CorrespondenceAdminPostSubcategoryTab from "./CorrespondenceAdminPostSubcategoryTab";
import CorrespondenceAdminSideAdministrationTab from "./CorrespondenceAdminSideAdministrationTab/CorrespondenceAdminSideAdministrationTab";



const CorrespondenceAdminTabs = React.memo((props) => {
  const { t } = useTranslation();

  const {
    getPostFilteringData
  } = useCorrespondenceService();

  const postFilteringData = useAsync(getPostFilteringData);

  return (
    <FullWidthTabs
    cleanFilters={true}
      items={[
        {
          label: t("tabs.correspondence_admin.user_department"),
          content: (
            <CorrespondenceAdminUserDepartmentTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_status"),
          content: (
            <CorrespondenceAdminPostStatusTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_delivery"),
          content: (
            <CorrespondenceAdminPostDeliveryTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_category"),
          content: (
            <CorrespondenceAdminPostCategoryTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_subcategory"),
          content: (
            <CorrespondenceAdminPostSubcategoryTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_side_type"),
          content: (
            <CorrespondenceAdminPostSideTypeTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.side_administration"),
          content: (
            <CorrespondenceAdminSideAdministrationTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        {
          label: t("tabs.correspondence_admin.post_archiv_location"),
          content: (
            <CorrespondenceAdminPostArchivesTab
              readOnly={props.readOnly}
              pageName={props.pageName}
              filteringData = {postFilteringData}
            />
          ),
        },
        
      ]}
    />
  );
});

CorrespondenceAdminTabs.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminTabs.defaultProps = {
  readOnly: false,
};

export default CorrespondenceAdminTabs;
