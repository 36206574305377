import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
export const SIDE_ADMINISTRATION_TABLE_NAME = "side_administration_table";
export const SIDE_ADMINISTRATION_TABLE_CONFIG = [
  {
    name: "administration_name",
    getValueCallback: (rowData) => rowData.administration_name,
    label: i18n.t("table.correspondence_admin.administration_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_side_type",
    getValueCallback: (rowData) => rowData.administration_side_type,
    label: i18n.t("table.correspondence_admin.administration_side_type"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_side_type,
      isObjectOption: true,
      optionLabelKey: "side_type_name",
      addNewValue: false,
      multiple: false,
      sortable: true,
    }),
  },
  {
    name: "administration_subname",
    getValueCallback: (rowData) => rowData.administration_subname,
    label: i18n.t("table.correspondence_admin.administration_subname"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_code",
    getValueCallback: (rowData) => rowData.administration_code,
    label: i18n.t("table.correspondence_admin.administration_code"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_city",
    getValueCallback: (rowData) => rowData.administration_city,
    label: i18n.t("table.correspondence_admin.administration_city"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_street",
    getValueCallback: (rowData) => rowData.administration_street,
    label: i18n.t("table.correspondence_admin.administration_street"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_house",
    getValueCallback: (rowData) => rowData.administration_house,
    label: i18n.t("table.correspondence_admin.administration_house"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_local",
    getValueCallback: (rowData) => rowData.administration_local,
    label: i18n.t("table.correspondence_admin.administration_local"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "administration_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.administration_active),
    label: i18n.t("table.correspondence_admin.administration_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
