import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../context/snackbarAlert";
import {
  postCategoryListUrl,
  postCategoryDataUrl,
  postSideTypeListUrl,
  postSideTypeDataUrl,
  postArchivesListUrl,
  postArchivesDataUrl,
  postDeliveryListUrl,
  postDeliveryDataUrl,
  postSubcategoryListUrl,
  sideAdministrationListUrl,
  userOwnersSelectListUrl,
  ownersSelectListUrl,
  userDepartmentSelectListUrl,
  departmentSelectListUrl,
  departmentsListUrl,
  departmentDataUrl,
  postStatusListUrl,
  postStatusDataUrl,
  postDefaultStatus,
  postSubcategoryDataUrl,
  postSubcategoryAllListUrl,
  sideAdministrationDataUrl,
  postSubcategoryDataDetailsUrl,
  sideAdministrationCreateUrl,
} from "../helpers/apiUrls";

import { makeRequest } from "../helpers/makeRequest";
import { prepareUrlWithQueryParams } from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useCorrespondenceAdminService() {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();

  const createDepartment = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, departmentsListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.department_created"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_department"));
          reject(error);
        });
    });
  };
  const createPostStatus = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postStatusListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_status_created"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_post_status"));
          reject(error);
        });
    });
  };
  const createPostDelivery = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postDeliveryListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.delivery_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_delivery"));
          reject(error);
        });
    });
  };

  const getPostDeliveryList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postDeliveryListUrl, searchParams)
    );
  };

  const getPostDeliveryDetails = (postDeliveryId) => {
    return makeRequest(axiosPrivate, postDeliveryDataUrl(postDeliveryId));
  };

  const updatePostDeliveryData = (postDeliveryId, dataToSend) => {
    return makeRequest(axiosPrivate, postDeliveryDataUrl(postDeliveryId), {
      method: "PATCH",
      data: dataToSend,
    });
  };
  const createPostCategory = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postCategoryListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.category_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_category"));
          reject(error);
        });
    });
  };

  const getPostCategoryList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postCategoryListUrl, searchParams)
    );
  };

  const getPostCategoryDetails = (postCategoryId) => {
    return makeRequest(axiosPrivate, postCategoryDataUrl(postCategoryId));
  };

  const updatePostCategoryData = (postCategoryId, dataToSend) => {
    return makeRequest(axiosPrivate, postCategoryDataUrl(postCategoryId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createPostSideType = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postSideTypeListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.side_type_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_side_type"));
          reject(error);
        });
    });
  };

  const getPostSideTypeList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postSideTypeListUrl, searchParams)
    );
  };

  const getPostSideTypeDetails = (postSideTypeId) => {
    return makeRequest(axiosPrivate, postSideTypeDataUrl(postSideTypeId));
  };

  const updatePostSideTypeData = (postSideTypeId, dataToSend) => {
    return makeRequest(axiosPrivate, postSideTypeDataUrl(postSideTypeId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createPostArchives = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postArchivesListUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.archives_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_archives"));
          reject(error);
        });
    });
  };

  const getPostArchivesList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postArchivesListUrl, searchParams)
    );
  };

  const getPostArchivesDetails = (postArchivesId) => {
    return makeRequest(axiosPrivate, postArchivesDataUrl(postArchivesId));
  };

  const updatePostArchivesData = (postArchivesId, dataToSend) => {
    return makeRequest(axiosPrivate, postArchivesDataUrl(postArchivesId), {
      method: "PATCH",
      data: dataToSend,
    });
  };
  const createPostSubcategory = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, postSubcategoryDataUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.subcategory_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_subcategory"));
          reject(error);
        });
    });
  };

  const getPostSubcategoryAllList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postSubcategoryAllListUrl, searchParams)
    );
  };

  const getPostSubcategoryDetails = (postSubcategoryId) => {
    return makeRequest(axiosPrivate, postSubcategoryDataDetailsUrl(postSubcategoryId));
  };

  const updatePostSubcategoryData = (postSubcategoryId, dataToSend) => {
    return makeRequest(axiosPrivate, postSubcategoryDataDetailsUrl(postSubcategoryId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const createSideAdministration = async (dataToSend) => {
    return new Promise(async (resolve, reject) => {
      await makeRequest(axiosPrivate, sideAdministrationCreateUrl, {
        method: "POST",
        data: dataToSend,
      })
        .then((result) => {
          resolve(result);
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.side_administration_created_successfully"));
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.error_occurred_during_creating_side_administration"));
          reject(error);
        });
    });
  };

  const getSideAdministrationList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(sideAdministrationListUrl, searchParams)
    );
  };

  const getSideAdministrationDetails = (id) => {
    return makeRequest(axiosPrivate, sideAdministrationDataUrl(id));
  };

  const updateSideAdministrationData = (id, dataToSend) => {
    return makeRequest(axiosPrivate, sideAdministrationDataUrl(id), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const updatePostStatusData = (postStatusId, dataToSend) => {
    return makeRequest(axiosPrivate, postStatusDataUrl(postStatusId), {
      method: "PATCH",
      data: dataToSend,
    });
  };
  const getPostStatusDetails = (postStatusId) => {
    return makeRequest(axiosPrivate, postStatusDataUrl(postStatusId));
  };

  const getDepartmentDetails = (departmentId) => {
    return makeRequest(axiosPrivate, departmentDataUrl(departmentId));
  };

  const updateDepartmentData = (departmentId, dataToSend) => {
    return makeRequest(axiosPrivate, departmentDataUrl(departmentId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getPostSubcategoryList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postSubcategoryListUrl, searchParams)
    );
  };
  const getUserOwnersSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userOwnersSelectListUrl, searchParams)
    );
  };
  const getOwnersSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(ownersSelectListUrl, searchParams)
    );
  };
  const getUserDepartmentSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(userDepartmentSelectListUrl, searchParams)
    );
  };
  const getDepartmentSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(departmentSelectListUrl, searchParams)
    );
  };
  const getDepartmentsList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(departmentsListUrl, searchParams)
    );
  };
  const getUserDepartmentList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(departmentsListUrl, searchParams)
    );
  };

  const getPostStatusList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(postDefaultStatus, searchParams)
    );
  };

  return {
    getPostCategoryList,
    getPostCategoryDetails,
    createPostCategory,
    updatePostCategoryData,

    getPostSubcategoryList,
    getPostSubcategoryAllList,
    getPostSubcategoryDetails,
    createPostSubcategory,
    updatePostSubcategoryData,

    getPostSideTypeList,
    getPostSideTypeDetails,
    createPostSideType,
    updatePostSideTypeData,

    getPostArchivesList,
    getPostArchivesDetails,
    createPostArchives,
    updatePostArchivesData,

    getPostDeliveryList,
    getPostDeliveryDetails,
    createPostDelivery,
    updatePostDeliveryData,

    createSideAdministration,
    getSideAdministrationList,
    getSideAdministrationDetails,
    updateSideAdministrationData,

    getUserOwnersSelectList,
    getOwnersSelectList,
    getUserDepartmentSelectList,
    getUserDepartmentList,
    getDepartmentSelectList,
    getDepartmentsList,
    updateDepartmentData,
    getPostStatusList,
    createDepartment,
    getDepartmentDetails,
    createPostStatus,
    updatePostStatusData,
    getPostStatusDetails,
  };

}
