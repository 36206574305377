import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import FieldWithLabel from "../../../field/FieldWithLabel";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import TrueFalseSelectField from "../../../field/TrueFalseSelectField";
import SelectField from "../../../field/SelectField";


const OwnerForm = (props) => {
  const { t } = useTranslation();

  const [isTextFieldChanged, setIsTextFieldChanged] = useState(false);
  const [textFieldsFormValueLocal, setTextFieldsFormValueLocal] = useState(props.ownerData)
  const onChangeTextFieldLocal = (e) => {
    setIsTextFieldChanged(true);
    setTextFieldsFormValueLocal((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTextField = (e) => {
    if (isTextFieldChanged) {
      setIsTextFieldChanged(false);
      props.onChangeTextField(e);
    }
  };



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={2}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_full_name")}
          field={
            <TextFieldFormControl
              name="owner_full_name"
              value={textFieldsFormValueLocal?.owner_full_name}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_short_name")}
          field={
            <TextFieldFormControl
              name="owner_short_name"
              value={textFieldsFormValueLocal?.owner_short_name}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_kind")}
          field={
            <SelectField
              name="owner_kind"
              value={textFieldsFormValueLocal?.owner_kind}
              options={props.ownerKinds}
              onChange={props.onChangeSelectField}
              isObjectOption={false}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_legal")}
          field={
            <TextFieldFormControl
              name="owner_legal"
              value={textFieldsFormValueLocal?.owner_legal}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_nip")}
          field={
            <TextFieldFormControl
              name="owner_nip"
              value={textFieldsFormValueLocal?.owner_nip}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.krs")}
          field={
            <TextFieldFormControl
              name="owner_krs"
              value={textFieldsFormValueLocal?.owner_krs}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_regon")}
          field={
            <TextFieldFormControl
              name="owner_regon"
              value={textFieldsFormValueLocal?.owner_regon}
              readOnly={props.readOnly}
              onChange={onChangeTextFieldLocal}
              onBlur={handleChangeTextField}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FieldWithLabel
          label={t("dialog.owner_details.owner_active")}
          field={
            <TrueFalseSelectField
              name="owner_active"
              value={textFieldsFormValueLocal?.owner_active}
              valuesAsBool
              addEmptyOptions={false}
              readOnly={props.readOnly}
              onChange={props.onChangeOwnerActivate}
            />
          }
        />
      </Grid>
      {textFieldsFormValueLocal?.owner_active === false &&
        <Grid item xs={12}>
          <FieldWithLabel
            label={t("dialog.owner_details.owner_deactivated_reason")}
            field={
              <SelectField
                name="owner_deactivated_reason"
                value={textFieldsFormValueLocal?.owner_deactivated_reason}
                options={props.deactivateReasonsList}
                onChange={props.onChangeSelectField}
                isObjectOption={false}
                readOnly={props.readOnly}
              />
            }
          />
        </Grid>
      }
      <Grid item xs={12}>
        <TextFieldFormControl
          name="owner_note"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={textFieldsFormValueLocal?.owner_note}
          label={t("dialog.owner_details.note")}
          onChange={onChangeTextFieldLocal}
          onBlur={handleChangeTextField}
        />
      </Grid>
    </Grid>
  );
};

OwnerForm.propTypes = {
  ownerData: PropTypes.object,
  readOnly: PropTypes.bool,
  deactivationReasons: PropTypes.array
};

OwnerForm.defaultProps = {
  deactivationReasons: [],
  readOnly: true,
};

export default OwnerForm;
