import i18n from "../../i18n";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
  NUMERIC_RANGE_FIELD_TYPE,
} from "../../helpers/constants";

import { convertFlatListFromBackendToTranslatedOptionObject } from "../../helpers/methods";

import SelectFieldService from "../../services/selectFieldService";

import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

export const STD_TICKET_TABLE_NAME = "std_ticket";
export const STD_ORDER_TABLE_NAME = "std_order";

export const STD_TICKET_TABLE_PREFIX = "std_ticket_prefix";
export const STD_ORDER_TABLE_PREFIX = "std_order_prefix";

export const STD_TICKET_TABLE_CONFIG = [
  {
    name: "std_ticket_name",
    getValueCallback: (rowData) => rowData.std_ticket_name,
    label: i18n.t("table.std_ticket.std_ticket_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "std_ticket_short_name",
    getValueCallback: (rowData) => rowData.std_ticket_short_name,
    label: i18n.t("table.std_ticket.std_ticket_short_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "ticket_category",
    getValueCallback: (rowData) => rowData.ticket_category?.tc_name,
    label: i18n.t("table.std_ticket.ticket_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.ticket_category,
      isObjectOption: true,
      optionLabelKey: "tc_name",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "std_ticket_area",
    getValueCallback: (rowData) => rowData.std_ticket_area,
    label: i18n.t("table.std_ticket.std_ticket_area"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.std_ticket_area,
      isObjectOption: true,
      optionLabelKey: "ta_area",
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "std_ticket_1question__text",
    getValueCallback: (rowData) => rowData.std_ticket_1question,
    label: i18n.t("table.std_ticket.std_ticket_1question"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.std_ticket_1question,
      isObjectOption: true,
      optionLabelKey: "question_text",
      addNewValue: false,
      multiple: true,
    }),
    other_export_field_name: "std_ticket_1question",
  },
  {
    name: "std_ticket_paid",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.std_ticket_paid),
    label: i18n.t("table.std_ticket.std_ticket_paid"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },

  {
    name: "std_ticket_is_end_list",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.std_ticket_is_end_list
      ),
    label: i18n.t("table.std_ticket.std_ticket_is_end_list"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "std_ticket_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.std_ticket_active),
    label: i18n.t("table.std_ticket.std_ticket_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "std_ticket_external",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.std_ticket_external
      ),
    label: i18n.t("table.std_ticket.std_ticket_external"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "std_ticket_is_exposed_to_api",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(
        rowData.std_ticket_is_exposed_to_api
      ),
    label: i18n.t("table.std_ticket.std_ticket_is_exposed_to_api"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];

export const STD_OTDER_TABLE_CONFIG = [
  {
    name: "std_order_name",
    getValueCallback: (rowData) => rowData.std_order_name,
    label: i18n.t("table.std_order.std_order_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "std_order_kt_need",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.std_order_kt_need),
    label: i18n.t("table.std_order.std_order_kt_need"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "std_order_need",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.std_order_need),
    label: i18n.t("table.std_order.std_order_need"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "std_order_paid",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.std_order_paid),
    label: i18n.t("table.std_order.std_order_paid"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "sla_value",
    getValueCallback: (rowData) => rowData.sla?.sla_value,
    label: i18n.t("table.std_order.sla_value"),
    filterType: NUMERIC_RANGE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    sortable: true,
  },
  {
    name: "sla_unit",
    getValueCallback: (rowData) =>
      rowData.sla
        ? i18n.t(`backend_choices_list.${rowData.sla?.sla_unit}`).toLowerCase()
        : null,
    label: i18n.t("table.std_order.sla_unit"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: convertFlatListFromBackendToTranslatedOptionObject(
        filteringData.value.sla_unit
      ),
      isObjectOption: true,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => (
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} />
    ),
    label: i18n.t("edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];

export const getTablePropsByName = (tableName) => {
  switch (tableName) {
    case STD_TICKET_TABLE_NAME:
      return {
        tableName: STD_TICKET_TABLE_NAME,
        tableConfig: STD_TICKET_TABLE_CONFIG,
        tableFilterPrefix: STD_TICKET_TABLE_PREFIX,
        configForUserPreferenceDialog: [
          {
            name: STD_TICKET_TABLE_NAME,
            config: STD_TICKET_TABLE_CONFIG,
          },
          {
            name: STD_ORDER_TABLE_NAME,
            config: STD_OTDER_TABLE_CONFIG,
          },
        ],
      };
    case STD_ORDER_TABLE_NAME:
      return {
        tableName: STD_ORDER_TABLE_NAME,
        tableConfig: STD_OTDER_TABLE_CONFIG,
        tableFilterPrefix: STD_ORDER_TABLE_PREFIX,
        configForUserPreferenceDialog: [
          {
            name: STD_ORDER_TABLE_NAME,
            config: STD_OTDER_TABLE_CONFIG,
          },
        ],
      };
    default:
      return undefined;
  }
};
