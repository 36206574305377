import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import PrefixSuffixFieldTableWrapper from "../../../wrapper/PrefixSuffixFieldTableWrapper"
import WarningAlertIcon from "../../../other/WarningAlertIcon"
import { formatRackShellData } from "../../../../page/StockWarehousePage/PageTablesConfig";


export const POST_CATEGORY_TABLE_NAME = "post_category_table";
export const POST_CATEGORY_TABLE_CONFIG = [
  {
    name: "post_cat_name",
    getValueCallback: (rowData) => rowData.post_cat_name,
    label: i18n.t("table.correspondence_admin.post_cat_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
  
  {
    name: "post_cat_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.post_cat_active),
    label: i18n.t("table.correspondence_admin.post_cat_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
