import * as React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

import CollapsibleTableDataRow from "./CollapsibleTableDataRow";
import BasicPaginationWithRowsPerPage from "../../base/BasicPaginationWithRowsPerPage";

import { StyledTableHead } from "../../base/BaseTable/BaseTable";

import {
  SELECTED_ROW_COLOR,
  FAVORITE_ROW_COLOR,
  CLAIM_ROW_COLOR,
  CLOSED_ROW_COLOR,
  IS_WITHOUT_RESPOSNE_COLOR,
} from "../../../helpers/constants";
import UniversalTableHeaders from "../UniversalTable/Headers/UniversalTableHeaders";
import BottomTable from "../../other/BottomTable";
import WindowService from "../../../services/windowService";

const CollapsibleTable = React.memo((props) => {
  const getRowBackgroundColor = (props, rowData) => {
    if (props.selectedInnerItemId)
      return rowData.is_favorite === true
        ? FAVORITE_ROW_COLOR
        : rowData.is_without_response
        ? IS_WITHOUT_RESPOSNE_COLOR
        : !rowData.is_closed && rowData.is_claim === true
        ? CLAIM_ROW_COLOR
        : rowData.is_closed
        ? CLOSED_ROW_COLOR
        : undefined;
    else {
      return props.selectedItemId === rowData.id && props.isSelectedOuterItem
        ? SELECTED_ROW_COLOR
        : rowData.is_favorite === true
        ? FAVORITE_ROW_COLOR
        : rowData.is_without_response
        ? IS_WITHOUT_RESPOSNE_COLOR
        : !rowData.is_closed && rowData.is_claim === true
        ? CLAIM_ROW_COLOR
        : rowData.is_closed
        ? CLOSED_ROW_COLOR
        : undefined;
    }
  };

  return (
    <>
      <TableContainer component={Paper} style={props.style}>
        <Table stickyHeader>
          <StyledTableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <UniversalTableHeaders
              showChangesCircle={props.showChangesCircle}
              showCheckbox={props.showCheckbox}
              checkedAll={props.checkedAll}
              showDetailsIcon={props.showDetailsIcon}
              onClickCheckAll={props.onClickCheckAll}
              headersConfig={props.headersConfig}
              resetPageNumber={props.resetPageNumber}
              filterPrefix={props.filterPrefix}
              showCleanFilterIcon={props.showCleanFilterIcon}
              excludedKeysForClearSearchParams={
                props.excludedKeysForClearSearchParams
              }
            />
          </StyledTableHead>
          <TableBody>
            {props.data.map((rowData, index) => (
              <CollapsibleTableDataRow
                showChangesCircle={props.showChangesCircle}
                showCheckbox={props.showCheckbox}
                showDetailsIcon={props.showDetailsIcon}
                onClickDetailsIcon={() =>
                  WindowService.openInNewTab(
                    `/${props.itemDetailsLinkPrefix}/${rowData.id}/`
                  )
                }
                key={rowData.id}
                id={rowData.id}
                updated={rowData.updated}
                is_closed={rowData.is_closed}
                hasChanges={rowData.hasChanges}
                updatedSelectedInnerElement={
                  rowData.updatedSelectedInnerElement
                }
                updatedInnerElementData={rowData.updatedInnerElementData}
                backgroundColor={getRowBackgroundColor(props, rowData)}
                selected={rowData.selected}
                data={rowData.data}
                onClickCheck={props.onClickCheck}
                onClickRow={props.onClickRow}
                CollapsibleElement={props.CollapsibleElement}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BottomTable
        withPagination={props.withPagination}
        page={props.page}
        pageKey={props.pageKey}
        countRecords={props.countRecords}
        onPageChange={props.onPageChange}
        rowsPerPage={props.rowsPerPage}
        onRowsPerPageChange={props.onRowsPerPageChange}
        rowsPerPageOptions={props.rowsPerPageOptions}
        showExportToFileButton={props.showExportToFileButton}
        exportToFileUrl={props.exportToFileUrl}
        exportToFileSearchParams={props.exportToFileSearchParams}
        exportToFileHeaders={props.exportToFileHeaders}
        exportToFileFileName={props.exportToFileFileName}
        filterPrefix={props.filterPrefix}
      />
    </>
  );
});

export default CollapsibleTable;

CollapsibleTable.propTypes = {
  showCheckbox: PropTypes.bool,
  checkedAll: PropTypes.bool,
  showDetailsIcon: PropTypes.bool,
  itemDetailsLinkPrefix: PropTypes.string,
  onClickCheckAll: PropTypes.func,
  onClickCheck: PropTypes.func,
  onClickRow: PropTypes.func,
  data: PropTypes.array,
  headersConfig: PropTypes.object,
  filteringData: PropTypes.object,
  countRecords: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageKey: PropTypes.string,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetPageNumber: PropTypes.func,
  CollapsibleElement: PropTypes.element,
  selectedItemId: PropTypes.string,
  style: PropTypes.object,
  showCleanFilterIcon: PropTypes.bool,
  isSelectedOuterItem: PropTypes.bool,
  showChangesCircle: PropTypes.bool,
  hasChanges: PropTypes.bool,
  showExportToFileButton: PropTypes.bool,
  exportToFileUrl: PropTypes.string,
  exportToFileSearchParams: PropTypes.object,
  exportToFileHeaders: PropTypes.array,
  exportToFileFileName: PropTypes.string,
  excludedKeysForClearSearchParams: PropTypes.array,
};

CollapsibleTable.defaultProps = {
  showCheckbox: true,
  showCleanFilterIcon: false,
  showDetailsIcon: false,
  isSelectedOuterItem: true,
  showChangesCircle: false,
  hasChanges: true,
};
