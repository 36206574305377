import { useState, useEffect, useRef } from "react";

import { useSnackbarAlert } from "../../context/snackbarAlert";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useCorrespondenceService from "../../services/correspondenceService";
import { useAsync} from "../../hooks/useAsync";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import PostDataWindow from "../../components/window/PostDataWindow/PostDataWindow";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import { useForm } from "../../hooks/useForm";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function PostDetailsPage(props) {
  const { pageName } = props.pageName;
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { postId } = useParams();
  const {
    getPostData,
    getPostFilteringData,
  } = useCorrespondenceService();

  const { user } = useAuth();
  const navigate = useNavigate();

  const postData = useAsync(() => getPostData(postId), [postId]);
  const postFilteringData = useAsync(getPostFilteringData);
  


  const {
    formValue,
    setFormValue,
  } = useForm({});


  useEffect(() => {
    setFormValue(postData.value);
  }, [postData]);

 
  const getBackUrl = () => "/my_tickets/";

  const getBackUrlTooltipLabel = () => t("form.ticket_order_details_form.my_tickets");

  const isLoading = postData.loading || postFilteringData.loading;
  if (isLoading) return <LoaderWrapper showLoader={true} />;

  const tagTitle = `MySolvy | ${t("tag_title.post_details")} ${postData.value?.post_title}`;

  return (
    <>
      <Box mt={4}>
        <Helmet>
          <title>{tagTitle}</title>
        </Helmet>
      </Box>
      <DefaultPageWrapper titleKey={"correspondence_post"}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          rowSpacing={4}
          columnSpacing={4}
          alignItems="stretch"
        >
          <Grid item xs={12} sx={{ marginBottom: "5px" }}>
          <div style={{ position: "absolute" }}>
            <IconButton color="primary" onClick={() => navigate(getBackUrl())}>
              <Tooltip title={getBackUrlTooltipLabel()}>
                <KeyboardBackspaceIcon />
              </Tooltip>
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} ></Grid>
          {(
            <Grid item xs={12} >
              <PostDataWindow
                dialogMode={props.dialogMode}
                onRefetchData={props.onRefetchData}
                postIn={props.postIn}
                autocompleteData={postFilteringData.value}
                formValue={formValue}
                postId={postId}
                readOnly={true}
                xs={true}
              />
            </Grid>
          )}
        </Grid>
      </DefaultPageWrapper>
    </>
  );
}

PostDetailsPage.propTypes = {};

export default PostDetailsPage;
