import i18n from "../../../i18n";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";

import {
  TEXT_FIELD_TYPE,
  AUTOCOMPLETE_FIELD_TYPE,
  BOOLEAN_FIELD_TYPE,
} from "../../../helpers/constants";
import SelectFieldService from "../../../services/selectFieldService";

export const TENANT_TABLE_NAME = "tenant_table";
export const TENANT_TABLE_FILTER_PREFIX = "tenants__tenant";
export const TENANT_TABLE_CONFIG = [
  {
    name: "tenant_full_name",
    getValueCallback: (rowData) => rowData.tenant_full_name,
    label: i18n.t("table.tenant_table.tenant_full_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "tenant_short_name",
    getValueCallback: (rowData) => rowData.tenant_short_name,
    label: i18n.t("table.tenant_table.tenant_short_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    sortable: true,
  },
  {
    name: "tenant_legal",
    getValueCallback: (rowData) => rowData.tenant_legal,
    label: i18n.t("table.tenant_table.tenant_legal"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant_nip",
    getValueCallback: (rowData) => rowData.tenant_nip,
    label: i18n.t("table.tenant_table.tenant_nip"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant_regon",
    getValueCallback: (rowData) => rowData.tenant_regon,
    label: i18n.t("table.tenant_table.tenant_regon"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant_krs",
    getValueCallback: (rowData) => rowData.tenant_krs,
    label: i18n.t("table.tenant_table.tenant_krs"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant_iban",
    getValueCallback: (rowData) => rowData.tenant_iban,
    label: i18n.t("table.tenant_table.tenant_iban"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "tenant_priority",
    getValueCallback: (rowData) => rowData.tenant_priority,
    label: i18n.t("table.tenant_table.tenant_priority"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.tenant_priority,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },
  {
    name: "address_zip",
    getValueCallback: (rowData) => rowData.address_zip,
    label: i18n.t("table.tenant_table.address_zip"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "address_city",
    getValueCallback: (rowData) => rowData.address_city,
    label: i18n.t("table.tenant_table.address_city"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "address_street",
    getValueCallback: (rowData) => rowData.address_street,
    label: i18n.t("table.tenant_table.address_street"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "address_building",
    getValueCallback: (rowData) => rowData.address_building,
    label: i18n.t("table.tenant_table.address_building"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "address_apartment",
    getValueCallback: (rowData) => rowData.address_apartment,
    label: i18n.t("table.tenant_table.address_apartment"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "address_kind",
    getValueCallback: (rowData) => rowData.address_kind,
    label: i18n.t("table.tenant_table.address_kind"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.address_kind,
      isObjectOption: false,
      addNewValue: false,
      multiple: true,
    }),
  },

  {
    name: "tenant_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.tenant_active),
    label: i18n.t("table.tenant_table.tenant_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        rowData.allow_edit? 
      <ModeEditOutlineOutlined onClick={(e) => onClickEdit(rowData.id)} /> 
      :
      undefined
    )},
    label: i18n.t("table.tenant_table.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
    allowChangeVisibility: false,
    excludedFromExport: true,
  },
];
