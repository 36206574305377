import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AutocompleteField from "../../../field/AutocompleteField";
import DateField from "../../../field/DateField/DateField";
import PostNotificationTable from "../../../table/PostNotificationTable";
import TextFieldFormControl from "../../../field/TextFieldFormControl";
import BaseBox from "../../../base/BaseBox/baseBox";
import FileUploadList from "../../../other/FileUploadList";
import { useForm } from "../../../../hooks/useForm";
import { useTranslation } from "react-i18next";
import FieldWithLabel from "../../../field/FieldWithLabel";
import CheckboxField from "../../../base/CheckboxField/checkboxField";


const PostDetailsForm = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={2}
    >
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.post_nr")}
          field={
            <TextFieldFormControl
              name="post_nr"
              value={props.itemData.post_nr}
              disabled
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.shipment_category")}
          field={
            <AutocompleteField
              name="post_subcat_category"
              value={props.itemData.post_subcat_category}
              options={props.autocompleteData.post_subcat_category}
              isObjectOption={true}
              optionLabelKey={"post_cat_name"}
              addNewValue={false}
              required
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("form.post_form.shipment_subcategory")}
          field={
            <AutocompleteField
              name="post_subcat"
              value={props.itemData.post_subcat}
              isObjectOption={true}
              optionLabelKey={"post_subcat_name"}
              options={props.autocompleteData.post_subcat}
              addNewValue={false}
              required
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.document_nr")}
          field={
            <TextFieldFormControl
              name="post_decree"
              value={props.itemData.post_decree}
              disabled
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.reference")}
          field={
            <AutocompleteField
              name="post_parent"
              value={props.itemData.post_parent}
              options={props.autocompleteData.post_parent}
              optionLabelKey={"post_title"}
              required
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.arrival_date")}
          field={
            <DateField
              name="post_date_in"
              label={t("table.correspondence.arrival_date")}
              value={props.itemData.post_date_in}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.owner_short_name")}
          field={
            <AutocompleteField
              name="post_owner"
              label={t("table.correspondence.owner_short_name")}
              value={props.itemData.post_owner}
              options={props.autocompleteData.post_owner}
              isObjectOption={true}
              optionLabelKey={"owner_short_name"}
              addNewValue={false}
              disabled={props.readOnly}
              required
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={props.itemData.post_in ? t("table.correspondence.sender_type") : t("table.correspondence.recipient_type")}
          field={
            <AutocompleteField
              name="post_side_type"
              value={props.itemData.post_side_type}
              options={props.autocompleteData.post_side_type}
              isObjectOption={true}
              optionLabelKey={"side_type_name"}
              addNewValue={false}
              disabled={props.readOnly}

            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={props.itemData.post_in ? t("table.correspondence.sender_name") : t("table.correspondence.recipient_name")}
          field={
            <TextFieldFormControl
              name="post_side_name"
              value={props.itemData.post_side_name}
              isObjectOption={true}
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={props.itemData.post_in ? t("table.correspondence.sender_address") : t("table.correspondence.recipient_address")}
          field={
            <TextFieldFormControl
              name="post_side_address"
              value={props.itemData.post_side_address}
              disabled
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={props.itemData.post_in ? t("table.correspondence.recipient_person") : t("table.correspondence.sender_person")}
          field={
            <TextFieldFormControl
              name="post_person"
              value={props.itemData.post_person}
              disabled
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.delivery_type")}
          field={
            <AutocompleteField
              name="post_delivery"
              value={props.itemData.post_delivery}
              options={props.autocompleteData.post_delivery}
              isObjectOption={true}
              optionLabelKey={"delivery_name"}
              addNewValue={false}
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.deadline")}
          field={
            <DateField
              name="post_event_date"
              label={t("table.correspondence.deadline")}
              value={props.itemData.post_event_date}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.notification_date")}
          field={
            <DateField
              name="postrec_info_date"
              label={t("table.correspondence.notification_date")}
              value={props.itemData.postrec_info_date}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.registration_date")}
          field={
            <DateField
              name="post_registration_date"
              label={t("table.correspondence.registration_date")}
              value={props.itemData.post_registration_date}
              readOnly={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.archiving_place")}
          field={
            <AutocompleteField
              name="post_archives_location"
              label={t("table.correspondence.archiving_place")}
              value={props.itemData.post_archives_location}
              options={props.autocompleteData.post_archives_location}
              optionLabelKey={"post_arch_name"}
              addNewValue={false}
              disabled
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.status")}
          field={
            <AutocompleteField
              name="post_status"
              value={props.itemData.post_status}
              options={props.autocompleteData.post_status}
              isObjectOption={true}
              optionLabelKey={"post_status_name"}
              addNewValue={false}
              disabled={props.readOnly}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.ZPO")}
          field={
            <CheckboxField
            name="post_zpo"
            label={t("table.correspondence.ZPO")}
            checked={props.itemData.post_zpo}
            value={props.itemData.post_zpo}
            disabled={props.readOnly}
          />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWithLabel
          label={t("table.correspondence.note")}
          field={
            <TextFieldFormControl
              name="post_note"
              label={t("table.correspondence.note")}
              value={props.itemData.post_note}
              disabled
              multiline
              rows={4}
            />
          }
        />
      </Grid>
    </Grid>
  );

};

PostDetailsForm.propTypes = {
  formValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeAutocompleteFieldWithObjectOptions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PostDetailsForm.defaultProps = {
  readOnly: false,
};

export default PostDetailsForm;