import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";

import { IconButton } from "@mui/material";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";

const TenantDetailsForm = (props) => {
  const { t } = useTranslation();


  const formContent = (
    <>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"tenant_full_name"}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.formValue.tenant_full_name}
          label={t("dialog.tenant_details.tenant_full_name")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"tenant_short_name"}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.formValue.tenant_short_name}
          label={t("dialog.tenant_details.tenant_short_name")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"tenant_legal"}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.formValue.tenant_legal}
          label={t("dialog.tenant_details.tenant_legal")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"tenant_nip"}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.formValue.tenant_nip}
          label={t("dialog.tenant_details.tenant_nip")}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name={"tenant_regon"}
          onChange={props.onChange}
          readOnly={props.readOnly}
          value={props.formValue.tenant_regon}
          label={t("dialog.tenant_details.tenant_regon")}
        />
      </Grid>
      {props.showKrs && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"tenant_krs"}
            onChange={props.onChange}
            readOnly={props.readOnly}
            value={props.formValue.tenant_krs}
            label={t("dialog.tenant_details.tenant_krs")}
          />
        </Grid>
      )}
      {props.showIban && (
        <Grid item xs={12}>
          <TextFieldFormControl
            name={"tenant_iban"}
            onChange={props.onChange}
            readOnly={props.readOnly}
            value={props.formValue.tenant_iban}
            label={t("dialog.tenant_details.tenant_iban")}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <TextFieldFormControl
          name={"address"}
          readOnly={true}
          required
          value={props.formValue.address}
          label={t("dialog.tenant_details.address")}
          suffix={
            !props.readOnly ? (
              <IconButton onClick={props.onClickAddress} edge="end">
                <ModeEditOutlineOutlined />
              </IconButton>
            ) : null
          }
        />
      </Grid>
      {props.showContacts && (
        <>
          <Grid item xs={12}>
            <TextFieldFormControl
              name={"phone"}
              onChange={props.onChange}
              readOnly={props.readOnly}
              value={props.formValue.phone}
              label={t("dialog.tenant_details.phone")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormControl
              name={"mail"}
              onChange={props.onChange}
              readOnly={props.readOnly}
              value={props.formValue.mail}
              label={t("dialog.tenant_details.mail")}
            />
          </Grid>
          <Grid item xs={12}>
            <TrueFalseSelectField
              name={"tenant_active"}
              onChange={props.onChange}
              readOnly={props.readOnly}
          valuesAsBool

              value={props.formValue.tenant_active}
              label={t("dialog.tenant_details.tenant_active")}
            />
          </Grid>
        </>
      )}
    </>
  );

  if (props.onlyFormContent) return formContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      rowGap={1}
    >
      {formContent}
    </Grid>
  );
};

TenantDetailsForm.propTypes = {
  formValue: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onlyFormContent: PropTypes.bool,
  showContacts: PropTypes.bool,
  showIban: PropTypes.bool,
  showKrs: PropTypes.bool,
  onClickAddress: PropTypes.func,
};

TenantDetailsForm.defaultProps = {
  readOnly: true,
  onlyFormContent: false,
  showIban: false,
  showKrs: false,
  showContacts: true,
  onClickAddress: () => {},
};

export default TenantDetailsForm;
