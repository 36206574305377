import React from "react";
import PostDataWindow from "../../window/PostDataWindow/PostDataWindow";
import BasicDialog from "../../base/BasicDialog";
import { useTranslation } from "react-i18next";

const CreatePostDialog = (props) => {
  const { t } = useTranslation();


  return (

    <BasicDialog
    open={props.open}
    onClose={props.onClose}
    titleAlign="center"
    maxWidth="full"
    backdropColor={props.backdropColor}
    title = {
      props.formValue && props.formValue?.id
        ? props.postIn
          ? t("dialog.post_dialog.edit_post_in")
          : t("dialog.post_dialog.edit_post_out")
        : props.postIn
          ? t("dialog.post_dialog.add_post_in")
          : t("dialog.post_dialog.add_post_out")
    }
  >

    <PostDataWindow
      open={props.open}
      onClose={props.onClose}
      onSubmitCallback={props.onSubmitCallback}
      dialogMode={props.dialogMode}
      onRefetchData={props.onRefetchData}
      postIn={props.postIn}
      autocompleteData={props.autocompleteData}
      backdropColor={props.backdropColor}
      formValue={props.formValue}
      postId={props.postId}
      readOnly={props.readOnly}
    />
    </BasicDialog>
  )
};

export default CreatePostDialog;
