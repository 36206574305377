import { Grid, Paper } from "@mui/material";
import React, { useRef, useEffect } from "react";
import CardIconWithText from "../CardIconWithText";
import PropTypes from "prop-types";

export const DocsRestrictedCardTypes = React.memo((props) => {
  const seletedRestrictedTypeRef = useRef();

  const isSelectedRestrictedType = (restrictedType) =>
    restrictedType.id === props.selectedRestrictedTypeId;

  useEffect(() => {
    if (seletedRestrictedTypeRef.current) {
      seletedRestrictedTypeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [props.selectedRestrictedTypeId, seletedRestrictedTypeRef.current]);

  return (
    <Paper elevation={0} sx={{ height: props.height, overflow: "auto" }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        padding={1}
        columns={15}
      >
        {props.restrictedTypes.map((restrictedType, _index) => (
          <Grid
            item
            xs={7.5}
            sm={3}
            ref={
              isSelectedRestrictedType(restrictedType)
                ? seletedRestrictedTypeRef
                : null
            }
          >
            <CardIconWithText
              label={restrictedType.label}
              icon={restrictedType.icon}
              isSelected={isSelectedRestrictedType(restrictedType)}
              cardAreaStyle={{
                height: "11vh",
                alignItems: "center",
                textAlign: "center",
              }}
              onClick={
                props.readOnly
                  ? () => {}
                  : () => props.onCardClick(restrictedType.id)
              }
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
});

DocsRestrictedCardTypes.propTypes = {
  restrictedTypes: PropTypes.array,
  onCardClick: PropTypes.func,
  selectedRestrictedTypeId: PropTypes.string,
  readOnly: PropTypes.bool,
  height: PropTypes.string,
};

DocsRestrictedCardTypes.defaultProps = {
  restrictedTypes: [],
  height: "125px",
};

export default DocsRestrictedCardTypes;
