import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useDialog from "../../../../hooks/useDialog";
import TableService from "../../../../services/tableService";
import FilterTable from "../../../table/FilterTable";
import { useEmptyArrayMemo } from "../../../../hooks/useEmptyArrayMemo";
import { useSearchParams } from "react-router-dom";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import { centerVericalAlignIconStyle } from "../../../../helpers/styles";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import SearchButton from "../../../button/SearchButton/SearchButton";
import FilterDialog from "../../../base/FilterDialog";
import UniversalFilterForm from "../../../form/UniversalFilterForm";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import { POST_SIDE_TYPE_TABLE_CONFIG } from "./TableConfig";
import useCorrespondenceAdminService from "../../../../services/correspondenceAdminService";
import CreatePostSideTypeDialog from "../../../dialog/CreatePostSideTypeDialog";

const POST_SIDE_TYPE_FILTER_PREFIX = "post_side_type";

const CorrespondenceAdminPostSideTypeTab = (props) => {
  const { t } = useTranslation();
  const [postSideTypesLocal, setPostSideTypesLocal] = useState([]);
  const [countRecords, setCountRecords] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postSideTypeTableSearchParams, setPostSideTypeTableSearchParams] = useState({});

  const { setNewItemSearchParamsIfAreChanged, clearSearchParamsByFilterPrefix } =
    useFilterSearchParams(searchParams);

  const { emptyArrayMemo } = useEmptyArrayMemo();
  const { getPostSideTypeList } = useCorrespondenceAdminService(); 

  const onCleanFiltersInFilterDialog = () => {
    setSearchParams(clearSearchParamsByFilterPrefix(POST_SIDE_TYPE_FILTER_PREFIX));
  };

  const [
    openPostSideTypeDialog,
    postSideTypeChangeId,
    onOpenPostSideTypeDialog,
    onClosePostSideTypeDialog,
    exportChangeSideTypeStatus
  ] = useDialogWithId();

  const [openFilterDialog, onOpenFilterDialog, onCloseFilterDialog] =
    useDialog();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
  } = usePaginationWithSearchParams(POST_SIDE_TYPE_FILTER_PREFIX);

  const postSideTypeData = useAsync(
    () => getPostSideTypeList(postSideTypeTableSearchParams),
    [postSideTypeTableSearchParams]
  );

  useEffect(() => {
    if (postSideTypeData.loading) {
      return;
    }
    setPostSideTypesLocal(postSideTypeData.value.results);
    setCountRecords(postSideTypeData.value.count);
  }, [postSideTypeData.loading]);

  const isLoading = postSideTypeData.loading || props.filteringData.loading;

  const handleOpenPostSideTypeDialog = useCallback(
    (e, postSideTypeId) => {
      e.stopPropagation();
      onOpenPostSideTypeDialog(postSideTypeId);
    },
    []
  );

  const refreshTable = () => {
    setPostSideTypesLocal([]);
    postSideTypeData.refetch();
  };

  const data = React.useMemo(
    () =>
      TableService.getPreparedDataForCollapsibleTable(
        POST_SIDE_TYPE_TABLE_CONFIG,
        postSideTypesLocal ? postSideTypesLocal : emptyArrayMemo,
        emptyArrayMemo,
        emptyArrayMemo,
        handleOpenPostSideTypeDialog,
      ),
    [postSideTypesLocal, POST_SIDE_TYPE_TABLE_CONFIG]
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_SIDE_TYPE_FILTER_PREFIX,
      postSideTypeTableSearchParams,
      setPostSideTypeTableSearchParams
    );
  }, [searchParams]);

  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} sm={2}>
        <Button
          fullWidth
          variant="contained"
          size="small"
          startIcon={<ControlPointOutlinedIcon sx={centerVericalAlignIconStyle} />}
          onClick={(e) => onOpenPostSideTypeDialog(undefined)}
        >
          {t("tabs.correspondence_admin.add_post_side_type")}
        </Button>
      </Grid>
      <Grid item sm={9} />
      <Grid item xs={12} sm={1}>
        <SearchButton onClickSearch={onOpenFilterDialog} />
      </Grid>
      <Grid item xs={12}>
        <FilterTable
          data={data}
          showCheckbox={false}
          showCleanFilterIcon={true}
          countRecords={countRecords}
          page={page}
          onPageChange={handleChangePageWithSearchParams}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          resetPageNumber={resetPageNumber}
          filteringData={props.filteringData}
          style={{ maxHeight: "vh" }}
          tableConfig={POST_SIDE_TYPE_TABLE_CONFIG}
          showContextMenu={false}
          filterPrefix={POST_SIDE_TYPE_FILTER_PREFIX}
          headersConfig={TableService.getHeadersConfigForCollapsibleTable(
            POST_SIDE_TYPE_TABLE_CONFIG,
            props.filteringData || emptyArrayMemo,
            emptyArrayMemo,
            emptyArrayMemo,
          )}
        />
      </Grid>
      {openFilterDialog &&
        <FilterDialog
          open={openFilterDialog}
          onCleanFilters={onCleanFiltersInFilterDialog}
          onClose={onCloseFilterDialog}
          resetPageNumber={resetPageNumber}
          filterPrefix={POST_SIDE_TYPE_FILTER_PREFIX}
          filterForm={
            <UniversalFilterForm
              filteringData={props.filteringData || emptyArrayMemo}
              filtersConfig={POST_SIDE_TYPE_TABLE_CONFIG}
              includeOpenCloseFilter={false}
            />
          }
        />
      }
      {openPostSideTypeDialog &&
        <CreatePostSideTypeDialog
          sideTypeId={postSideTypeChangeId}
          open={openPostSideTypeDialog}
          onClose={onClosePostSideTypeDialog}
          onRefetchData={refreshTable}
          filteringData={props.filteringData}
        />
      }
    </Grid>
  );
};

CorrespondenceAdminPostSideTypeTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondenceAdminPostSideTypeTab.defaultProps = {};

export default CorrespondenceAdminPostSideTypeTab;
