import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOfferTableService from "../services/offerTableService";
import useOfferService from "../services/offerService";
import { useAsync, useAsyncFn } from "./useAsync";
import { useAuth } from "../context/auth";
import { useSnackbarAlert } from "../context/snackbarAlert";
import useSettlementTableService from "../services/settlementTableService";
import { getFieldsFromObject } from "../helpers/methods";
import useDialog from "./useDialog";
import useSettlementData from "./useSettlementData";
import { SETTLEMENT_OM_TABLE_FIELDS_CONFIG } from "../page/MyOffersAcceptPage/MyOffersTablesConfig";
import { OFFER_ACCEPED, OFFER_ACCEPED_CR, OFFER_REJECTED, OFFER_REJECTED_CR } from "../helpers/constants";



const useAcceptRejectOffers = (
    offerId,
    totalCostData,
    offersDataRefetch,
    onCloseOfferDocumentDialog,
    isOfferCR = false
) => {
    const snackbarAlert = useSnackbarAlert();
    const { t } = useTranslation();

    const [existsChanges, setExistsChanges] = useState(false);

    const [
        openRejectOfferDialog,
        onOpenRejectOfferDialog,
        handleCloseRejectOfferDialog,
    ] = useDialog();

    const [
        openSettlementOfferDialog,
        onOpenSettlementOfferDialog,
        handleCloseSettlementOfferDialog,
    ] = useDialog();

    const {
        updateMyOfferData,
        sendMessageToMyOffer, } =
        useOfferService();

    const {
        offerSettlementData,
        onChangeSettlementTableData,
        getSettlementsToSend,
    } = useSettlementData(
        offerId ? offerId : undefined,
        SETTLEMENT_OM_TABLE_FIELDS_CONFIG,
        totalCostData ? totalCostData : undefined,
        true,
        "my-offers"
    );


    const updateOfferDataFn = useAsyncFn(updateMyOfferData);

    const sendMessageToMyOfferFn = useAsyncFn(sendMessageToMyOffer);


    const onSendMessageToOffer = useCallback(
        (formValue) => {
            sendMessageToMyOfferFn
                .execute(offerId, formValue)
                .then((res) => {
                    setExistsChanges(true)
                    snackbarAlert.openSuccessSnackbarAlert(
                        t("snackbar_alert.message_sent")
                    );
                })
                .catch((error) => {
                    snackbarAlert.openErrorSnackbarAlert(
                        t("snackbar_alert.occurred_error_during_message_sending")
                    );
                });
            onCloseOfferDocumentDialog();
            handleCloseRejectOfferDialog();
            handleCloseSettlementOfferDialog();
        },
        [offerId]
    );

    const handleSendAcceptOffer = useCallback(() => {
        onOfferUpdate(onPrepareDataToSave(isOfferCR ? OFFER_ACCEPED_CR :OFFER_ACCEPED));
    }, [offerId, offerSettlementData, isOfferCR]);

    const handleSendRejectOffer = useCallback(
        (data) => {
            onOfferUpdate(onPrepareDataToSave(isOfferCR ? OFFER_REJECTED_CR:OFFER_REJECTED, data));
        },
        [offerId, offerSettlementData, isOfferCR]
    );

    const onPrepareDataToSave = (action, data = {}) => {
        let dataToSend = {};
        dataToSend.offer_details = { ...data, id: offerId };
        dataToSend.action = action;
        dataToSend.settlements = getSettlementsToSend();
        return dataToSend;
    };

    const onOfferUpdate = (data) => {
        updateOfferDataFn
            .execute(offerId, data)
            .then((res) => {
                setExistsChanges(true)
                snackbarAlert.openSuccessSnackbarAlert(
                    t("snackbar_alert.offer_data_updated")
                );
                onCloseOfferDocumentDialog();
                handleCloseRejectOfferDialog();
                handleCloseSettlementOfferDialog();
                offersDataRefetch()
            })
            .catch((error) => {
                snackbarAlert.openErrorSnackbarAlert(
                    t("snackbar_alert.occurred_error_during_updating_offer")
                );
            });
    };

    return {
        onSendMessageToOffer,
        handleSendAcceptOffer,
        handleSendRejectOffer,
        openSettlementOfferDialog,
        onOpenSettlementOfferDialog,
        openRejectOfferDialog,
        onOpenRejectOfferDialog,
        handleCloseRejectOfferDialog,
        handleCloseSettlementOfferDialog,
        existsChanges, 
        offerSettlementData,
        onChangeSettlementTableData,
        offerDetailsLoading : updateOfferDataFn.loading
    };
};

export default useAcceptRejectOffers;
