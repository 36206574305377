import { EXTERNAL_TYPE } from "../helpers/constants";
import { useState } from "react";
import { getRandomString } from "../helpers/methods";
const useFileOnMemoryData = (objectType = "enclosure", initialFiles = []) => {
  const [localFiles, setLocalFiles] = useState(initialFiles);

  const onAddFile = (files, extraData = {}) => {
    let tempFiles = files.map(file => ({...file, extraData  :extraData}))
    setLocalFiles((filesTemp) => [...filesTemp, ...tempFiles]);
  };


  const onDeleteFile = (index) => {
    setLocalFiles((filesTemp) => {
      filesTemp = [...filesTemp];
      filesTemp.splice(index, 1);
      return filesTemp;
    });
  };

  const onUpdateFile = (file, index) => {
    setLocalFiles((filesTemp) => {
      filesTemp = [...filesTemp];
      filesTemp[index] = file;
      return filesTemp;
    });
  };

  const prepareFilesToSend = (files) => {
    let prepraredData = []
    for (let file of files) {
      file["objectType"] = objectType;
      file["enclosure_external"] = file.enclosure_type === EXTERNAL_TYPE;
      file["token_data"] = {
        file_ori_name: getFileName(file),
        unique_index: getRandomString(),
        content_type: file["file"].type,
      };
      let data = file.extraData
      delete file.extraData
      prepraredData.push({...file, ...data})
    }
    return prepraredData;
  };

  const getFileName = (file) => {
    switch (objectType) {
      case "docs":
        return file.docs_name;
      case "certs":
        return file.cert_name;
      default:
        return file.enclosure_name;
    }
  };

  return [
    localFiles,
    onAddFile,
    onUpdateFile,
    onDeleteFile,
    prepareFilesToSend,
  ];
};

export default useFileOnMemoryData;
