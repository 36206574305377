import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import CheckboxField from "../../field/CheckboxField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useCorrespondenceAdminService from "../../../services/correspondenceAdminService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import AutocompleteField from "../../field/AutocompleteField";

const CreateSideAdministrationDialog = (props) => {
  const { t } = useTranslation();
  const { formValue, setFormValue, onChange, onChangeAutocompleteFieldWithObjectOptions } = useForm({
    administration_active: true,
  });

  const {
    createSideAdministration,
    updateSideAdministrationData,
    getSideAdministrationDetails,
    getPostSideTypeList,
  } = useCorrespondenceAdminService();
  
  const createSideAdministrationFn = useAsyncFn(createSideAdministration);
  const updateSideAdministrationFn = useAsyncFn(updateSideAdministrationData);
  const snackbarAlert = useSnackbarAlert();

  const sideAdministrationDetailsData = useAsync(() => {
    if (props.sideAdministrationId) {
      return getSideAdministrationDetails(props.sideAdministrationId);
    }
    return Promise.resolve({});
  }, [props.sideAdministrationId]);

  const sideTypeOptions = useAsync(getPostSideTypeList, []);

  useEffect(() => {
    if (!sideAdministrationDetailsData.loading && sideAdministrationDetailsData.value && props.sideAdministrationId) {
      setFormValue(sideAdministrationDetailsData.value);
    }
  }, [props.sideAdministrationId, sideAdministrationDetailsData.loading]);

  const onSubmit = (sideAdministrationData) => {
    if (sideAdministrationData.id) {
      updateSideAdministrationFn.execute(sideAdministrationData.id, sideAdministrationData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.side_administration_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_updating_side_administration"));
        });
    } else {
      createSideAdministrationFn.execute(sideAdministrationData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.side_administration_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_side_administration"));
        });
    }
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formValue);
    }
  };

  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const isFormValid = () => {
    return !isEmptyValue(formValue.administration_name) && !isEmptyValue(formValue.administration_side_type);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      title={props.sideAdministrationId ? t("dialog.correspondence_admin_panel_dialogs.edit_side_administration") : t("dialog.correspondence_admin_panel_dialogs.add_side_administration")}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_name"
            label={t("dialog.correspondence_admin_panel_dialogs.side_administration_name")}
            value={formValue["administration_name"]}
            onChange={onChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteField
            name="administration_side_type"
            label={t("dialog.correspondence_admin_panel_dialogs.side_type_name")}
            optionValueKey="id"
            optionLabelKey="side_type_name"
            value={formValue["administration_side_type"]}
            options={props.filteringData.value.post_side_type}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_subname"
            label={t("dialog.correspondence_admin_panel_dialogs.side_administration_address")}
            value={formValue["administration_subname"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_code"
            label={t("dialog.correspondence_admin_panel_dialogs.code")}
            value={formValue["administration_code"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_city"
            label={t("dialog.correspondence_admin_panel_dialogs.city")}
            value={formValue["administration_city"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_street"
            label={t("dialog.correspondence_admin_panel_dialogs.street")}
            value={formValue["administration_street"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_house"
            label={t("dialog.correspondence_admin_panel_dialogs.house")}
            value={formValue["administration_house"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="administration_local"
            label={t("dialog.correspondence_admin_panel_dialogs.local")}
            value={formValue["administration_local"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name="administration_active"
            label={t("dialog.correspondence_admin_panel_dialogs.active")}
            checked={formValue["administration_active"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            {props.sideAdministrationId ? t("save") : t("add")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton variant="outlined" color="secondary" fullWidth onClick={props.onClose}>
            {t("cancel")}
          </LoadingButton>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

CreateSideAdministrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefetchData: PropTypes.func,
  sideAdministrationId: PropTypes.string,
};

CreateSideAdministrationDialog.defaultProps = {
  sideAdministrationId: null,
  onRefetchData: null,
};

export default CreateSideAdministrationDialog;
