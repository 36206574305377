import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import LoaderWrapper from "../../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../../hooks/useAsync";
import usePaginationWithSearchParams from "../../../../hooks/usePaginationWithSearchParams";
import useFilterSearchParams from "../../../../hooks/useFilterSearchParams";
import useCorrespondenceService from "../../../../services/correspondenceService";
import UniversalToolBarWithDialogs from "../../../bar/UniversalToolBarWithDialogs";
import TableService from "../../../../services/tableService";
import { exportPostInListToFileUrl} from "../../../../helpers/apiUrls";
import {
  isSearchParamsObjectEmpty,
} from "../../../../helpers/methods";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {
  centerVericalAlignIconStyle,
  overflowButtonStyle,
} from "../../../../helpers/styles";

import {
  POST_TABLE_NAME,
  POST_TABLE_FILTER_PREFIX,
  POST_IN_TABLE_CONFIG,
} from "./TableConfig";
import CorrespondenceTable from "../../../table/CorrespondenceTable";
import CreatePostDialog from "../../../dialog/CreatePostDialog/CreatePostDialog";
import useDialogWithId from "../../../../hooks/useDialogWithId";
import { DIALOG_CREATE_MODE, DIALOG_EDIT_MODE } from "../../../../helpers/constants";
import useDialog from "../../../../hooks/useDialog";
import usePermissionService from "../../../../services/permissionService";
import useUserService from "../../../../services/userService";
import DetailsDrawer from "../../../drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import SnackbarAlert from "../../../base/SnackbarAlert";
import useBasicDrawer from "../../../../hooks/useBasicDrawer";

const tableConfig = {
  name: POST_TABLE_NAME,
  config: POST_IN_TABLE_CONFIG,
};

const CorrespondencePostInTab = (props) => {
  const { t } = useTranslation();

  const {
    page,
    pageSize,
    handleChangePageWithSearchParams,
    handleChangeRowsPerPage,
    resetPageNumber,
    searchParams,
    setSearchParams,
  } = usePaginationWithSearchParams(POST_TABLE_FILTER_PREFIX);

  const [
    openCreatePostDialog,
    onOpenCreatePostDialog,
    onCloseCreatePostDialog,
  ] = useDialog();

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] = useState(true);
  const postDialogModeRef = useRef();

  const handleOpenPostDialogInCreateMode = () => {
    postDialogModeRef.current = DIALOG_CREATE_MODE;
    onOpenCreatePostDialog();
  };

  const handleOpenPostDialogInEditMode = (postId) => {
    postDialogModeRef.current = DIALOG_EDIT_MODE;
    handleOpenDetailsDrawer(postId);
  };

  const getPostDialogCallback = () => {
    if (
      postDialogModeRef.current === DIALOG_CREATE_MODE &&
      !isSearchParamsObjectEmpty(postInSearchParams)
    ) {
      setSearchParams(
        clearSearchParamsByFilterPrefix(POST_TABLE_FILTER_PREFIX)
      );
    } else {
      postInData.refetch();
    }
  };

  const {
    setNewItemSearchParamsIfAreChanged,
    getSearchParamsByFilterPrefix,
    clearSearchParamsByFilterPrefix,
  } = useFilterSearchParams(searchParams);

  const [postInSearchParams, setPostInSearchParams] = useState(
    getSearchParamsByFilterPrefix(POST_TABLE_FILTER_PREFIX)
  );

  useEffect(() => {
    setNewItemSearchParamsIfAreChanged(
      POST_TABLE_FILTER_PREFIX,
      postInSearchParams,
      setPostInSearchParams
    );
  }, [searchParams]);

  const { getPostsInData, getPostData } = useCorrespondenceService();
  const postInData = useAsync(() => getPostsInData({ ...postInSearchParams }), [
    postInSearchParams,
  ]);
  const [postInDataLocal, setPostInDataLocal] = useState([]);
  const [countRecords, setCountRecords] = useState(0);

  useEffect(() => {
    if (postInData.loading) {
      return;
    }
    setPostInDataLocal(postInData.value ? postInData.value.results : []);
    setCountRecords(postInData.value ? postInData.value.count : 0);
  }, [postInData.loading, postInData.value]);

  const [hiddenColumnsForTables, setHiddenColumnsForTables] = useState({});
  const [columnsOrdersForTables, setColumnsOrdersForTables] = useState({});

  const refreshTable = () => {
    setPostInDataLocal([]);
    postInData.refetch();
    if (openDetailsDrawer && openedPostIdRef.current) {
      executeGetPostDetailsDataFn(openedPostIdRef.current);
    }
  };
  const refreshDrawerTable = () => {
    if (openDetailsDrawer && openedPostIdRef.current) {
      executeGetPostDetailsDataFn(openedPostIdRef.current);
    }
  };
  const onCleanFiltersInFilterDialog = () => {
    setPostInDataLocal([]);
    setCountRecords(0);
  };

  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const openedPostIdRef = useRef();
  const opendedPostDetailsDataRef = useRef();
  const getPostDataFn = useAsyncFn(getPostData);

  const handleOpenDetailsDrawer = useCallback(
    (postId) => {
      if (
        openDetailsDrawer &&
        (!postId || postId === opendedPostDetailsDataRef.current?.id)
      ) {
        setOpenDetailsDrawer(false);
        openedPostIdRef.current = null;
        opendedPostDetailsDataRef.current = null;
      } else {
        setOpenDetailsDrawer(true);
        openedPostIdRef.current = postId;
        executeGetPostDetailsDataFn(postId);
      }
    },
    [openDetailsDrawer, openedPostIdRef.current]
  );

  const executeGetPostDetailsDataFn = (postId) => {
    getPostDataFn.execute(postId).then(
      (data) => (opendedPostDetailsDataRef.current = data)
    );
  };

  const postExportHeaders = useMemo(() => {
    if (!hiddenColumnsForTables || !columnsOrdersForTables) return [];
    return TableService.getTableExportHeaders(
      POST_IN_TABLE_CONFIG,
      hiddenColumnsForTables?.[POST_TABLE_NAME],
      columnsOrdersForTables?.[POST_TABLE_NAME]
    );
  }, [
    hiddenColumnsForTables?.[POST_TABLE_NAME],
    columnsOrdersForTables?.[POST_TABLE_NAME],
    POST_IN_TABLE_CONFIG,
  ]);

  const isLoading = postInData.loading || props.filteringData.loading;
  if (isLoading) return <LoaderWrapper showLoader={true} />;

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={handleOpenDetailsDrawer}
      filteringData={props.filteringData}
      backdropColor={"rgba(0, 128, 0, 0.5)"}
      itemData={opendedPostDetailsDataRef.current}
      itemType={"post"}
      readOnly={true}
      isLoading={getPostDataFn.loading || !opendedPostDetailsDataRef.current}
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={refreshTable}
      onRefetchTableData={refreshDrawerTable}
      availableActionButtons={props.hasEditPermission ? ["enclosures", "copy_post", "edit_post", "delete"]:[]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UniversalToolBarWithDialogs
            pageName={props.pageName}
            showOpenCloseFilterButton={false}
            tableConfig={tableConfig}
            filteringData={props.filteringData}
            refreshTable={refreshTable}
            onCleanFlitersInFilterDialog={onCleanFiltersInFilterDialog}
            resetPageNumber={resetPageNumber}
            hiddenColumnsForTables={hiddenColumnsForTables}
            setHiddenColumnsForTables={setHiddenColumnsForTables}
            columnsOrdersForTables={columnsOrdersForTables}
            setColumnsOrdersForTables={setColumnsOrdersForTables}
            filterPrefix={POST_TABLE_FILTER_PREFIX}
            extraButtonList={
              props.hasEditPermission
                ? [
                    <Button
                      key="create-post-button"
                      color="success"
                      onClick={handleOpenPostDialogInCreateMode}
                      variant="contained"
                      size="small"
                      fullWidth
                      startIcon={
                        <ControlPointOutlinedIcon
                        sx={centerVericalAlignIconStyle}
                        />
                      }
                      sx={overflowButtonStyle}
                    >
                      {t("bar.tool_bar.create_post_in")}
                    </Button>,
                  ]
                : []
            }
          />
        </Grid>
        <Grid item xs={12}>
          {hiddenColumnsForTables && (
            <CorrespondenceTable
              showCleanFilterIcon={true}
              style={{ maxHeight: "60vh" }}
              showFilters={true}
              data={postInDataLocal}
              filteringData={props.filteringData}
              filterPrefix={POST_TABLE_FILTER_PREFIX}
              countRecords={countRecords}
              page={page}
              onPageChange={handleChangePageWithSearchParams}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onClickRow={handleOpenPostDialogInEditMode}
              selectedPostId={openedPostIdRef.current}
              resetPageNumber={resetPageNumber}
              tableConfig={POST_IN_TABLE_CONFIG}
              hiddenColumns={hiddenColumnsForTables[POST_TABLE_NAME]}
              columnsOrders={columnsOrdersForTables[POST_TABLE_NAME]}
              showExportToFileButton={true}
              exportToFileUrl={exportPostInListToFileUrl}
              exportToFileSearchParams={{
                ...postInSearchParams,
              }}
              exportToFileHeaders={postExportHeaders}
              exportToFileFileName={`${t("page.correspondence_page.post_in")}.xlsx`}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {openCreatePostDialog && (
            <CreatePostDialog
              open={openCreatePostDialog}
              onClose={onCloseCreatePostDialog}
              onSubmitCallback={getPostDialogCallback}
              dialogMode={postDialogModeRef.current}
              onRefetchData={refreshTable}
              postIn={true}
              autocompleteData={props.filteringData.value}
              backdropColor={"rgba(0, 128, 0, 0.5)"}
            />
          )}
        </Grid>
      </Grid>
    </DetailsDrawer>
  );
};

CorrespondencePostInTab.propTypes = {
  pageName: PropTypes.string,
  readOnly: PropTypes.bool,
};

CorrespondencePostInTab.defaultProps = {};

export default CorrespondencePostInTab;
