
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function CheckboxField(props) {

    const onChange = (e) => {
        e.target.name = props.name; 
        props.onChange(e) 
    }

    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    control={<Checkbox onChange={onChange} checked={props.checked ? props.checked : false} size={props.size} inputProps={{ 'aria-label': 'controlled' }} />}
                    label={props.label}
                    labelPlacement={props.labelPlacement}
                    disabled={props.disabled}
                />
            </FormGroup>
        </FormControl>
    )
}

CheckboxField.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    labelPlacement: PropTypes.oneOf(['top', 'start', 'bottom', 'end']),
    size: PropTypes.oneOf(['small', 'medium']),
    disabled: PropTypes.bool,
    name: PropTypes.string,
}


CheckboxField.defaultProps = {
    label: '',
    labelPlacement: 'start',
    size: 'medium',
    disabled: false,
    name: ''
}

