import BasicDialog from "../../base/BasicDialog";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import BaseBox from "../../base/BaseBox";
import useDocsData from "../../../hooks/useDocsData";
import FileUploadList from "../../other/FileUploadList";

function PostDocsByItemDialog(props) {
    const { t } = useTranslation();

    const {
        docs,
        isDocsLoading,
        updateDocs,
        saveDocs,
        hideDocs,
        onDownloadDoc,
        onPreviewDoc,
        refetchDocs,
    } = useDocsData(props.itemId, "post");

    return (
        <>
            <BasicDialog
                open={props.open}
                onClose={props.onClose}
                titleAlign="center"
                contentAlign="center"
                title={t("dialog.docs_details_dialog.docs_details")}
                maxWidth="sm"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={12}
                >
                    <Grid item xs={12}>
                        <BaseBox>
                            <FileUploadList
                                addEnclosureButtonProps={{ size: "mini" }}
                                enclosures={docs}
                                onAddEnclosure={saveDocs}
                                fileType={"docs"}
                                onPreviewEnclosure={onPreviewDoc}
                                onDownloadEnclosure={onDownloadDoc}
                                onDeleteEnclosure={hideDocs}
                                canRemoveEnclosures={true}
                                onUpdateEnclosure={updateDocs}
                                readOnly={isDocsLoading}
                                post={props.itemId}
                                refetchDocs={refetchDocs}
                                availableDocsRestrictedTypes={[]}/>
                        </BaseBox>
                    </Grid>
                </Grid>
            </BasicDialog>
        </>
    );
}

PostDocsByItemDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onRefetchData: PropTypes.func,
    itemId: PropTypes.string.isRequired, // itemId should be required as it's used in useDocsData
};

PostDocsByItemDialog.defaultProps = {
    open: false,
};

export default PostDocsByItemDialog;
