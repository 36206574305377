export const TicketAppApiUrl = window._env_.TICKET_APP_API_URL;

export const loginUrl = "/users/auth/login/";
export const logoutUrl = "/users/auth/logout/";
export const refreshTokenUrl = "/users/auth/refresh/";

export const aadLoginUrl = "/users/aad_auth/login/";
export const myOliviaLoginUrl = "/users/my_olivia_auth/login/";

export const aadRefreshTokenUrl = "/users/aad_auth/refresh/";

export const userBehalfCreateTicketSelectListUrl =
  "/users/behalf_create_ticket/";

export const userPreferecesUrl = (pageName) =>
  `/users/user_preferences/${pageName}/`;
export const userSelectListUrl = `/users/select_list/`;
export const userPermissionUrl = (componentName) =>
  `/users/user_permissions/${componentName}/`;

export const otherPermissionsSelectList = "permissions/others/select_list/";

export const usersPermissionUrl = "/users/user_permissions/";
export const checkUsersPermissionUrl = "/users/user_permissions/check/";
export const transactionsWarehousePermissionUrl =
  "/users/user_permissions/warehouses_transactions/";
export const otherWarehousePermissionUrl =
  "/users/user_permissions/warehouses_other/";
export const nonWarehousePermissionUrl = "/users/user_permissions/other/";

export const exportTicketsToFileUrl = "/tickets/export/xlsx/";
export const exportMyTicketsToFileUrl = "/tickets/my_tickets/export/xlsx/";
export const createTicketUrl = "/tickets/create/";
export const ticketDataUrl = "/tickets/";
export const ticketOfferDataUrl = "/tickets/offers";
export const myTicketOfferDataUrl = "/tickets/my_tickets/offers";
export const ticketWithEnclosureDataUrl = "/tickets/only_with_enclosures/";
export const ticketDetailsDataUrl = (ticketId) => `/tickets/${ticketId}/`;
export const ticketShortDetailsDataUrl = (ticketId) =>
  `/tickets/short_details/${ticketId}/`;
export const ticketOfferInitDataUrl = (ticketId) =>
  `/tickets/${ticketId}/new_offer_data/`;
export const ticketFilteringDataUrl = "/tickets/filtering_data/";
export const ticketFilteringMyTicketsDataUrl =
  "/tickets/filtering_data/my_tickets/";
export const ticketFilteringEnclosuresDataUrl =
  "/tickets/filtering_data/enclosures/";
export const ticketFilteringOffersDataUrl = "/tickets/filtering_data/offers/";
export const myTicketFilteringOffersDataUrl =
  "/tickets/filtering_data/my_offers/";
export const ticketAddFavorityUrl = "/tickets/ticket_favorite/add/";
export const ticketDeleteFavorityUrl = "/tickets/ticket_favorite/delete/";
export const ticketCategoryDataUrl = "/tickets/ticket_category/";
export const ticketCategoryAdminDataUrl = "/tickets/ticket_category/admin/";
export const ticketCategoryFilteringDataUrl =
  "/tickets/ticket_category/filtering_data/";
export const ticketCategoryDetailsDataUrl = (ticketCategoryId) =>
  `/tickets/ticket_category/${ticketCategoryId}/`;
export const ticketCategoryShortDataUrl =
  "/tickets/ticket_category/short_data/";
export const stdTicketShortDataUrl = "/tickets/std_ticket/short_data/";
export const offersByTicketDataUrl = (ticketId) =>
  `/tickets/${ticketId}/offers/`;

export const myOffersByTicketDataUrl = (ticketId) =>
  `/tickets/${ticketId}/my_offers`;

export const objectsSelectListUrl = "/locations/object/select_list/";

export const questionFormUrl = "/forms/form/question/";
export const questionAdminUrl = "/forms/admin/question/";
export const questionAdminListForFirstQuestionUrl = (questionId) =>
  `forms/admin/question/${questionId}/list_for_question/`;
export const questionAdminDetailsUrl = (questionId) =>
  `forms/admin/question/${questionId}/`;
export const questionAdminFilteringDataUrl =
  "/forms/admin/question/filtering_data/";
export const questionAnswerAdminUrl = "/forms/admin/question_answer/";
export const questionAnswerAdminDetailsUrl = (questionAnswerId) =>
  `forms/admin/question_answer/${questionAnswerId}/`;
export const questionAnswerAdminFilteringDataUrl =
  "/forms/admin/question_answer/filtering_data/";

export const filterDataUrl = "/filters/";

export const messageUpdateUrl = (messageId) =>
  `communication/messages/${messageId}/`;
export const messagesForTicketUrl = (ticketId) =>
  `communication/messages/ticket/${ticketId}/`;

export const messagesForTicketIcludeOrdersUrl = (ticketId) =>
  `communication/messages/ticket/${ticketId}/`;
export const messagesForOrderUrl = (orderId) =>
  `communication/messages/order/${orderId}/`;

export const saveFileUrl = "files/file_uploader/";
export const createEnclosureUrl = `files/enclosures/`;
export const createManyEnclosureUrl = `files/enclosures/mass_actions/`;
export const createEnclosureTOUrl = `files/enclosure_to/`;
export const eclosureDataUrl = (enclosureId) =>
  `files/enclosures/${enclosureId}/`;
export const enclosureDownloadUrl = (enclosureId) =>
  `files/enclosures/${enclosureId}/download/`;
export const enclosureImageMiniatureUrl = (enclosureId) =>
  `files/enclosures/${enclosureId}/miniature/`;

export const enclosuresForOrderUrl = (orderId) =>
  `files/enclosures/order/${orderId}/`;
export const enclosuresForOfferUrl = (offerId) =>
  `files/enclosures/offer/${offerId}/`;
export const enclosuresForPartUrl = (partId) =>
  `files/enclosures/part/${partId}/`;
export const createEnclosureForOrderUrl = (orderId) =>
  `files/enclosures/order/${orderId}/create/`;
export const enclosuresForTicketInlcudeOrdersUrl = (ticketId) =>
  `files/enclosures/ticket/${ticketId}/`;
export const enclosureTODataUrl = (enclosureTOId) =>
  `files/enclosure_to/${enclosureTOId}/`;
export const enclosureFavoriteUrl = "files/enclosures/enclosures_favorite/";
export const enclosureFavoriteForEnclosureUrl = (enclosureId) =>
  `files/enclosures/enclosures_favorite/${enclosureId}/`;
export const enclosureFilteringDataUrl = "files/enclosures/filtering_data/";
export const offerEnclosureUrl = (enclosureId) =>
  `files/enclosures/enclosure_offer/${enclosureId}/`;
export const partEnclosureUrl = (enclosureId) =>
  `files/enclosures/enclosure_part/${enclosureId}/`;
export const enclosureUploadSasTokenUrl = "files/file_uploader/upload_token/";
export const enclosuresKindPartUrl = "files/enclosures/kind_parts/";

export const orderDataUrl = "/orders/";
export const myOrderDataUrl = "/orders/my_orders/";
export const exportOrdersToFileUrl = "/orders/export/xlsx/";
export const createOrderUrl = "/orders/create/";
export const orderDataWithoutPaginationUrl = "/orders/without_pagination/";
export const orderPlannerDataUrl = "/orders/planner/";

export const orderDetailsDataUrl = (orderId) => `/orders/${orderId}/`;
export const orderFilteringDataUrl = "/orders/filtering_data/";
export const orderPrioritySelectListUrl = "/orders/order_priority/select_list/";
export const orderTypeSelectListUrl = "/orders/order_type/select_list/";
export const stdOrdersForTicketSelectListUrl = (ticketId) =>
  `orders/std_orders_for_ticket/${ticketId}/`;
export const orderSelectListUrl = "/orders/select_list/";

export const flagShortDetailsUrl = "/flags/short_details/";
export const ticketAddFlagsUrl = "tickets/flags/add/";
export const ticketDeleteFlagsUrl = "tickets/flags/delete/";
export const ticketPrioritySelectListUrl =
  "tickets/ticket_priority/select_list/";
export const ticketAreaSelectListUrl = "tickets/ticket_area/select_list/";

export const statusesForTicketsUrl = "statuses/statuses_for_tickets/";
export const statusesForOrdersUrl = "statuses/statuses_for_orders/";
export const statusesForTasksUrl = "statuses/statuses_for_tasks/";
export const ticketMassActionUrl = "tickets/mass_actions/";
export const ticketSelectListUrl = "tickets/select_list/";
export const ticketTicketUrl = "tickets/ticket_ticket/";
export const ticketTicketMergeUrl = "tickets/ticket_ticket/merge/";

export const ticketTicketTicketKindSelectListUrl =
  "tickets/ticket_ticket_kind/select_list/";

export const myTicketDataUrl = "tickets/my_tickets/";

export const offersDataUrl = "/offers/";
export const myOffersDataUrl = "/offers/my_offers/";
export const offersToJoinDataUrl = "/offers/to_join/";
export const offersToRRAcceptDataUrl = "/offers/to_rr_accept/";
export const offerDetailsDataUrl = (offerId) => `/offers/${offerId}/`;
export const newOfferVersionUrl = (offerId) =>
  `/offers/${offerId}/new_version/`;
export const copyOfferForUpdateUrl = (offerId) => `/offers/${offerId}/copy/`;
export const copyOfferForInnerUrl = (offerId) =>
  `/offers/${offerId}/internal_offer/`;
export const myOfferMessageDataUrl = (offerId) =>
  `/offers/my_offers/${offerId}/message/`;
export const myOfferDetailsDataUrl = (offerId) =>
  `/offers/my_offers/${offerId}/om_action/`;
export const offerSummaryCostsDataUrl = (offerId) =>
  `/offers/${offerId}/summary_costs/`;
export const offerGenerateUrl = (offerId) =>
  `/offers/${offerId}/generate_offer/`;
export const offerDrawerDetailsDataUrl = (offerId) =>
  `/offers/${offerId}/drawer_data/`;
export const myOfferDrawerDetailsDataUrl = (offerId) =>
  `/offers/my_offers/${offerId}/om_action/`;
export const offerServicesOfferDataUrl = (offerId) =>
  `/offers/${offerId}/services/`;
export const offerPartsOfferDataUrl = (offerId) => `/offers/${offerId}/parts/`;
export const offerSettlementDataUrl = (offerId) =>
  `/offers/${offerId}/settlements/`;
export const offerSettlementLogUrl = (offerId) =>
  `/offers/${offerId}/settlement_logs/`;
export const myOfferSettlementDataUrl = (offerId) =>
  `/offers/my_offers/${offerId}/settlements/`;
export const offerFilteringDataUrl = "/offers/filtering_data/";
export const myOfferFilteringDataUrl = "/offers/filtering_data/my_offers/";
export const offersAddFavorityUrl = "/offers/offer_favorite/add/";
export const offersDeleteFavorityUrl = "/offers/offer_favorite/delete/";
export const offerDocumentUrl = (offerId) => `/offers/${offerId}/document/`;

export const servicesDataUrl = "/offers/services/";
export const serviceSelectListUrl = "/offers/services/select_list/";
export const serviceFilteringUrl = "/offers/services/filtering_data/";
export const serviceExtendSelectListUrl =
  "/offers/services/select_list/extend/";

export const partsDataUrl = "/offers/parts/";
export const partDetailsDataUrl = (partId) => `/offers/parts/${partId}/`;
export const serviceDetailsDataUrl = (serviceId) =>
  `/offers/services/${serviceId}/`;
export const partItemBarnchUrl = (partId) =>
  `/offers/parts/${partId}/item_branch/`;
export const partSelectListUrl = "/offers/parts/select_list/";
export const partInfoSelectListUrl = "/offers/parts/select_list/";
export const partExtendSelectListUrl = "/offers/parts/select_list/extend/";
export const partFilteringDataUrl = "/offers/parts/filtering_data/";
export const certsByPartUrl = (partId) =>
  `/offers/parts/${partId}/certifications/`;
export const partParameterDataUrl = (partId) =>
  `/offers/parts/${partId}/parameters/`;

export const accountSelectListUrl = "/offers/account/select_list/";
export const partKindSelectListUrl = "/offers/part_kind/select_list/";
export const gtuSelectListUrl = "/offers/gtu/select_list/";
export const itemDataUrl = (itemId) => `/items/${itemId}/`;
export const itemUrl = "/items/";
export const itemSelectListUrl = "/items/select_list/";
export const itemStructureUrl = "/items/structure/";
export const itemLocationRecordUrl = (itemId) =>
  `/items/location_record/${itemId}/`;
export const parameterDataUrl = "/items/parameters/";
export const parametersSelectListUrl = "/items/parameters/select_list/";
export const parameterValueDataUrl = "/items/parameter_values/";
export const parameterValueDetailsUrl = (parameterId) =>
  `/items/parameter_values/${parameterId}/`;
export const hideParameterValueDetailsUrl = (parameterId) =>
  `/items/parameter_values/${parameterId}/hide/`;

export const clausesSelectListUrl = "/offers/clauses/";
export const offerClausesListUrl = (offerId) => `/offers/${offerId}/clauses/`;

export const orderAddFlagsUrl = "orders/flags/add/";
export const orderDeleteFlagsUrl = "orders/flags/delete/";

export const openedStatusesForOrdersUrl =
  "statuses/opened_statuses_for_orders/";
export const orderMassActionUrl = "orders/mass_actions/";
export const orderAddFavorityUrl = "/orders/order_favorite/add/";
export const orderDeleteFavorityUrl = "/orders/order_favorite/delete/";

export const employeeSkillUrl = "users/employee/skill/";
export const skillUrl = "users/skill/";
export const skillSelectListUrl = "users/skill/select_list/";
export const skillUserUrl = "users/skill/skill_user/";
export const skillUserFilteringDataUrl =
  "users/skill/skill_user/filtering_data/";
export const userHasSkillUrl = "users/user_has_skill/";

export const subcontractorUrl = "users/subcontractor/";
export const subcontractorSelectListUrl = "users/subcontractor/select_list/";
export const usersBySubcontractorUrl = (subId) =>
  `/users/subcontractor/${subId}/users/`;
export const subcontractorDetailsUrl = (subId) =>
  `/users/subcontractor/${subId}/`;
export const subcontractorFilteringDataUrl = `/users/subcontractor/filtering_data/`;
export const subcontractorAddressUrl = (subcontractorAddressId) =>
  `/users/subcontractor/address/remove/${subcontractorAddressId}/`;
export const subcontractorPhoneUrl = (subcontractorPhoneId) =>
  `/users/subcontractor/phone/remove/${subcontractorPhoneId}/`;
export const subcontractorMailUrl = (subcontractorMailId) =>
  `/users/subcontractor/mail/remove/${subcontractorMailId}/`;
export const userSubcontractorUrl = "/users/user_subcontractor/";
export const userSubcontractorDetailsUrl = (usersubId) =>
  `/users/user_subcontractor/${usersubId}/`;
export const userOwnerUrl = "/users/user_owner/";
export const userOwnerDetailsUrl = (userOwnId) =>
  `/users/user_owner/${userOwnId}/`;

export const taskUrl = "tasks/";
export const taskPlannerUrl = "tasks/planner/";
export const taskMassCreateOrUpdateUrl = "tasks/mass/create_or_update/";
export const taskWaitingFilteringDataUrl = "tasks/filtering_data/waiting_room/";
export const taskWaitingRoomUrl = "tasks/waiting_room/";

export const userDetailsUrl = (userId) => `/users/details/${userId}/`;
export const tenantDetailsUrl = (tenantId) =>
  `/users/tenant/details/${tenantId}/`;
export const tenantAddressUrl = (tenantId) =>
  `/users/tenant/details/${tenantId}/addresses/`;
export const ownersUrl = `/users/owner/`;
export const ownersFilteringDataUrl = `/users/owner/filtering_data/`;
export const ownerDetailsUrl = (ownerId) => `/users/owner/details/${ownerId}/`;
export const allPayersDetailsUrl = `/users/tenant/payers/`;
export const internalPayersDetailsUrl = `/users/tenant/payers/internal/`;
export const payersDetailsUrl = (offerId) => `/users/tenant/payers/${offerId}/`;
export const ownerAddressUrl = (ownerAddressId) =>
  `/users/owner/address/remove/${ownerAddressId}/`;
export const ownerPhoneUrl = (ownerPhoneId) =>
  `/users/owner/phone/remove/${ownerPhoneId}/`;
export const ownerMailUrl = (ownerMailId) =>
  `/users/owner/mail/remove/${ownerMailId}/`;
export const userDefaultLocationUrl = `/users/default_location/`;
export const userDefaultTicketOmLocationUrl = (ticketOmId) =>
  `/users/default_location/ticket_om/${ticketOmId}`;

export const createBoardUrl = `/users/board/`;
export const boardDetailsUrl = (boardId) => `/users/board/${boardId}/`;
export const boardFilteringDataUrl = `/users/board/filtering_data/`;

export const substatusesForTicketsUrl = "statuses/substatuses_for_tickets/";
export const substatusesForOrdersUrl = "statuses/substatuses_for_orders/";
export const substatusesForTasksUrl = "statuses/substatuses_for_tasks/";
export const ticketFilteringDataForDetails = "/tickets/filtering_data/details/";
export const ticketDataForCopyTicketUrl = (ticketId) =>
  `tickets/${ticketId}/data_for_copy/`;
export const ticketDataForClaimTicketUrl = (ticketId) =>
  `tickets/${ticketId}/data_for_claim/`;

export const guaranteeDataUrl = "/documents/guarantee/";
export const guaranteeDetailsDataUrl = (guaranteeId) =>
  `/documents/guarantee/${guaranteeId}/`;
export const guaranteeAgreementDetailsDataUrl = (guaranteeId) =>
  `/documents/guarantee/${guaranteeId}/agreement/`;
export const guaranteeFilteringDataUrl = "/documents/guarantee/filtering_data/";
export const guaranteeScopeDataUrl = "/documents/guarantee_scope/";
export const guaranteeScopeDetailsDataUrl = (guaranteeScopeId) =>
  `/documents/guarantee_scope/${guaranteeScopeId}/`;
export const guaranteeScopeFilteringDataUrl =
  "/documents/guarantee_scope/filtering_data/";
export const guaranteeAgreementsDataPaginationUrl =
  "/documents/guarantee/agreements/";
export const guaranteeAgreementsDataUrl =
  "/documents/guarantee/agreements/without_pagination/";
export const guaranteeScopeManualReminderUrl = (guaranteeScopeId) =>
  `/documents/guarantee_scope/${guaranteeScopeId}/send_reminder/`;
export const guaranteeAgreementFilteringDataUrl =
  "/documents/guarantee/agreement/filtering_data/";
export const exportGuaranteeAgreementsToFileUrl =
  "documents/guarantee/agreements/export/xlsx/";
export const guaranteeNextNumberUrl =
  "documents/guarantee/next_location_number/";
export const exportMyOrdersToFileUrl = "/orders/my_orders/export/xlsx/";
export const initialOrderDataForCreateTaskUrl = (orderId) =>
  `/orders/${orderId}/initial_data_for_create_task/`;

export const taskFilteringDataForCreateUrl =
  "tasks/filtering_data/create_task/";
export const myTaskDataUrl = "tasks/my_tasks/";
export const taskFilteringDataUrl = "tasks/filtering_data/";
export const taskDetailsDataUrl = (taskId) => `tasks/${taskId}/details/`;
export const taskListUrl = "tasks/list/";
export const exportTaskListToFileUrl = "/tasks/list/export/xlsx/";
export const exportMyTaskToFileUrl = "/tasks/my_tasks/export/xlsx/";

export const rcpPlanUrl = "rcp/rcp_plan/";
export const rcpPlanWithoutPaginationUrl = "rcp/rcp_plan/without_pagination/";
export const rcpPlanDetailsUrl = (rcpPlanId) => `rcp/rcp_plan/${rcpPlanId}/`;
export const rcpPlanFilteringDataUrl = "rcp/rcp_plan/filtering_data/";
export const rcpActivityChoicesUrl = "rcp/activity_choices/";
export const rcpPlanMassUpdateOrCreateUrl =
  "rcp/rcp_plan/mass/create_or_update/";

export const rcpDoneUrl = "rcp/rcp_done/";
export const rcpDoneDetailsUrl = (rcpDoneId) => `rcp/rcp_done/${rcpDoneId}/`;
export const rcpDoneMassUpdateOrCreateUrl =
  "rcp/rcp_done/mass/create_or_update/";

export const rcpTimeTrackingUrl = "rcp/rcp_tracking/";
export const rcpScheduleUrl = "rcp/rcp_schedule/";
export const exportRcpTimeTrackingToFileUrl = "rcp/rcp_tracking/export/xlsx/";
export const exportRcpTimeTrackingSummaryToFileUrl =
  "rcp/rcp_tracking/summary/export/xlsx/";

export const feedbackUrl = "feedback/";
export const feedbackDetailsDataUrl = (feedbackId) => `feedback/${feedbackId}/`;
export const exportFeedbackToFileUrl = "feedback/export/xlsx/";
export const feedbackFilteringDataUrl = "feedback/filtering_data/";

export const createAddressUrl = "addresses/";
export const addressFilteringDataUrl = "addresses/filtering_data/";
export const addressDetailsUrl = (addressId) => `addresses/${addressId}/`;

export const createPhoneUrl = "phones/";
export const phoneDetailsUrl = (phoneId) => `phones/${phoneId}/`;

export const createMailUrl = "mails/";
export const mailDetailsUrl = (mailId) => `mails/${mailId}/`;

export const ownerLogsUrl = (ownerId) => `logs/owner_logs/${ownerId}/`;
export const offerLogsUrl = (offerId) => `logs/offer_logs/${offerId}/`;
export const grtReportUrl = "offers/grt_report/";
export const offerOWUUrl = "offers/owu/";

export const ticketSlaDataUrl = "tickets/sla/";
export const ticketSlaShortDataUrl = "tickets/sla/short_data/";
export const exportTicketsSlaToFileUrl = "tickets/sla/export/xlsx/";
export const ticketSlaDataWithDelayTimeDistributionUrl =
  "tickets/sla/delay_time_distribution/";
export const ticketSlaDataWithClaimDistributionUrl =
  "tickets/sla/claim_distribution/";

export const ticketSlaFilteringDataUrl = "tickets/filtering_data/sla/";
export const feedbackSlaDataUrl = "feedback/sla/";
export const exportFeedbackSlaToFileUrl = "feedback/sla/export/xlsx/";

export const tenantUrl = "users/tenant/";
export const tenantSelectListUrl = "users/tenant/select_list/";
export const tenantRRListUrl = "users/tenant/rr/";
export const tenantRRDetailsUrl = (tenantId) => `users/tenant/rr/${tenantId}/`;
export const tenantRRDetailsListUrl = "users/tenant/rr/details/";
export const tenantFilteringDataUrl = "users/tenant/filtering_data/";
export const exportTenantRRDetailsToFileUrl =
  "users/tenant/rr/details/export/xlsx/";

export const agreementsDataUrl = "documents/agreement/";
export const agreementsDetailsUrl = (agreementId) =>
  `documents/agreement/${agreementId}/`;
export const relatedAgreementsUrl = (agreementId) =>
  `documents/agreement/${agreementId}/related_agreements/`;
export const agreementsFilteringDataUrl = "documents/agreement/filtering_data/";
export const agreementsSelectListUrl = "documents/agreement/select_list/";
export const agreementsNextNumberUrl =
  "documents/agreement/next_location_number/";
export const exportAgreementsToFileUrl = "documents/agreement/export/xlsx/";
export const agreementSubkindsListUrl =
  "documents/agreement_subkind/select_list/";
export const docsByAgreementUrl = (agreementId) =>
  `documents/agreement/${agreementId}/docs`;
export const docsByPostUrl = (postId) => `documents/docs_post/${postId}/docs`;
export const createManyDocsUrl = "documents/docs/mass_actions/";
export const docsDataUrl = (docsId) => `documents/docs/${docsId}/`;
export const docsDownloadUrl = (docsId) => `documents/docs/${docsId}/download`;
export const docsFilteringDataUrl = "documents/docs/filtering_data/";
export const certificateUrl = "documents/certificate/";
export const certificatesSelectListUrl = "documents/certificate/select_list/";
export const createManyCertsUrl = "documents/certification/mass_actions/";
export const certsDataUrl = (certId) => `documents/certification/${certId}/`;
export const certificationDownloadUrl = (docsId) =>
  `documents/certification/${docsId}/download`;

export const warehousesDataUrl = "warehouses/";
export const warehouseDetailsUrl = (warehouseId) =>
  `warehouses/${warehouseId}/`;
export const warehousesFilteringDataUrl = "warehouses/filtering_data/";

export const warehouseLocationUrl = "warehouses/war_location/";
export const warehouseLocationDetailsUrl = (warLocId) =>
  `warehouses/war_location/${warLocId}/`;

export const transactionDataUrl = "warehouses/transaction/";
export const transactionInDataUrl = "warehouses/transaction/in/";
export const transactionOutDataUrl = "warehouses/transaction/out/";
export const transactionMoveDataUrl = "warehouses/transaction/move/";
export const transactionRemoveDataUrl = "warehouses/transaction/remove/";
export const transactionFilteringDataUrl =
  "warehouses/transaction/filtering_data/";
export const transactionSelectingDataUrl =
  "warehouses/transaction/select_data/";

export const reservationDataUrl = "warehouses/reservation/";
export const reservationUrl = (reservationId) =>
  `warehouses/reservation/${reservationId}/`;
export const transactionByPartDataUrl = (partId) =>
  `warehouses/transaction/${partId}/`;
export const reservationByPartDataUrl = (partId) =>
  `warehouses/reservation/${partId}/`;
export const stockWarehouseDataUrl = "warehouses/stock/";
export const stockAmountUrl = "warehouses/stock/amount/";
export const stockAvailabilityUrl = "warehouses/stock/availability/";
export const stockWarehouseFilteringDataUrl =
  "warehouses/stock/filtering_data/";
export const poDetailsDataUrl = (poId) => `warehouses/po/${poId}/`;
export const poLineDetailsUrl = (poLineId) =>
  `warehouses/po_lines/${poLineId}/`;
export const poRemoveDocUrl = (poId) => `warehouses/po/${poId}/remove_doc/`;
export const poLinesDataByPoUrl = (poId) => `warehouses/po/${poId}/po_lines/`;
export const poUrl = `warehouses/po/`;
export const poFlatListUrl = `warehouses/po/flat_list/`;
export const poLineUrl = `warehouses/po_lines/`;
export const poLineChangeStatusUrl = `warehouses/po_lines/change_status_list/`;
export const poFilteringDataUrl = `warehouses/po/filtering_data/`;
export const poLinesFilteringDataUrl = `warehouses/po_lines/filtering_data/`;
export const poSelectDataUrl = `warehouses/po/select_list/`;

export const buffersDataUrl = "warehouses/buffer/";
export const bufferFilteringDataUrl = "warehouses/buffer/filtering_data/";
export const bufferDetailsUrl = (bufferId) => `warehouses/buffer/${bufferId}/`;

export const fvUrl = "warehouses/fv/";
export const fvFlatListUrl = "warehouses/fv/flat_list/";
export const fvFilteringDataUrl = "warehouses/fv/filtering_data/";

export const userEmailNotificationsUrl = "users/email_notifications/";
export const userEmailNotificationsFilteringDataUrl =
  "users/email_notifications/filtering_data/";
export const exportUserEmailNotificationsToFileUrl =
  "users/email_notifications/export/xlsx/";

export const userTenantListUrl = "users/user_tenant/";
export const userTenantFilteringDataUrl = "users/user_tenant/filtering_data/";
export const userTenantInitialDataUrl = "users/user_tenant/initial_data/";
export const userTenantDetailsUrl = (userTenantId) =>
  `users/user_tenant/${userTenantId}/`;
export const exportUserTenantListToFileUrl = "users/user_tenant/export/xlsx/";

export const tenantShortDetailsDataUrl = "users/tenant/short_details/";
export const exportTenantShortDetailsToFileUrl =
  "users/tenant/short_details/export/xlsx/";

export const objectTenantExtendedLocationUrl = (lastLevel) =>
  `locations/object_tenant/extend_location/${lastLevel}/`;
export const objectTenantFilteringDataUrl =
  "locations/object_tenant/filtering_data/";

export const exportObjectTenantExtendedLocationToFilteUrl = (lastLevel) =>
  `locations/object_tenant/extend_location/export/xlsx/${lastLevel}/`;
export const objectLevelsFilteringDataUrl =
  "locations/object/levels/filtering_data/";
export const postFilteringDataUrl = "correspondence/posts/filtering_data/";
export const postInListUrl = "correspondence/posts/in/";
export const postCreate = "correspondence/post/";
export const postDefaultStatus = "correspondence/post/default_status/";
export const postDataUrl = (postId) => `correspondence/post/${postId}/`;
export const postDetailsDataUrl = (postId) => `correspondence/${postId}/`;
export const postOutListUrl = "correspondence/posts/out/";
export const postParentUrl = "correspondence/posts/oldest_by_decree/";
export const exportPostInListToFileUrl = "correspondence/posts/in/export/xlsx/";
export const exportPostOutListToFileUrl =
  "correspondence/posts/out/export/xlsx/";
export const postSubcategoryListUrl =
  "correspondence/post_subcategory/select_list/";
export const sideAdministrationSelectListUrl =
  "correspondence/side_administration/select_list/";
export const userDepartmentSelectListUrl =
  "correspondence/user_department/select_list/";
export const departmentSelectListUrl = "correspondence/department/select_list/";
export const departmentsListUrl = "correspondence/departments/";
export const departmentDataUrl = (departmentId) =>
  `correspondence/department/${departmentId}/`;
export const postStatusListUrl = "correspondence/post_status/";
export const postStatusDataUrl = (postStatusId) =>
  `correspondence/post_status/${postStatusId}/`;
export const postDeliveryListUrl = "correspondence/post_delivery/";
export const postDeliveryDataUrl = (postDeliveryId) =>
  `correspondence/post_delivery/${postDeliveryId}/`;
export const postCategoryListUrl = "correspondence/post_category/";
export const postCategoryDataUrl = (postCategoryId) =>
  `correspondence/post_category/${postCategoryId}/`;
export const postSideTypeListUrl = "correspondence/post_side_type/";
export const postSideTypeDataUrl = (postSideTypeId) =>
  `correspondence/post_side_type/${postSideTypeId}/`;
export const postArchivesListUrl = "correspondence/post_archives/";
export const postArchivesDataUrl = (postArchivesId) => `correspondence/post_archives/${postArchivesId}/`;
export const postSubcategoryDataUrl = `correspondence/post_subcategory/`;
export const postSubcategoryDataDetailsUrl = (postSubcategoryId) => `correspondence/post_subcategory/details/${postSubcategoryId}/`;
export const postSubcategoryAllListUrl = "correspondence/post_subcategory/list/";
export const sideAdministrationDataUrl = (id) => `correspondence/side_administration/${id}/`;
export const sideAdministrationListUrl = "correspondence/side_administration/list/";
export const sideAdministrationCreateUrl = "correspondence/side_administration/";
export const ownersSelectListUrl = `/users/owner/select_list/`;
export const userOwnersSelectListUrl = `/users/user_owner/select_list/`;
export const roleDescriptionDataUrl = "users/role/description_data/";
export const objectUrl = "locations/object/";
export const objectDetailsUrl = (locationId) =>
  `locations/object/${locationId}/`;
export const objectAdminDataUrl = "locations/admin/";
export const objectAdminDetailsDataUrl = (locationId) =>
  `locations/admin/${locationId}/`;
export const objectAdminFilteringDataUrl = `locations/admin/filtering_data/`;
export const exportObjectAdminDataToFileUrl = "locations/admin/export/xlsx/";

export const objectCategoryUrl = "locations/object_category/";
export const objectCategoryDetailsUrl = (objectCategoryId) =>
  `locations/object_category/${objectCategoryId}/`;
export const objectCategoryShortDataUrl =
  "locations/object_category/short_data/";
export const objectCategorySelectListUrl =
  "locations/object_category/select_list/";
export const objectCategoryFilteringDataUrl =
  "locations/object_category/filtering_data/";
export const objectSubcategoryListUrl =
  "locations/object_category/subcategory_list/";
export const objectSubcategoryFilteringDataUrl =
  "locations/object_category/subcategory/filtering_data/";

export const stdTicketAdminDataUrl = "tickets/std_ticket/admin/";
export const stdTicketAdminDetailsDataUrl = (stdTicketId) =>
  `tickets/std_ticket/admin/${stdTicketId}/`;
export const stdTicketAdminFilteringDataUrl =
  "tickets/std_ticket/admin/filtering_data/";
export const exportStdTicketAdminDataToFileUrl =
  "tickets/std_ticket/admin/export/xlsx/";

export const stdOrderAdminDataUrl = "orders/std_order/admin/";
export const stdOrderForStdTicketAdminDataUrl = (stdTicket) =>
  `orders/std_order/admin/std_ticket/${stdTicket}/`;
export const stdOrderAdminDetailsDataUrl = (stdOrderId) =>
  `orders/std_order/admin/${stdOrderId}/`;
export const stdOrderAdminFilteringDataUrl =
  "orders/std_order/admin/filtering_data/";
export const exportStdOrderAdminDataToFileUrl =
  "orders/std_order/admin/export/xlsx/";

export const stdTicketAutomatAdminDataUrl = "tickets/std_ticket_automat/admin/";
export const stdTicketAutomatAdminDetailsDataUrl = (ticketCategoryId) =>
  `tickets/std_ticket_automat/admin/${ticketCategoryId}/`;
export const stdTicketAutomatAdminFilteringDataUrl =
  "tickets/std_ticket_automat/admin/filtering_data/";
export const exportStdTicketAutomatAdminDataToFileUrl =
  "tickets/std_ticket_automat/admin/export/xlsx/";

export const stdOrderAutomatAdminDataUrl = "orders/std_order_automat/admin/";
export const stdOrderAutomatAdminDetailsDataUrl = (stdOrderId) =>
  `orders/std_order_automat/admin/${stdOrderId}/`;
export const stdOrderAutomatAdminFilteringDataUrl =
  "orders/std_order_automat/admin/filtering_data/";
export const exportStdOrderAutomatAdminDataToFileUrl =
  "orders/std_order_automat/admin/export/xlsx/";

export const overridingDataUrl = "tickets/overriding/";
export const overridingDetailsDataUrl = (overridingId) =>
  `tickets/overriding/${overridingId}/`;
export const overridingFilteringDataUrl = "tickets/overriding/filtering_data/";
export const exportOverridingDataToFileUrl = "tickets/overriding/export/xlsx/";

export const ticketsForOverridingDataUrl = (overridingId) =>
  `tickets/tickets_for_overriding/${overridingId}/`;

export const stdTicketConfigForHandOver =
  "tickets/overriding/hand_over/config/";

export const ticketChartFilteringDataUrl = "/tickets/charts/filtering_data/";
export const ticketFeedbackDistributionDataUrl =
  "/tickets/feedback/distribution/";

export const orderSlaDataUrl = "orders/sla/";
export const orderSlaFilteringDataUrlUrl = "orders/sla/filtering_data/";
export const exportOrdersSlaToFileUrl = "orders/sla/export/xlsx/";
export const orderChartFilteringDataUrl = "/orders/charts/filtering_data/";

export const offerDistributionDataUrl = "offers/offer_distribution/";
export const exportOfferToFileUrl = "offers/export/xlsx/";
export const offerChartFilteringDataUrl = "/offers/charts/filtering_data/";

export const ticketProtocolGenerateUrl = (ticketId) =>
  `tickets/${ticketId}/generate_protocol/`;

export const docsRestrictedUrl = "documents/docs_restricted/";
export const docsRestrictedDetailsDataUrl = (docsId) =>
  `documents/docs_restricted/${docsId}/`;
export const docsRestrictedFilteringDataUrl =
  "documents/docs_restricted/filtering_data/";
