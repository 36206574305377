import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import TextFieldFormControl from "../../field/TextFieldFormControl/TextFieldFormControl";
import SelectField from "../../field/SelectField";
import DateField from "../../field/DateField/DateField";
import AutocompleteField from "../../field/AutocompleteField";
import TrueFalseSelectField from "../../field/TrueFalseSelectField";
import MaskedTextFieldForm from "../../base/MaskedTextFieldForm";

const AgreementDetailsForm = (props) => {
  const { t } = useTranslation();



  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {props.agreementId &&
        <Grid item xs={6}>
          <TextFieldFormControl
            name="agreement_nr"
            readOnly={true}
            value={props.agreementData?.agreement_nr}
            label={t("form.agreements_details_form.agreement_nr")}
            onChange={props.onChange}
          />
        </Grid>}
      <Grid item xs={6}>
        <TextFieldFormControl
          name="agreement_title"
          readOnly={props.readOnly}
          required
          value={props.agreementData?.agreement_title}
          label={t("form.agreements_details_form.agreement_title")}
          onChange={props.onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldFormControl
          name="agreement_bluedew_nr"
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_bluedew_nr}
          label={t("form.agreements_details_form.agreement_bluedew_nr")}
          onChange={props.onChange}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name={"owner"}
          label={t("form.agreements_details_form.agreement_owner")}
          value={props.agreementData?.owner}
          options={props.filteringData?.value?.owner}
          isObjectOption={true}
          optionLabelKey={"owner_short_name"}
          onChange={props.onChangeOwner}
          addNewValue={false}
          multiple={true}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name={"tenant"}
          label={t("form.agreements_details_form.agreement_tenant")}
          value={props.agreementData?.tenant}
          options={props.filteringData?.value?.tenant}
          isObjectOption={true}
          optionLabelKey={"tenant_short_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          addNewValue={false}
          multiple={true}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteField
          name={"agreement_kind"}
          label={t("form.agreements_details_form.agreement_kind")}
          value={props.agreementData?.agreement_kind}
          options={props.filteringData?.value?.agreement_kind}
          isObjectOption={true}
          optionLabelKey={"agki_category"}
          onChange={props.onChangeAgreementKind}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteField
          name={"agreement_subkind"}
          label={t("form.agreements_details_form.agreement_subkind")}
          value={props.agreementData?.agreement_subkind}
          options={props.agreementSubkindSelectList}
          isObjectOption={true}
          optionLabelKey={"agsu_subcategory"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          addNewValue={false}
          multiple={false}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <SelectField
          label={t("form.agreements_details_form.agreement_sign_form")}
          name="agreement_sign_form"
          value={props.agreementData?.agreement_sign_form}
          options={props.filteringData?.value?.agreement_sign_form}
          isObjectOption={false}
          onChange={props.onChange}
          readOnly={props.readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <MaskedTextFieldForm
          mask={"NAJEM/****/99"}
          onChange={props.onChange}
          disabled={props.readOnly}
          value={props.agreementData?.agreement_location}
          maskChar={"_"}
          name="agreement_location"
          label={t("form.agreements_details_form.agreement_location")}
          showHelperText={!props.isLocationFormatValid}
          FormHelperTextProps={{ sx: { color: 'red' } }}
          helperText={t("form.agreements_details_form.uncorrect_location_format")}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <AutocompleteField
          label={t("form.agreements_details_form.agreement_keeper")}
          name="keeper"
          value={props.agreementData?.keeper}
          options={props.filteringData?.value?.keeper}
          isObjectOption={true}
          optionLabelKey={"full_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly}
          required
        />
      </Grid> */}
      <Grid item xs={6}>
        <AutocompleteField
          label={t("form.agreements_details_form.agreement_parent")}
          name="parent"
          value={props.agreementData?.parent}
          options={props.agreementParentsList}
          isObjectOption={true}
          optionLabelKey={"agreement_descriptive_name"}
          onChange={props.onChangeAutocompleteFieldWithObjectOptions}
          readOnly={props.readOnly}
        />
      </Grid>
      {props.agreementId &&
      <Grid item xs={6}>
        <TrueFalseSelectField
          name="agreement_active"
          readOnly={props.readOnly}
          valuesAsBool
          value={props.agreementData?.agreement_active}
          label={t("form.agreements_details_form.agreement_active")}
          onChange={props.onChange}
          addEmptyOptions={false}
        />
      </Grid>}
      <Grid item xs={6}>
        <DateField
          name="agreement_date_sign"
          label={t("form.agreements_details_form.agreement_date_sign")}
          value={props.agreementData?.agreement_date_sign}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          required
        />
      </Grid>
      {/* <Grid item xs={6}>
        <DateField
          name="agreement_date_start"
          label={t("form.agreements_details_form.agreement_date_start")}
          value={props.agreementData?.agreement_date_start}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          name="agreement_date_stop"
          label={t("form.agreements_details_form.agreement_date_stop")}
          value={props.agreementData?.agreement_date_stop}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
        />
      </Grid> */}
      {/* <Grid item xs={12}>
        <TextFieldFormControl
          name="agreement_validity"
          label={t("form.agreements_details_form.agreement_xxx")}
          value={props.agreementData?.agreement_validity}
          readOnly={props.readOnly}
          onChange={props.onChangeDate}
          multiline={true}
          rows={2}
          resize={"vertical"}
        />
      </Grid> */}

      {/* <Grid item xs={12}>
        <TextFieldFormControl
          name="agreement_scope"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_scope}
          label={t("form.agreements_details_form.agreement_scope")}
          onChange={props.onChange}
          resize={"vertical"}
        />
      </Grid> */}
      {/* <Grid item xs={12}>
        <TextFieldFormControl
          name="agreement_note_salesforce"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_note_salesforce}
          label={t("form.agreements_details_form.agreement_note_salesforce")}
          onChange={props.onChange}
          resize={"vertical"}
        />
      </Grid> */}

      {/* <Grid item xs={12}>
        <TextFieldFormControl
          name="agreement_note_fm"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_note_fm}
          label={t("form.agreements_details_form.agreement_note_fm")}
          onChange={props.onChange}
          resize={"vertical"}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormControl
          name="agreement_note_legal"
          multiline
          rows={4}
          readOnly={props.readOnly}
          value={props.agreementData?.agreement_note_legal}
          label={t("form.agreements_details_form.agreement_note_legal")}
          onChange={props.onChange}
          resize={"vertical"}
        />
      </Grid> */}
    </Grid>
  );
};

AgreementDetailsForm.propTypes = {
  agreementData: PropTypes.object,
  readOnly: PropTypes.bool,
  filteringData: PropTypes.object,
  agreementSubkindSelectList: PropTypes.array,
  agreementParentsList: PropTypes.array,
  onChangeOwner : PropTypes.func
};

AgreementDetailsForm.defaultProps = {
  readOnly: false,
  filteringData: { value: [] },
  valueagreementSubkindSelectList: [],
  agreementParentsList : []
};

export default AgreementDetailsForm;
