import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import BasicDialog from "../../base/BasicDialog";
import LoaderWrapper from "../../wrapper/LoaderWrapper";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import { useForm } from "../../../hooks/useForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import AgreementDetailsForm from "../../form/AgreementDetailsForm/AgreementDetailsForm";
import useFieldValidation from "../../../hooks/useFieldValidation";
import useAgreementService from "../../../services/agreementService";
import { isEmptyArray } from "../../../helpers/methods";


const AgreementDetailsDialog = (props) => {
  const { t } = useTranslation();
  const snackbarAlert = useSnackbarAlert();
  const { getUnfillRequiredFields } = useFieldValidation();
  const [isLocationFormatValid, setIsLocationFormatValid] = useState(true);

  const [agreementSubkindSelectList, setAgreementSubkindSelectList] = useState(props.filteringData.value.agreement_subkind)
 
  const locationFormatRegex = /^NAJEM\/(?:[^_]*_){0,3}[^_]{0,4}\/\d{2}$/;
  
  const {
    formValue,
    setFormValue,
    onChange,
    onChangeDate,
    onChangeAutocompleteFieldWithObjectOptions
  } = useForm({'agreement_location' : "NAJEM/___/__"});


  useEffect(() => {
    if (formValue.agreement_location) {
      setIsLocationFormatValid(validateLocationFormat(formValue.agreement_location));
    }
  }, [formValue]);
  
  const {
    getAgreementDetails,
    getAgreementsSelectList,
    getNextAgreementNumber,
    updateAgreementDetails,
    createAgreement,
    getAgreementSubkindsSelectList} = useAgreementService();


  const updateAgreementDetailsFn = useAsyncFn(updateAgreementDetails)
  const createAgreementFn = useAsyncFn(createAgreement)
  const getAgreementSubkindsSelectListFn = useAsyncFn(getAgreementSubkindsSelectList)


  const requiredFields = useMemo(() => {
    return [
      "agreement_title",
      "owner",
      "tenant",
      "agreement_kind",
      "agreement_subkind",
      "agreement_date_sign"
    ];
  }, []);

  const agreementDetailsData = useAsync(
    () => {
      if (props.agreementId) {
      return getAgreementDetails(props.agreementId)
      }else{
        return getNextAgreementNumber()
    }},
    [props.agreementId]);

    const agreementSelectList = useAsync(() => {
      if(formValue.owner !==undefined && !isEmptyArray(formValue.owner)){
        let searchDict = {"owner" : formValue.owner}
        if (props.agreementId){
          searchDict["exclude"] = props.agreementId
        }
        return getAgreementsSelectList(searchDict)
      }
     return getAgreementsSelectList(props.agreementId? {"exclude": props.agreementId} : {})
    },
     [formValue?.owner]);

  useEffect(() => {
    if (agreementDetailsData.loading) {
      return;
    }
    if (agreementDetailsData.value) {
      setFormValue(agreementDetailsData.value);
      setAgreementSubkindSelectList(agreementDetailsData.value.agreement_subkind_list)
      setIsLocationFormatValid(validateLocationFormat(agreementDetailsData.value.agreement_location));
    }
  }, [agreementDetailsData.loading]);

  const onChangeAgreementKind = (e, value_object, value_key, state_value_name)=>{
    setFormValue((formValue) => ({ ...formValue, ['agreement_subkind']: undefined }));
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
    if (value_object.id) {
      getAgreementSubkindsSelectListFn.execute({'agreement_kind' : value_object.id})    
          .then((res) => {
            setAgreementSubkindSelectList(res)
          })
    }else{
      setAgreementSubkindSelectList([])
    }
  }

  const onChangeOwner = (e, value_object, value_key, state_value_name)=>{
    setFormValue((formValue) => ({ ...formValue, ['parent']: undefined }));
    onChangeAutocompleteFieldWithObjectOptions(e, value_object, value_key, state_value_name)
  }

  const isLoadingButtonDisabled = useMemo(() => {
    return getUnfillRequiredFields(requiredFields, formValue).length > 0 || !isLocationFormatValid;
  }, [requiredFields, formValue, getUnfillRequiredFields, isLocationFormatValid]);


  const onSubmit = useCallback(() => {
    if (props.agreementId) {
      updateAgreementDetailsFn
        .execute(props.agreementId, formValue)
        .then((res) => {
          props.filteringData.refetch()
          props.onRefetchData()
          props.onRefetchDetails()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.agreement_updated")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_agreement_updating")
          );
        });
    } else {
      createAgreementFn
        .execute(formValue)
        .then((res) => {
          props.filteringData.refetch()
          props.onRefetchData()
          snackbarAlert.openSuccessSnackbarAlert(
            t("snackbar_alert.agreement_created")
          );
          props.onClose();
        })
        .catch((error) => {
          snackbarAlert.openErrorSnackbarAlert(
            t("snackbar_alert.occurred_error_during_agreement_creating")
          );
        });
    }

  }, [formValue]);

  const validateLocationFormat = (location) => {
    return locationFormatRegex.test(location);
  };


  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      contentAlign="center"
      title={props.agreementId ? t(`dialog.agreements_details_dialog.agreement_details`) : t(`dialog.agreements_details_dialog.create_agreement`)}
      maxWidth="md"
      showDialogActions
    >

      <LoaderWrapper showLoader={agreementDetailsData.loading} >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12}>
            {agreementDetailsData.value &&
            <AgreementDetailsForm
              readOnly={props.readOnly}
              agreementData={formValue}
              agreementId = {props.agreementId}
              onChange={onChange}
              onChangeDate={onChangeDate}
              onChangeOwner={onChangeOwner}
              onChangeAutocompleteFieldWithObjectOptions={onChangeAutocompleteFieldWithObjectOptions}
              filteringData={props.filteringData}
              agreementSubkindSelectList={agreementSubkindSelectList}
              agreementParentsList={agreementSelectList?.value ? agreementSelectList?.value : []}
              onChangeAgreementKind={onChangeAgreementKind}
              isLocationFormatValid= {isLocationFormatValid || agreementDetailsData.loading}
            />}
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              fullWidth
              loading={updateAgreementDetailsFn.loading || createAgreementFn.loading}
              disabled={isLoadingButtonDisabled}
              onClick={onSubmit}
            >
              {props.agreementId ? t("save") : t("create")}
            </LoadingButton>
          </Grid>

        </Grid>

      </LoaderWrapper>

    </BasicDialog>
  );
};

AgreementDetailsDialog.propTypes = {
  agreementId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool
};

AgreementDetailsDialog.defaultProps = {
  open: false,
};

export default AgreementDetailsDialog;
