import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import BasicDialog from '../../base/BasicDialog';
import axios from '../../../api/axios';
import { useSnackbarAlert } from '../../../context/snackbarAlert';
import { useForm } from '../../../hooks/useForm';
import { useAsync, useAsyncFn } from '../../../hooks/useAsync';
import { isEmptyValue } from '../../../helpers/methods';
import TextFieldFormControl from '../../field/TextFieldFormControl';
import CheckboxField from '../../base/CheckboxField/checkboxField';
import useCorrespondenceAdminService from '../../../services/correspondenceAdminService';

const CreatePostCategoryDialog = (props) => {
  const { t } = useTranslation();
  const { formValue, setFormValue, onChange } = useForm({post_cat_active:true});


  const { createPostCategory, updatePostCategoryData, getPostCategoryDetails } = useCorrespondenceAdminService();
  const createPostCategoryFn = useAsyncFn(createPostCategory);
  const updatePostCategoryFn = useAsyncFn(updatePostCategoryData);
  const snackbarAlert = useSnackbarAlert();

  const postCategoryDetailsData = useAsync(() => {
    if (props.categoryId) {
      return getPostCategoryDetails(props.categoryId);
    }
    return Promise.resolve({});
  }, [props.categoryId]);

  useEffect(() => {
    if (!postCategoryDetailsData.loading && postCategoryDetailsData.value && props.categoryId) {
      setFormValue(postCategoryDetailsData.value);
    }
  }, [props.categoryId, postCategoryDetailsData.loading]);

  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const onSubmit = (postCategoryData) => {
    if (postCategoryData.id) {
      updatePostCategoryFn.execute(postCategoryData.id, postCategoryData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_category_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_updating_post_category"));
        });
    } else {
      createPostCategoryFn.execute(postCategoryData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_category_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_post_category"));
        });
    }
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formValue);
    }
  };

  const isFormValid = () => {
    return !isEmptyValue(formValue.post_cat_name);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      title={props.categoryId ? t('dialog.correspondence_admin_panel_dialogs.edit_category') : t('dialog.correspondence_admin_panel_dialogs.add_category')}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldFormControl
            name="post_cat_name"
            label={t('dialog.correspondence_admin_panel_dialogs.category_name')}
            value={formValue["post_cat_name"]}
            onChange={onChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxField
            label={t('dialog.correspondence_admin_panel_dialogs.active')}
            name="post_cat_active"
            checked={formValue["post_cat_active"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            {props.categoryId ? t('save') : t('add')}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={props.onClose}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

CreatePostCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  onRefetchData: PropTypes.func.isRequired
};

CreatePostCategoryDialog.defaultProps = {
  categoryId: null
};

export default CreatePostCategoryDialog;
