import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import CheckboxField from "../../field/CheckboxField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import useCorrespondenceAdminService from "../../../services/correspondenceAdminService";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import AutocompleteField from "../../field/AutocompleteField";

const CreatePostSubcategoryDialog = (props) => {
  const { t } = useTranslation();
  const { formValue, setFormValue, onChange, onChangeAutocompleteFieldWithObjectOptions } = useForm({
    post_subcat_active: true,
  });

  const {
    createPostSubcategory,
    updatePostSubcategoryData,
    getPostSubcategoryDetails,
    getPostCategoryList,
  } = useCorrespondenceAdminService();
  
  const createPostSubcategoryFn = useAsyncFn(createPostSubcategory);
  const updatePostSubcategoryFn = useAsyncFn(updatePostSubcategoryData);
  const snackbarAlert = useSnackbarAlert();

  const postSubcategoryDetailsData = useAsync(() => {
    if (props.postSubcategoryId) {
      return getPostSubcategoryDetails(props.postSubcategoryId);
    }
    return Promise.resolve({});
  }, [props.postSubcategoryId]);

  const postCategoryOptions = useAsync(getPostCategoryList, []);

  useEffect(() => {
    if (!postSubcategoryDetailsData.loading && postSubcategoryDetailsData.value && props.postSubcategoryId) {
      setFormValue(postSubcategoryDetailsData.value);
    }
  }, [props.postSubcategoryId, postSubcategoryDetailsData.loading]);

  const onSubmit = (postSubcategoryData) => {
    if (postSubcategoryData.id) {
      updatePostSubcategoryFn.execute(postSubcategoryData.id, postSubcategoryData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_subcategory_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_updating_post_subcategory"));
        });
    } else {
      createPostSubcategoryFn.execute(postSubcategoryData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_subcategory_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_post_subcategory"));
        });
    }
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formValue);
    }
  };

  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const isFormValid = () => {
    return !isEmptyValue(formValue.post_subcat_name) && !isEmptyValue(formValue.post_subcat_category);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      title={props.postSubcategoryId ? t("dialog.correspondence_admin_panel_dialogs.edit_subcategory") : t("dialog.correspondence_admin_panel_dialogs.add_subcategory")}
    >
      <Grid container spacing={2}>
      <Grid item xs={4}>
          <AutocompleteField
            name="post_subcat_category"
            label={t("dialog.correspondence_admin_panel_dialogs.category_name")}
            optionValueKey="id"
            optionLabelKey="post_cat_name"
            value={formValue["post_subcat_category"]}
            options={props.filteringData.value.post_subcat_category}
            onChange={onChangeAutocompleteFieldWithObjectOptions}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldFormControl
            name="post_subcat_name"
            label={t("dialog.correspondence_admin_panel_dialogs.subcategory_name")}
            value={formValue["post_subcat_name"]}
            onChange={onChange}
            required
            fullWidth
          />
        </Grid> 
        <Grid item xs={4}>
          <TextFieldFormControl
            name="post_subcat_mail"
            label={t("dialog.correspondence_admin_panel_dialogs.subcategory_mail")}
            value={formValue["post_subcat_mail"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={9}>
          <TextFieldFormControl
            name="post_subcat_action"
            label={t("dialog.correspondence_admin_panel_dialogs.subcategory_action")}
            value={formValue["post_subcat_action"]}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxField
            name="post_subcat_active"
            label={t("dialog.correspondence_admin_panel_dialogs.active")}
            checked={formValue["post_subcat_active"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            {props.postSubcategoryId ? t("save") : t("add")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton variant="outlined" color="secondary" fullWidth onClick={props.onClose}>
            {t("cancel")}
          </LoadingButton>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

CreatePostSubcategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefetchData: PropTypes.func,
  postSubcategoryId: PropTypes.string,
};

CreatePostSubcategoryDialog.defaultProps = {
  postSubcategoryId: null,
  onRefetchData: null,
};

export default CreatePostSubcategoryDialog;
