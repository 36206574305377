import PlannerTimeline from "../../components/other/PlannerTimeline";
import NavigationDrawer from "../../components/drawer/NavigationDrawer";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import useUserService from "../../services/userService";
import { useAsync, useAsyncFn } from "../../hooks/useAsync";
import LoaderWrapper from "../../components/wrapper/LoaderWrapper";
import useTaskService from "../../services/taskService";
import moment from "moment";
import { Button, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbarAlert } from "../../context/snackbarAlert";
import {
  convertMomentInstanceToStringDatetimeInUtc,
  findAndDeleteObjectFromArray,
  isEmptyValue,
  getErrorMsg,
} from "../../helpers/methods";
import PlannerOrders from "../../components/other/PlannerOrders";
import { ORDER_TABLE_FIELDS_CONFIG } from "./PageTablesConfig";
import useTimelineService from "../../services/timelineService";
import DateField from "../../components/field/DateField/DateField";
import DateService from "../../services/dateService";
import DefaultPageWrapper from "../../components/wrapper/DefaultPageWrapper";
import DetailsDrawer from "../../components/drawer/UniversalDetailsDrawer/UniversalDetailsDrawer";
import useBasicDrawer from "../../hooks/useBasicDrawer";
import useOrderService from "../../services/orderService";
import PlannerUnfinishedTasks from "../../components/other/PlannerUnfinishedTasks/PlannerUnfinishedTasks";
import {
  ORDER,
  STATUS_KIND,
  TASK,
  RCP_ACTIVITIES,
  MANAGE_PLANNER_PERMISSION,
  MANAGE_TEAMS_PERMISSION,
  MANAGE_ORDERS_PERMISSION,
} from "../../helpers/constants";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog/confirmationDialog";
import ManagementTeamsDialog from "../../components/dialog/ManagementTeamsDialog";
import useDialog from "../../hooks/useDialog";
import {
  taskDateStartTPlanField,
  taskDateStartTRealField,
  taskDateStopTPlanField,
  taskDateStopTRealField,
} from "../../helpers/fieldNames";
import { useAuth } from "../../context/auth";
import useSkillService from "../../services/skillService";
import PlannerService from "../../services/plannerService";
import {
  overflowButtonStyle,
  centerVericalAlignIconStyle,
} from "../../helpers/styles";
import useLocalStorage from "../../hooks/useLocalStorage";
import { usePreventBrowserUnload } from "../../hooks/usePreventBrowerUnload";
import useRcpService from "../../services/rcpService";
import hasUserPermission, { IS_ADMIN } from "../../helpers/userPermissions";
import useCheckPermission from "../../hooks/usePermission";
import IconButtonWithTooltip from "../../components/button/IconButtonWithTooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function PlannerPage(props) {
  const { t } = useTranslation();

  const { user } = useAuth();

  const { preventBrowserUnload, allowBrowserUnload } =
    usePreventBrowserUnload();

  const sidebarWidth = 300;

  const defaultUnit = "minutes";

  const opendedItemDetailsDataRef = useRef();
  const plannerOrdersRef = useRef();
  const waitingRoomTasksRef = useRef();

  const [
    openManagementTeamsDialog,
    onOpenManagementTeamsDialog,
    onCloseManagementTeamsDialog,
  ] = useDialog();

  const [plannerTaskFields] = useState([
    "id",
    "task_name",
    "task_planner_desc",
    "task_planner_label",
    "task_t",
    taskDateStartTPlanField,
    taskDateStopTPlanField,
    "order",
    "task_parent",
    "status",
    taskDateStartTRealField,
    taskDateStopTRealField,
    "allow_edit_on_planner",
    "allow_delete_on_planner",
    "substatus",
  ]);

  const { getUserHasSkill } = useUserService();
  const { handleItemDrop } = useTimelineService();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useBasicDrawer(false);
  const [detailsDrawerProps, setDetailsDrawerProps] = useState({});

  const [newAllocatedTasks, setNewAllocatedTasks] = useState([]);

  const { startTimeHour, endTimeHour } =
    DateService.getStartAndEndTimeForPlanner();

  const [startHourLS, setStartHourLS, removeStartHourLS] =
    useLocalStorage("planner_starthour");
  const [startHour, setStartHour] = useState(startHourLS || startTimeHour);

  const [endHourLS, setEndHourLS, removeEndHourLS] =
    useLocalStorage("planner_endhour");
  const [endHour, setEndHour] = useState(endHourLS || endTimeHour);

  const [timestampStart, setTimestampStart] = useState();
  const [timestampEnd, setTimestampEnd] = useState();

  const [plannerTimelineSkillGroup, setPlannerTimelineSkillGroup] =
    useLocalStorage("planner_skill");

  const [plannerTimelineSearchParams, setPlannerTimelineSearchParams] =
    useState({ date: new Date(), skill: plannerTimelineSkillGroup });

  const [isDialogWithParamsClosed, setIsDialogWithParamsClosed] =
    useState(true);

  const [plannedTaskIdsFromWaitingRoom, setPlannedTaskIdsFromWaitingRoom] =
    useState([]);

  const [confirmationDialogData, setConfirmationDialogData] = useState({});

  const [scrollTimelineRef, setScroolTimelineRef] = useState(useRef());
  const [timelineRef, setTimelineRef] = useState(useRef());

  const { getSkillsUserIsLeaderSelectList, getSkillSelectList } =
    useSkillService();
  const skillsUserIsLeaderSelectList = useAsync(() => {
    if (user.is_admin) {
      return Promise.resolve();
    }
    return getSkillsUserIsLeaderSelectList(user);
  }, []);
  const skillSelectList = useAsync(getSkillSelectList, []);
  const isAdmin = hasUserPermission([IS_ADMIN], user);
  const [hasPermissionToManagePlanner] = useCheckPermission(
    MANAGE_PLANNER_PERMISSION
  );
  const [hasPermissionToManageTeams] = useCheckPermission(
    MANAGE_TEAMS_PERMISSION
  );

  const [hasPermissionToManageOrders] = useCheckPermission(
    MANAGE_ORDERS_PERMISSION
  );

  const {
    getTasksForPlanner,
    massCreateOrUpdateTasks,
    getTaskDetailsData,
    getTaskFilteringData,
    updateTaskData,
  } = useTaskService();

  const snackbarAlert = useSnackbarAlert();

  const { getOrderDetailsData, getOrderFilteringData, updateOrderData } =
    useOrderService();

  const getTaskDetailsDataFn = useAsyncFn(getTaskDetailsData);
  const taskFilteringData = useAsync(getTaskFilteringData);

  const tasksForPlanner = useAsync(() => {
    if (plannerTimelineSearchParams.date) {
      const { start, end } = DateService.getFilterDatetimeRangeForData(
        plannerTimelineSearchParams.date
      );
      var searchParamsTemp = { start_date_range: start, end_date_range: end };
      if (plannerTimelineSearchParams.skill) {
        searchParamsTemp["task_t__skill"] = plannerTimelineSearchParams.skill;
      }
      return getTasksForPlanner(searchParamsTemp);
    }
    return Promise.resolve();
  }, [plannerTimelineSearchParams.skill, plannerTimelineSearchParams.date]);

  const [users, setUsers] = useState([]);

  const [tasks, setTasks] = useState();

  const orderFilteringData = useAsync(getOrderFilteringData);

  const userListAsync = useAsync(() => {
    let searchParams = { only_employee: true, roles: "role_is_t" };
    if (plannerTimelineSearchParams.skill) {
      searchParams.skill = plannerTimelineSearchParams.skill;
    }
    return getUserHasSkill(searchParams);
  }, [plannerTimelineSearchParams.skill]);

  const getOrderDetailsDataFn = useAsyncFn(getOrderDetailsData);

  const executeGetOrderDetailsDataFn = useCallback(
    (orderId) => {
      getOrderDetailsDataFn
        .execute(orderId)
        .then((data) => (opendedItemDetailsDataRef.current = data));
    },
    [getOrderDetailsDataFn]
  );

  const updateOrderDataFn = useAsyncFn(updateOrderData);
  const onUpdateOrderDataPromise = (orderId, dataToSend) => {
    return updateOrderDataFn
      .execute(orderId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.order_data_updated")
        );
        refetchDataAfterSave();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_updating_order_data")
          )
        );
        return Promise.reject(error);
      });
  };

  const executeGetTaskDetailsDataFn = useCallback(
    (taskId) => {
      getTaskDetailsDataFn
        .execute(taskId)
        .then((data) => (opendedItemDetailsDataRef.current = data));
    },
    [getTaskDetailsDataFn]
  );

  const updateTaskDataFn = useAsyncFn(updateTaskData);

  const onUpdateTaskDataPromise = (taskId, dataToSend) => {
    return updateTaskDataFn
      .execute(taskId, dataToSend)
      .then((data) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.task_data_updated")
        );
        refetchDataAfterSave();
        return Promise.resolve(data);
      })
      .catch((error) => {
        snackbarAlert.openErrorSnackbarAlert(
          getErrorMsg(
            error.data,
            t("snackbar_alert.occurred_error_during_updating_task_data")
          )
        );
        return Promise.reject(error);
      });
  };

  const skillNameById = useMemo(() => {
    if (skillSelectList.value) {
      return skillSelectList.value.reduce(
        (acc, obj) => ({ ...acc, [obj.id]: obj.skill_name }),
        {}
      );
    }
    return undefined;
  }, [skillSelectList.value]);

  const getStartDate = useCallback((taskDateStartTPlan, taskDateStartTReal) => {
    if (
      !isEmptyValue(taskDateStartTReal) &&
      taskDateStartTReal < taskDateStartTPlan
    ) {
      return taskDateStartTReal;
    }
    return taskDateStartTPlan;
  }, []);

  const getEndData = useCallback((taskDateStopTPlan, taskDateStopTReal) => {
    if (
      !isEmptyValue(taskDateStopTReal) &&
      taskDateStopTReal > taskDateStopTPlan
    ) {
      return taskDateStopTReal;
    }
    return taskDateStopTPlan;
  }, []);

  const prepareTaskData = useCallback(
    (item) => {
      let itemData = {
        canResize: false,
        canMove: false,
        added: false,
        edited: false,
        deleted: false,
      };
      for (const field of plannerTaskFields) {
        let value = item[field];
        if (
          !isEmptyValue(value) &&
          [
            taskDateStopTPlanField,
            taskDateStartTPlanField,
            taskDateStartTRealField,
            taskDateStopTRealField,
          ].includes(field)
        ) {
          value = moment(value);
        }
        itemData[field] = value;
      }
      itemData.start = getStartDate(
        itemData[taskDateStartTPlanField],
        itemData[taskDateStartTRealField]
      );
      itemData.end = getEndData(
        itemData[taskDateStopTPlanField],
        itemData[taskDateStopTRealField]
      );
      if (itemData.allow_edit_on_planner && hasPermissionToManagePlanner) {
        itemData.canResize = "both";
        itemData.canMove = true;
      }
      return itemData;
    },
    [plannerTaskFields, getStartDate, getEndData, hasPermissionToManagePlanner]
  );

  const { getRcpPlanWithoutPagination } = useRcpService();

  const rcpPlan = useAsync(() => {
    if (plannerTimelineSearchParams.date) {
      const { start, end } = DateService.getFilterDatetimeRangeForData(
        plannerTimelineSearchParams.date
      );
      var searchParamsTemp = { day_start: start, day_end: end };
      if (plannerTimelineSearchParams.skill) {
        searchParamsTemp["skill"] = plannerTimelineSearchParams.skill;
      }
      return getRcpPlanWithoutPagination(searchParamsTemp);
    }
    return Promise.resolve();
  }, [plannerTimelineSearchParams.skill, plannerTimelineSearchParams.date]);

  useEffect(() => {
    let usersData = undefined;
    if (
      !userListAsync.loading &&
      userListAsync.value !== undefined &&
      !rcpPlan.loading &&
      rcpPlan.value !== undefined &&
      skillNameById
    ) {
      usersData = [];
      let groupName = plannerTimelineSearchParams.skill
        ? skillNameById[plannerTimelineSearchParams.skill]
        : undefined;
      for (var u of userListAsync.value) {
        u = { ...u };

        const rcpPlanForUser = rcpPlan.value.find(
          (rcp) => rcp.employee.id === u.id
        );
        u.dayOff = rcpPlanForUser
          ? rcpPlanForUser.rcpplan_activity !== RCP_ACTIVITIES.WORK
            ? true
            : false
          : false;

        if (!plannerTimelineSearchParams.skill) {
          var userSkillNames = [];
          for (const skillId of u.skill) {
            userSkillNames.push(skillNameById[skillId]);
          }
          groupName = userSkillNames.sort().join(", ");
        }
        u.group_name = groupName;
        usersData.push(u);
      }
    }
    setUsers(usersData);
  }, [
    userListAsync.value,
    userListAsync.loading,
    rcpPlan.loading,
    rcpPlan.value,
    skillNameById,
  ]);

  useEffect(() => {
    var newTasks;
    if (!tasksForPlanner.loading && tasksForPlanner.value) {
      newTasks = [];
      for (var task of tasksForPlanner.value) {
        newTasks.push(prepareTaskData(task));
      }
    }
    setTasks(newTasks);
  }, [tasksForPlanner.loading, tasksForPlanner.value, prepareTaskData]);

  useEffect(() => {
    if (plannerTimelineSearchParams.date) {
      let dateTemp = new Date(plannerTimelineSearchParams.date);
      const hours = parseInt(startHour.split(":")[0]);
      setTimestampStart(dateTemp.setHours(hours, 0, 0));
    }
  }, [startHour, plannerTimelineSearchParams.date]);

  useEffect(() => {
    if (plannerTimelineSearchParams.date) {
      let dateTemp = new Date(plannerTimelineSearchParams.date);
      const hours = parseInt(endHour.split(":")[0]);
      setTimestampEnd(dateTemp.setHours(hours, 0, 0));
    }
  }, [endHour, plannerTimelineSearchParams.date]);

  const onChangeSkill = (e) => {
    setPlannerTimelineSearchParams((searchParamsTemp) => ({
      ...searchParamsTemp,
      skill: e.target.value,
    }));
    setPlannerTimelineSkillGroup(e.target.value);
  };

  // function CustomDateHeader() {
  //     return (
  //       <CustomHeader>
  //         {({
  //           headerContext: { unit, intervals },
  //           getRootProps,
  //           getIntervalProps
  //         }) => {
  //           return (
  //             <div {...getRootProps()}>
  //               {intervals.map(interval => {
  //                 const intervalText = formatLabel(
  //                   [interval.startTime, interval.endTime],
  //                   unit,
  //                   interval.labelWidth
  //                 );

  //                 return (
  //                   <div
  //                     className="rct-dateHeader"
  //                     key={intervalText}
  //                     {...getIntervalProps({
  //                       interval
  //                     })}
  //                   >
  //                     <span>{intervalText}</span>
  //                   </div>
  //                 );
  //               })}
  //             </div>
  //           );
  //         }}
  //       </CustomHeader>
  //     );
  //   }

  function roundTimeQuarterHour(timestamp) {
    var timeToReturn = new Date(timestamp);
    timeToReturn.setMilliseconds(
      Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn;
  }

  const generateIdForNewItem = useCallback((itemsTempLength) => {
    return itemsTempLength + 1;
  }, []);

  const addTaskByDrop = ({
    data: {
      title,
      order,
      duration,
      unit,
      task_parent,
      task_planner_label,
      task_name,
      id,
    },
    start,
    group,
  }) => {
    if (unit === undefined) {
      unit = defaultUnit;
    }
    let end = moment(start);
    end.add(duration, unit);
    const edited = id !== undefined;
    setTasks((itemsTemp) => [
      ...itemsTemp,
      {
        id: edited ? id : generateIdForNewItem(itemsTemp.length),
        start: start,
        end: end,
        [taskDateStartTPlanField]: start,
        [taskDateStopTPlanField]: end,
        task_t: group.id,
        task_planner_label: task_planner_label,
        task_planner_desc: title,
        task_name: task_name,
        order: order,
        task_parent: task_parent,
        added: !edited,
        edited: edited,
        canResize: "both",
        allow_edit_on_planner: true,
        allow_delete_on_planner: !task_parent,
        // className:
        //     startDay === 6 || startDay === 0 || endDay === 6 || endDay === 0
        //         ? 'item-weekend'
        //         : '',
        // itemProps: {
        //     'data-tip': 'Drag & drop is working',
        // },
      },
    ]);
    preventBrowserUnload();
  };

  const onChangeTask = (taskId, data, notSetEdited = false) => {
    if (true) {
      setTasks((tasksTemp) => {
        var task = tasksTemp.find((x) => x.id === taskId);
        for (const [key, value] of Object.entries(data)) {
          task[key] = value;
        }
        if (!notSetEdited && !task.added && !task.edited && !task.deleted) {
          task.edited = true;
        }
        return [...tasksTemp];
      });
    }
  };

  const onItemMove = (itemId, dragTime, newGroupIndex) => {
    if (hasPermissionToManagePlanner) {
      const task = findTaskById(itemId);
      const end = moment(
        dragTime + (task.task_date_stopT_plan - task.task_date_startT_plan)
      );
      const start = moment(dragTime);
      if (checkIfDateIsBeforeNow(moment(start))) {
        showTaskStartBeforeNowSnackbar();
      } else {
        onChangeTask(itemId, {
          start,
          end,
          task_date_stopT_plan: end,
          task_date_startT_plan: start,
          task_t: users[newGroupIndex].id,
        });
      }
      preventBrowserUnload();
    }
  };

  const onItemResize = (itemId, time, edge) => {
    if (hasPermissionToManagePlanner) {
      time = moment(time);
      if (edge === "left" && checkIfDateIsBeforeNow(time)) {
        showTaskStartBeforeNowSnackbar();
      } else {
        onChangeTask(itemId, {
          [edge === "right" ? "end" : "start"]: time,
          [edge === "right" ? "task_date_stopT_plan" : "task_date_startT_plan"]:
            time,
        });
      }
      preventBrowserUnload();
    }
  };

  const findTaskById = (itemId) => {
    return tasks.find((x) => x.id === itemId);
  };

  const prepareTaskDataToSave = (taskData, isNewAllocatedTask = false) => {
    const fieldsToSave =
      isNewAllocatedTask === false
        ? [
            "task_name",
            "task_date_startT_plan",
            "task_date_stopT_plan",
            "task_t",
            "order",
            "task_parent",
          ]
        : ["task_name", "order", "status"];
    let taskDataToSave = {};
    for (let fieldName of fieldsToSave) {
      let value = taskData[fieldName];
      if (
        ["task_date_startT_plan", "task_date_stopT_plan"].includes(fieldName) &&
        value
      ) {
        value = convertMomentInstanceToStringDatetimeInUtc(value);
      } else if (fieldName === "status" && taskData[fieldName]) {
        fieldName = "status";
        value = taskData.status.id;
      }
      if (value) {
        taskDataToSave[fieldName] = value;
      }
    }
    if (taskData.edited) {
      taskDataToSave.id = taskData.id;
    }
    // taskDataToSave.task_kt = user.user_id;
    return taskDataToSave;
  };

  const prepareDeletedTaskDataToSave = (taskData) => {
    return {
      id: taskData.id,
      task_is_active: false,
    };
  };

  const prepareDataToSave = () => {
    let dataToSave = [];
    for (const allocatedTasks of newAllocatedTasks) {
      const allocatedTasksTemp = {
        ...allocatedTasks,
        order: allocatedTasks.order.id,
      };
      dataToSave.push(prepareTaskDataToSave(allocatedTasksTemp, true));
    }
    for (const task of tasks) {
      if (task.added || task.edited) {
        dataToSave.push(prepareTaskDataToSave(task));
      } else if (task.deleted) {
        dataToSave.push(prepareDeletedTaskDataToSave(task));
      }
    }
    return dataToSave;
  };

  const massCreateOrUpdateTasksFn = useAsyncFn(massCreateOrUpdateTasks);

  const onSave = () => {
    const dataToSave = prepareDataToSave();
    massCreateOrUpdateTasksFn
      .execute(dataToSave)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.saved_changes")
        );
        refetchDataAfterSave();
        setTimeout(() => {
          cleanDataAfterSave();
        }, 50);
        allowBrowserUnload();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_saving_changes")
        );
      });
  };

  const onItemDrop = (e, itemData, itemType = ORDER) => {
    if (hasPermissionToManagePlanner) {
      const handleItempDropData = handleItemDrop(
        e,
        timelineRef,
        scrollTimelineRef,
        itemData
      );

      if (handleItempDropData === undefined) {
        return;
      }

      let { data, start, group } = handleItempDropData;
      start = moment(roundTimeQuarterHour(start));

      if (checkIfDateIsBeforeNow(start)) {
        showTaskStartBeforeNowSnackbar();
      } else {
        let taskData;
        let orderDeadlineKT;
        let extraCallbackToExecute;
        if (itemType === "task") {
          taskData = {
            order: data.order.id,
            title: data.task_nr
              ? `${data.task_nr} ${data.task_name}`
              : data.task_name,
            task_name: data.task_name,
            task_planner_label: data.task_planner_label,
            duration: data.duration,
          };
          orderDeadlineKT = data.order.order_deadlineKT;
          const newAllocatedTaskIndex = newAllocatedTasks.findIndex(
            (x) => x.id === itemData.id
          );
          if (newAllocatedTaskIndex === -1) {
            if (itemData.status.status_kind === STATUS_KIND.UNFINISHED) {
              taskData.task_parent = itemData.id;
            } else {
              //is allocated task, so is editing
              taskData.id = itemData.id;
            }

            extraCallbackToExecute = () =>
              setPlannedTaskIdsFromWaitingRoom(
                (plannedTaskIdsFromWaitingRoomTemp) => [
                  ...plannedTaskIdsFromWaitingRoomTemp,
                  itemData.id,
                ]
              );
          } else {
            extraCallbackToExecute = () =>
              setNewAllocatedTasks((newAllocatedTasksTemp) => {
                newAllocatedTasksTemp.splice(newAllocatedTaskIndex, 1);
                return [...newAllocatedTasksTemp];
              });
          }
        } else {
          orderDeadlineKT = itemData.order_deadlineKT;
          taskData = {
            order: data.id,
            title: data.task_planner_desc,
            task_name: data.order_name,
            task_planner_label: data.task_planner_label,
            duration: data.order_std_time_value,
          };
        }

        let end = moment(start);
        end.add(taskData.duration, defaultUnit);
        if (
          !isEmptyValue(orderDeadlineKT) &&
          moment(end.format("YYYY-MM-DD")).isAfter(moment(orderDeadlineKT))
        ) {
          onOpenConfirmationDialogData({
            title: t("alert"),
            content: `${t(
              "dialog.planner_confirmation_dialog.planned_end_task_after_deadline"
            )} (${orderDeadlineKT}). ${t(
              "dialog.planner_confirmation_dialog.are_you_sure_you_want_planned_the_task"
            )}`,
            onNo: onCloseConfirmationDialogData,
            onYes: () => {
              addTaskByDrop({ data: taskData, start, group });
              onCloseConfirmationDialogData();
              if (extraCallbackToExecute) {
                extraCallbackToExecute();
              }
            },
          });
        } else {
          addTaskByDrop({ data: taskData, start, group });
          if (extraCallbackToExecute) {
            extraCallbackToExecute();
          }
        }
      }
    }
  };

  const onChangeScrollTimlineRef = useCallback((scrollTimlineRef) => {
    setScroolTimelineRef(scrollTimlineRef);
  }, []);

  const onChangeTimlineRef = useCallback((timlineRef) => {
    setTimelineRef(timlineRef);
  }, []);

  const showLoaderInTimeline = useMemo(
    () => tasksForPlanner.loading || tasks === undefined || users === undefined,
    [tasks, tasksForPlanner.loading, users]
  );

  const showLoaderPage = useMemo(() => {
    return skillSelectList.loading || skillsUserIsLeaderSelectList.loading;
  }, [skillSelectList.loading, skillsUserIsLeaderSelectList.loading]);

  const onDuplicateItem = useCallback(
    (item) => {
      if (hasPermissionToManagePlanner) {
        setTasks((itemsTemp) => [
          ...itemsTemp,
          {
            ...item,
            id: generateIdForNewItem(itemsTemp.length),
            edited: false,
            added: true,
          },
        ]);
        preventBrowserUnload();
      }
    },
    [generateIdForNewItem, hasPermissionToManagePlanner]
  );

  const deleteIdFromPlannedTaskIdsFromWaitingRoom = (task) => {
    setPlannedTaskIdsFromWaitingRoom((plannedTaskIdsFromWaitingRoomTemp) => {
      let plannedTaskIdFromWaitingRoomToDeleteIndex = -1;

      if (task.added && task.task_parent) {
        //task created based on unfinished tasks
        plannedTaskIdFromWaitingRoomToDeleteIndex =
          plannedTaskIdsFromWaitingRoomTemp.findIndex(
            (x) => x === task.task_parent
          );
      } else {
        //saved allocated tasks
        plannedTaskIdFromWaitingRoomToDeleteIndex =
          plannedTaskIdsFromWaitingRoomTemp.findIndex((x) => x === task.id);
      }
      if (plannedTaskIdFromWaitingRoomToDeleteIndex !== -1) {
        plannedTaskIdsFromWaitingRoomTemp.splice(
          plannedTaskIdFromWaitingRoomToDeleteIndex,
          1
        );
        return [...plannedTaskIdsFromWaitingRoomTemp];
      }
      return plannedTaskIdsFromWaitingRoomTemp;
    });
  };

  const onDeleteItem = useCallback((item) => {
    if (item.added || item.edited) {
      deleteIdFromPlannedTaskIdsFromWaitingRoom(item);
      setTasks((itemsTemp) => {
        findAndDeleteObjectFromArray(itemsTemp, item.id);
        return [...itemsTemp];
      });
    } else {
      onChangeTask(item.id, { deleted: true });
    }
    preventBrowserUnload();
  }, []);

  const onSaveItem = useCallback((item) => {
    const dataToSave = [prepareTaskDataToSave(item)];
    massCreateOrUpdateTasks(dataToSave)
      .then((result) => {
        snackbarAlert.openSuccessSnackbarAlert(
          t("snackbar_alert.saved_changes")
        );

        onChangeTask(item.id, prepareTaskData(result[0]), true);
        plannerOrdersRef.current.refetchOrders();
      })
      .catch(() => {
        snackbarAlert.openErrorSnackbarAlert(
          t("snackbar_alert.occurred_error_during_saving_changes")
        );
      });
  }, []);

  const onAllocateTask = useCallback((order, taskData) => {
    setNewAllocatedTasks((newAllocatedTasksTemp) => {
      return [
        ...newAllocatedTasksTemp,
        {
          task_planner_desc: taskData.task_planner_desc,
          task_planner_label: order.task_planner_label,
          task_name: taskData.task_name,
          duration: order.order_std_time_value,
          order: order,
          id: `allocated_task_${newAllocatedTasksTemp.length + 1}`,
          status: taskData.status,
        },
      ];
    });
    preventBrowserUnload();
  }, []);

  const onOpenConfirmationDialogData = useCallback((dialogExtraData) => {
    setConfirmationDialogData({ open: true, ...dialogExtraData });
  }, []);

  const onCloseConfirmationDialogData = useCallback(() => {
    setConfirmationDialogData({});
  }, []);

  const refetchDataAfterSave = () => {
    plannerOrdersRef.current.refetchOrders();
    waitingRoomTasksRef.current.refetchTaskWaitingRoom();
    tasksForPlanner.refetch();
  };

  const cleanDataAfterSave = () => {
    setNewAllocatedTasks([]);
    setPlannedTaskIdsFromWaitingRoom([]);
  };

  const checkIfDateIsBeforeNow = (date) => {
    //date moment instance
    return date.isBefore(moment());
  };

  const showTaskStartBeforeNowSnackbar = () => {
    snackbarAlert.openErrorSnackbarAlert(
      t("snackbar_alert.task_start_before_now")
    );
  };

  const onCloseOpenDetailsDrawer = useCallback(() => {
    setOpenDetailsDrawer(false);
    setDetailsDrawerProps({});
    opendedItemDetailsDataRef.current = null;
  }, [setOpenDetailsDrawer]);

  const onOpenDetailsDrawer = useCallback(
    (
      itemType,
      onRefetchData,
      filteringData,
      availableActionButtons,
      onUpdateDataPromise
    ) => {
      setDetailsDrawerProps({
        itemType: itemType,
        onRefetchData: onRefetchData,
        filteringData: filteringData,
        availableActionButtons: availableActionButtons,
        onUpdateDataPromise: onUpdateDataPromise,
      });
      setOpenDetailsDrawer(true);
    },
    [setOpenDetailsDrawer]
  );

  const onOpenTaskDetailsDrawer = useCallback(
    (taskId) => {
      if (
        taskId &&
        taskId !== opendedItemDetailsDataRef.current?.id &&
        newAllocatedTasks.findIndex((x) => x.id === taskId) === -1
      ) {
        executeGetTaskDetailsDataFn(taskId);
        onOpenDetailsDrawer(
          "task",
          () =>
            executeGetTaskDetailsDataFn(opendedItemDetailsDataRef.current?.id),
          taskFilteringData.value,
          hasPermissionToManagePlanner
            ? ["change_status", "enclosures"]
            : ["enclosures"],
          onUpdateTaskDataPromise
        );
      } else {
        onCloseOpenDetailsDrawer();
      }
    },
    [
      executeGetTaskDetailsDataFn,
      onOpenDetailsDrawer,
      taskFilteringData.value,
      newAllocatedTasks,
      hasPermissionToManagePlanner,
    ]
  );

  const onOpenOrderDetailsDrawer = (orderId) => {
    if (orderId && orderId !== opendedItemDetailsDataRef.current?.id) {
      executeGetOrderDetailsDataFn(orderId);
      onOpenDetailsDrawer(
        "order",
        () =>
          executeGetOrderDetailsDataFn(opendedItemDetailsDataRef.current?.id),
        orderFilteringData?.value,
        hasPermissionToManagePlanner
          ? ["change_status", "enclosures"]
          : ["enclosures"],
        onUpdateOrderDataPromise
      );
    } else {
      onCloseOpenDetailsDrawer();
    }
  };

  const onShowTaskDetailsItem = useCallback(
    (item) => {
      onOpenTaskDetailsDrawer(item.id);
    },
    [onOpenTaskDetailsDrawer]
  );

  const handleCLoseManagementTeamsDialog = (existsChanges) => {
    if (existsChanges) {
      userListAsync.refetch();
      skillSelectList.refetch();
      rcpPlan.refetch();
    }
    onCloseManagementTeamsDialog();
  };

  return (
    <DetailsDrawer
      openDrawer={openDetailsDrawer}
      setOpenDrawer={onCloseOpenDetailsDrawer}
      itemData={opendedItemDetailsDataRef.current}
      isLoading={
        getOrderDetailsDataFn.loading ||
        getTaskDetailsDataFn.loading ||
        !opendedItemDetailsDataRef.current
      }
      onOpenDialogParamsAction={setIsDialogWithParamsClosed}
      onRefetchData={() =>
        executeGetOrderDetailsDataFn(opendedItemDetailsDataRef.current?.id)
      }
      readOnly={false}
      hasPermissionToManageOrders={hasPermissionToManageOrders}
      {...detailsDrawerProps}
    >
      <NavigationDrawer pageName={props.pageName}>
        <DefaultPageWrapper titleKey={"planner"}>
          <LoaderWrapper showLoader={showLoaderPage}>
            <Grid
              container
              spacing={1}
              style={{ marginBlock: "5px" }}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item style={{ width: sidebarWidth }}>
                    {(hasPermissionToManageTeams || user.is_admin) && (
                      <Button
                        variant="contained"
                        sx={{ ...overflowButtonStyle }}
                        fullWidth
                        onClick={onOpenManagementTeamsDialog}
                      >
                        {t("page.planner_page.team_management")}
                      </Button>
                    )}
                  </Grid>

                  <Grid
                    item
                    style={{ width: `calc(100% - ${sidebarWidth}px)` }}
                  >
                    <Grid
                      container
                      spacing={1}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={6} container>
                        <Grid item xs={1}>
                          <IconButtonWithTooltip
                            title={DateService.getPreviousDayFromDateAsStringInFormatYYYYMMDD(
                              plannerTimelineSearchParams.date
                            )}
                            onClick={() =>
                              setPlannerTimelineSearchParams(
                                (searchParamsTemp) => ({
                                  ...searchParamsTemp,
                                  date: DateService.getPreviousDayFromDate(
                                    plannerTimelineSearchParams.date
                                  ),
                                })
                              )
                            }
                          >
                            <ArrowBackIcon />
                          </IconButtonWithTooltip>
                        </Grid>
                        <Grid item xs={10}>
                          <DateField
                            label={"data"}
                            value={plannerTimelineSearchParams.date}
                            onChange={(e) => {
                              setPlannerTimelineSearchParams(
                                (searchParamsTemp) => ({
                                  ...searchParamsTemp,
                                  date: e.target.value,
                                })
                              );
                            }}
                            showClearIcon={false}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButtonWithTooltip
                            title={DateService.getNextDayFromDateAsStringInFormatYYYYMMDD(
                              plannerTimelineSearchParams.date
                            )}
                            onClick={() =>
                              setPlannerTimelineSearchParams(
                                (searchParamsTemp) => ({
                                  ...searchParamsTemp,
                                  date: DateService.getNextDayFromDate(
                                    plannerTimelineSearchParams.date
                                  ),
                                })
                              )
                            }
                          >
                            <ArrowForwardIcon />
                          </IconButtonWithTooltip>
                        </Grid>
                      </Grid>

                      {hasPermissionToManagePlanner && (
                        <Grid item xs={12} sm={3}>
                          <LoadingButton
                            startIcon={
                              <SaveIcon sx={centerVericalAlignIconStyle} />
                            }
                            sx={{ ...overflowButtonStyle }}
                            variant="contained"
                            onClick={onSave}
                            fullWidth
                            loading={massCreateOrUpdateTasksFn.loading}
                          >
                            {t("save_and_refresh")}
                          </LoadingButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.dropEffect = "move";
                  }}
                >
                  <PlannerTimeline
                    items={tasks ? tasks.filter((x) => x.deleted !== true) : []}
                    users={users}
                    visibleTimeStart={timestampStart}
                    visibleTimeEnd={timestampEnd}
                    sidebarWidth={sidebarWidth}
                    skill={plannerTimelineSearchParams.skill}
                    skillsUserIsLeaderSelectList={
                      isAdmin
                        ? skillSelectList.value
                        : skillsUserIsLeaderSelectList.value
                    }
                    skillSelectList={skillSelectList.value}
                    onChangeStartHour={(e) => {
                      setStartHour(e.target.value);
                      setStartHourLS(e.target.value);
                    }}
                    onClearStartHour={(e) => {
                      setStartHour(startTimeHour);
                      removeStartHourLS();
                    }}
                    onChangeEndHour={(e) => {
                      setEndHour(e.target.value);
                      setEndHourLS(e.target.value);
                    }}
                    onClearEndHour={(e) => {
                      setEndHour(endTimeHour);
                      removeEndHourLS();
                    }}
                    onChangeSkill={onChangeSkill}
                    startHour={startHour}
                    endHour={endHour}
                    handleItemDrop={handleItemDrop}
                    onItemMove={onItemMove}
                    onItemResize={onItemResize}
                    onChangeScrollTimlineRef={onChangeScrollTimlineRef}
                    onChangeTimlineRef={onChangeTimlineRef}
                    onDuplicateItem={onDuplicateItem}
                    onDeleteItem={onDeleteItem}
                    onSaveItem={onSaveItem}
                    showLoader={showLoaderInTimeline}
                    onShowDetailsItem={onShowTaskDetailsItem}
                    hasPermissionToManagePlanner={hasPermissionToManagePlanner}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={4} sm={12} xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <PlannerUnfinishedTasks
                          newAllocatedTasks={newAllocatedTasks}
                          onTaskDrop={(e, itemData) =>
                            onItemDrop(e, itemData, TASK)
                          }
                          onDragStartRow={
                            PlannerService.handlePlannerTablesDragStart
                          }
                          draggableRows={hasPermissionToManagePlanner}
                          plannedTaskIds={plannedTaskIdsFromWaitingRoom}
                          ref={waitingRoomTasksRef}
                          onClickTableRow={onOpenTaskDetailsDrawer}
                          selectedTaskId={opendedItemDetailsDataRef.current?.id}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} sm={12} xs={12}>
                    <PlannerOrders
                      tableFieldConfigs={ORDER_TABLE_FIELDS_CONFIG}
                      onOrderDrop={onItemDrop}
                      onDragStartRow={
                        PlannerService.handlePlannerTablesDragStart
                      }
                      style={{ maxHeight: "40vh" }}
                      onClickTableRow={onOpenOrderDetailsDrawer}
                      showContextMenuTable={hasPermissionToManagePlanner}
                      draggableRows={hasPermissionToManagePlanner}
                      ref={plannerOrdersRef}
                      onAllocateTask={onAllocateTask}
                      selectedOrderId={opendedItemDetailsDataRef.current?.id}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {confirmationDialogData.open && (
                <ConfirmationDialog {...confirmationDialogData} />
              )}
              {openManagementTeamsDialog && (
                <ManagementTeamsDialog
                  open={openManagementTeamsDialog}
                  onClose={handleCLoseManagementTeamsDialog}
                />
              )}
            </Grid>
          </LoaderWrapper>
        </DefaultPageWrapper>
      </NavigationDrawer>
    </DetailsDrawer>
  );
}

export default PlannerPage;
