import DateService from "./dateService";
import { USER_TENANT_TABLE_FILTER_PREFIX } from "../components/tabs/RRAdminTabs/RRAdminUserContactsTab/TableConfig";

import { FILTER_SEPARATOR } from "../helpers/constants";
import { TENANT_TABLE_FILTER_PREFIX } from "../components/other/TenantTableWithToolBar/TableConfig";

const FilterValueService = {
  getDefaultIsOpenFilter: function (
    { isOpenKey, isOpenValue, filterPrefix } = {
      isOpenKey: "is_open",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isOpenKey]: isOpenValue,
        },
      },
    ];
  },

  getDefaulDateFilter: function (
    { dateKey, filterPrefix } = {
      dateKey: "date_start",
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [dateKey]: DateService.getISOStringZeroHourOfDate(new Date()),
        },
      },
    ];
  },

  getDefaulDateWithIsOpenFilter: function (
    { isOpenKey, isOpenValue, dateKey, filterPrefix } = {
      isOpenKey: "is_open",
      dateKey: "date_start",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isOpenKey]: isOpenValue,
          [dateKey]: DateService.getISOStringZeroHourOfDate(new Date()),
        },
      },
    ];
  },

  getDefaulFilterForVisibleUserTenant: function (
    { fieldName, filterPrefix } = {
      fieldName: "uste_rr_visibility",
      filterPrefix: USER_TENANT_TABLE_FILTER_PREFIX,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [fieldName]: true,
        },
      },
    ];
  },

  getDefaulFilterForPageSizeAndExtraFields: function ({
    pageSize = 100,
    filterPrefix,
    extraFields = [],
  } = {}) {
    let filterValue = { page_size: pageSize };
    for (const field of extraFields) {
      filterValue[field.name] = field.value;
    }

    return [
      {
        filter_prefix: filterPrefix,
        filter_value: filterValue,
      },
    ];
  },

  getDefaulFilterForActiveAgreements: function (
    { isActiveKey, isOpenValue, filterPrefix } = {
      isActiveKey: "agreement_active",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isActiveKey]: isOpenValue,
        },
      },
    ];
  },

  getDefaulFilterForActiveOwners: function (
    { isActiveKey, isOpenValue, filterPrefix } = {
      isActiveKey: "owner_active",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isActiveKey]: isOpenValue,
        },
      },
    ];
  },
  getDefaulFilterForPostIn: function (
    { isActiveKey, isOpenValue, filterPrefix } = {
      isActiveKey: "post_in",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isActiveKey]: isOpenValue
        },
      },
    ];
  },
  getDefaulFilterForActiveSubcontractors: function (
    { isActiveKey, isOpenValue, filterPrefix } = {
      isActiveKey: "subcontractor_active",
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isActiveKey]: isOpenValue
        },
      },
    ];
  },

  getFirstAndLastDayOfCurrentMonthFilter: function (
    { dayStartKey, dayEndKey, filterPrefixesList } = {
      dayStartKey: "day_start",
      dayEndKey: "day_end",
      filterPrefixesList: null,
    }
  ) {
    const { firstDay, lastDay } =
      DateService.getFirstAndLastDayOfCurrentMonth();

    const filterPrefixes = Array.isArray(filterPrefixesList)
      ? filterPrefixesList
      : [filterPrefixesList];

    return filterPrefixes.map((filterPrefix) => ({
      filter_prefix: filterPrefix,
      filter_value: {
        [dayStartKey]: DateService.getISOStringZeroHourOfDate(firstDay),
        [dayEndKey]: DateService.getISOStringleEndHourOfDate(lastDay),
      },
    }));
  },
    getDefaulFilterForActiveTenants: function (
    { isActiveKey, isOpenValue, filterPrefix } = {
      isActiveKey: `${TENANT_TABLE_FILTER_PREFIX}${FILTER_SEPARATOR}tenant_active`,
      isOpenValue: true,
      filterPrefix: null,
    }
  ) {
    return [ 
      {
        filter_prefix: filterPrefix,
        filter_value: {
          [isActiveKey]: isOpenValue, 
        },
      },
    ];
  },
};

export default FilterValueService; 
