import { AUTOCOMPLETE_FIELD_TYPE,  BOOLEAN_FIELD_TYPE,  TEXT_FIELD_TYPE } from "../../../../helpers/constants";
import i18n from "../../../../i18n";
import SelectFieldService from "../../../../services/selectFieldService";
import ModeEditOutlineOutlined from "@mui/icons-material/ModeEditOutlineOutlined";



export const POST_SUBCATEGORY_TABLE_NAME = "post_subcategory_table";
export const POST_SUBCATEGORY_TABLE_CONFIG = [
  {
    name: "post_subcat_name",
    getValueCallback: (rowData) => rowData.post_subcat_name,
    label: i18n.t("table.correspondence_admin.post_subcat_name"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_subcat_action",
    getValueCallback: (rowData) => rowData.post_subcat_action,
    label: i18n.t("table.correspondence_admin.post_subcat_action"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "post_subcat_category",
    getValueCallback: (rowData) => rowData.post_subcat_category,
    label: i18n.t("table.correspondence.shipment_category"),
    filterType: AUTOCOMPLETE_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => ({
      options: filteringData.value.post_subcat_category,
      isObjectOption: true,
      optionLabelKey: "post_cat_name",
      addNewValue: false,
      multiple: true,
      sortable: true,
    }),
  },
  {
    name: "post_subcat_mail",
    getValueCallback: (rowData) => rowData.post_subcat_mail,
    label: i18n.t("table.correspondence_admin.post_subcat_e-mail"),
    filterType: TEXT_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  
  {
    name: "post_subcat_active",
    getValueCallback: (rowData) =>
      SelectFieldService.getLabelOptionsTrueOrFalse(rowData.post_subcat_active),
    label: i18n.t("table.correspondence_admin.post_subcat_active"),
    filterType: BOOLEAN_FIELD_TYPE,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  },
  {
    name: "edit",
    getValueCallback: (rowData, onClickEdit) => {
      return (
        <ModeEditOutlineOutlined
          onClick={(e) => onClickEdit(e, rowData.id)}
        />
      );
    },
    label: i18n.t("table.correspondence_admin.edit"),
    filterType: null,
    getFilterFieldConfigCallback: (filteringData) => undefined,
  }
];
