import {
  objectUrl,
  objectDetailsUrl,
  objectsSelectListUrl,
  objectTenantExtendedLocationUrl,
  objectLevelsFilteringDataUrl,
  objectTenantFilteringDataUrl,
  objectAdminDataUrl,
  objectAdminDetailsDataUrl,
  objectAdminFilteringDataUrl,
  objectCategoryUrl,
  objectCategoryDetailsUrl,
  objectCategorySelectListUrl,
  objectCategoryShortDataUrl,
  objectSubcategoryListUrl,
  objectCategoryFilteringDataUrl,
  objectSubcategoryFilteringDataUrl,
} from "../helpers/apiUrls";
import { makeRequest } from "../helpers/makeRequest";
import {
  isEmptyValue,
  prepareUrlWithQueryParams,
  isEmptyArray,
} from "../helpers/methods";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export default function useLocationService() {
  const axiosPrivate = useAxiosPrivate();

  const getObjectSelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectsSelectListUrl, searchParams)
    );
  };

  const getEmptyLocationLevel = (parent = null, level = 1) => {
    return {
      parent: parent,
      level: level,
    };
  };

  const getEmptyLocationElement = (parent = null, level = 1) => {
    return [getEmptyLocationLevel(parent, level)];
  };

  const getChosenHighestLevelObject = (levels) => {
    levels = [...levels];
    levels.sort((a, b) => b.level - a.level);
    for (const level of levels) {
      if (!isEmptyValue(level.id)) {
        return level.id;
      }
    }
    return undefined;
  };

  const prepareLocationDataFromBackendForLocationRecordsComponent = (
    locations,
    addEmptyLevel = true
  ) => {
    if (!locations || (Array.isArray(locations) && isEmptyArray(locations)))
      return [getEmptyLocationElement()];

    let preparedLocations = locations;
    if (!Array.isArray(locations)) preparedLocations = [locations];
    preparedLocations = preparedLocations.map((location) =>
      Object.values(location)
        .filter((level) => level !== null)
        .map((level, idx) => ({
          id: level.id,
          object_name: level.object_name,
          parent: level.parent,
          level: idx,
        }))
    );

    if (addEmptyLevel) {
      preparedLocations = preparedLocations.map((location) => [
        ...location,
        getEmptyLocationLevel(
          location[location.length - 1].id,
          location.length
        ),
      ]);
    }

    return preparedLocations;
  };

  const prepareLocationDataToSend = (locationsData) => {
    let objects = [];
    for (const locationLevels of locationsData) {
      let lastLocationObjectId = getChosenHighestLevelObject(locationLevels);
      if (lastLocationObjectId) objects.push(lastLocationObjectId);
    }
    return objects;
  };

  const getLevelFieldId = (locationIndex, levelIndex) => {
    return `location_${locationIndex}_level_${levelIndex}`;
  };

  const getObjectTenantExtendedLocationList = (searchParams, lastLevel = 5) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(
        objectTenantExtendedLocationUrl(lastLevel),
        searchParams
      )
    );
  };

  const getObjectLevelsFilteringData = () => {
    return makeRequest(axiosPrivate, objectLevelsFilteringDataUrl);
  };

  const getObjectTenantFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectTenantFilteringDataUrl, searchParams)
    );
  };

  const getObjectAdminData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectAdminDataUrl, searchParams)
    );
  };

  const getObjectAdminDetailsData = (objectId) => {
    return makeRequest(axiosPrivate, objectAdminDetailsDataUrl(objectId));
  };

  const getObjectAdminFilteringDataForDetails = () => {
    return makeRequest(axiosPrivate, objectAdminFilteringDataUrl);
  };

  const createObject = (dataToSend) => {
    return makeRequest(axiosPrivate, objectUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const updateObject = (dataToSend, objectId) => {
    return makeRequest(axiosPrivate, objectDetailsUrl(objectId), {
      method: "PATCH",
      data: dataToSend,
    });
  };

  const getObjectAdminFilteringData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectAdminFilteringDataUrl, searchParams)
    );
  };

  const getObjectSubcategoryData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectSubcategoryListUrl, searchParams)
    );
  };

  const getObjectCategorySelectList = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectCategorySelectListUrl, searchParams)
    );
  };

  const getObjectCategoryShortData = (searchParams) => {
    return makeRequest(
      axiosPrivate,
      prepareUrlWithQueryParams(objectCategoryShortDataUrl, searchParams)
    );
  };

  const createObjectCategory = (dataToSend) => {
    return makeRequest(axiosPrivate, objectCategoryUrl, {
      method: "POST",
      data: dataToSend,
    });
  };

  const getObjectCategoryDetails = (objectCategoryId) => {
    return makeRequest(
      axiosPrivate,
      objectCategoryDetailsUrl(objectCategoryId),
      {
        method: "GET",
      }
    );
  };

  const updateObjectCategory = (dataToSend, objectCategoryId) => {
    return makeRequest(
      axiosPrivate,
      objectCategoryDetailsUrl(objectCategoryId),
      {
        method: "PATCH",
        data: dataToSend,
      }
    );
  };

  const deleteObjectCategory = (objectCategoryId) => {
    return makeRequest(
      axiosPrivate,
      objectCategoryDetailsUrl(objectCategoryId),
      {
        method: "DELETE",
      }
    );
  };

  const getObjectCategoryFilteringData = () => {
    return makeRequest(axiosPrivate, objectCategoryFilteringDataUrl);
  };

  const getObjectSubcategoryFilteringData = () => {
    return makeRequest(axiosPrivate, objectSubcategoryFilteringDataUrl);
  };

  return {
    getObjectSelectList,
    getEmptyLocationLevel,
    getEmptyLocationElement,
    getChosenHighestLevelObject,
    prepareLocationDataFromBackendForLocationRecordsComponent,
    prepareLocationDataToSend,
    getLevelFieldId,
    getObjectTenantExtendedLocationList,
    getObjectLevelsFilteringData,
    getObjectTenantFilteringData,
    getObjectAdminData,
    createObject,
    updateObject,
    getObjectAdminDetailsData,
    getObjectAdminFilteringDataForDetails,
    getObjectAdminFilteringData,
    getObjectSubcategoryData,
    getObjectCategorySelectList,
    getObjectCategoryShortData,
    getObjectCategoryDetails,
    createObjectCategory,
    updateObjectCategory,
    deleteObjectCategory,
    getObjectCategoryFilteringData,
    getObjectSubcategoryFilteringData,
  };
}
