import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BasicDialog from "../../base/BasicDialog";
import CheckboxField from "../../field/CheckboxField";
import TextFieldFormControl from "../../field/TextFieldFormControl";
import { useForm } from "../../../hooks/useForm";
import { isEmptyValue } from "../../../helpers/methods";
import { useTranslation } from "react-i18next";
import { useSnackbarAlert } from "../../../context/snackbarAlert";
import { useAsync, useAsyncFn } from "../../../hooks/useAsync";
import useCorrespondenceAdminService from "../../../services/correspondenceAdminService";

const CreatePostStatusDialog = (props) => {
  const { t } = useTranslation();
  const { formValue, setFormValue, onChange } = useForm({post_status_active:true});

  const { createPostStatus, updatePostStatusData, getPostStatusDetails } = useCorrespondenceAdminService()
  const createPostStatusFn = useAsyncFn(createPostStatus);
  const updatePostStatusFn = useAsyncFn(updatePostStatusData);
  const snackbarAlert = useSnackbarAlert();

  const postStatusDetailsData = useAsync(() => {
    if (props.postStatusId) {
      return getPostStatusDetails(props.postStatusId);
    }
    return Promise.resolve({});
  }, [props.postStatusId]);

  useEffect(() => {
    if (!postStatusDetailsData.loading && postStatusDetailsData.value && props.postStatusId) {
      setFormValue(postStatusDetailsData.value);
    }
  }, [postStatusDetailsData.loading]);


  const onSubmit = (postStatusData) => {
    if (postStatusData.id) {
      updatePostStatusFn.execute(postStatusData.id, postStatusData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_status_updated"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_updating_post_status"));
        });
    } else {
      createPostStatusFn.execute(postStatusData)
        .then(() => {
          snackbarAlert.openSuccessSnackbarAlert(t("snackbar_alert.post_status_created"));
          if (props.onRefetchData) {
            props.onRefetchData();
          }
          props.onClose(true);
        })
        .catch(() => {
          snackbarAlert.openErrorSnackbarAlert(t("snackbar_alert.occurred_error_during_creating_new_post_status"));
        });
    }
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit(formValue);
    }
  };

  const onChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: checked }));
  }, []);

  const isFormValid = () => {
    return !isEmptyValue(formValue.post_status_name);
  };

  return (
    <BasicDialog
      open={props.open}
      onClose={props.onClose}
      titleAlign="center"
      maxWidth="md"
      title={props.postStatusId ? t("dialog.correspondence_admin_panel_dialogs.edit_post_status") : t("dialog.correspondence_admin_panel_dialogs.edit_post_status")}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextFieldFormControl
            name="post_status_name"
            label={t("dialog.correspondence_admin_panel_dialogs.post_status_name")}
            value={formValue["post_status_name"]}
            onChange={onChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldFormControl
            name="post_status_order"
            label={t("dialog.correspondence_admin_panel_dialogs.post_status_order")}
            value={formValue["post_status_order"]}
            onChange={onChange}
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxField
            name="post_status_in"
            label={t("dialog.correspondence_admin_panel_dialogs.post_status_in")}
            checked={formValue["post_status_in"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={3}>
          <CheckboxField
            name="post_status_active"
            label={t("dialog.correspondence_admin_panel_dialogs.active")}
            checked={formValue["post_status_active"]}
            onChange={onChangeCheckbox}
          />
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            {props.postStatusId ? t("save") : t("add")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton variant="outlined" color="secondary" fullWidth onClick={props.onClose}>
            {t("cancel")}
          </LoadingButton>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

CreatePostStatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefetchData: PropTypes.func,
  postStatusId: PropTypes.string,
};

CreatePostStatusDialog.defaultProps = {
  postStatusId: null,
  onRefetchData: null,
};

export default CreatePostStatusDialog;
